.StakeholderEmailRow {
    &__emailColumn {
        position: relative;
    }

    &__emailButton {
        width: 200px;
        height: 22px;
    }
}
