@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.MandatoryFlowsDetails {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 48rem;

    &__subtitle {
        color: $text-grey-dark;
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
    }

    &__header {
        margin-bottom: 1.6rem;

        display: flex;

        /** To visually integrate the selector next to the segmented control as if it were another tab **/
        .SegmentedControl__item:last-child {
            border-radius: 0;
        }

        .MandatoryFlowSelect {
            border-radius: 0 10px 10px 0;
            border-left: 0;
        }
    }

    &__chart {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__selector {
        min-width: 20rem;

        [class*='-control'] {
            min-height: 36px;
        }
    }

    &--pending {
        overflow: hidden;
        @include skeleton-animation();
    }

    &--failed {
        @include skeleton-animation($error: true);
        object,
        img {
            filter: brightness(0.75) saturate(0.5) sepia(1) hue-rotate(315deg);
        }

        overflow: hidden;

        .ErrorCard {
            margin-bottom: 2.4rem;
        }
    }
}
