@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

.waste {
    &__uploadContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 4rem;

        @include res-desk {
            flex-direction: row;
        }

        & > :first-child {
            margin-bottom: 4rem;

            @include res-desk {
                margin: 0rem;
                margin-right: 4rem;
            }
        }
    }

    &__dragAndDrop {
        width: 100%;
        width: -moz-available; /* For Mozzila */
        width: -webkit-fill-available; /* For Chrome */
        width: stretch;

        & > label {
            font-size: 1.6rem;
            font-weight: 400;
        }

        & > div {
            min-height: 10rem;
        }

        &-green {
            & > div {
                background-color: #f6fdf5;
                border: 2px dashed #c7e2b8;
            }
        }

        &-blue {
            & > div {
                background-color: #f5fcff;
                border: 2px dashed #c5e4ee;
            }
        }
    }
}
