@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.modal {
    max-width: 90vw;

    &__content {
        white-space: pre;

        &--text-center {
            text-align: center;
        }
    }

    &__buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2rem;
        gap: 3rem;
    }

    @include res-desk {
        max-width: 50vw;
    }
}
