@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesPageHeader {
    max-width: $default-container-width;
    width: 100%;
    margin: 0rem auto 2rem;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2.4rem;

    h1 {
        margin: 0;
    }

    a[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }


    &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 1.6rem;
    }
}
