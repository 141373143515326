@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.FlowTooltip {

    &__goal-flag {
        filter: brightness(5);
    }

    &__goal-title {
        margin-bottom: 0;
        margin-top: 0.8rem;
    }

    &__date {
        text-transform: capitalize;
    }

    &__flow {
        font-weight: 300;
        text-transform: lowercase;
    }

    &__goal-status {
        &.KPIStatusIcon--danger {
            filter: brightness(2.5) saturate(0.75);
        }
        &.KPIStatusIcon--warning {
            filter: brightness(1.5) saturate(0.5);
        }
        &.KPIStatusIcon--success {
            filter: brightness(1.5) saturate(0.5);
        }
    }

    &__goal {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        margin-bottom: 0.4rem;

        &--danger em {
            color: lighten($red-status, 25%);
        }
    
        &--success em {
            color: lighten($green-status, 15%);
        }
    
        &--warning em {
            color: lighten($orange-status, 15%);
        }
    }

}
