@import 'styles/abstracts/_variables.scss';
// Used px units instead rem/em to prevent user scale print elements

$A4width: 297mm;

// Workaround:
// should be 210mm as well as the page height, but chrome causes page breaks
//  if the element size is identical to the one defined in @page size
$A4height: 209mm;

.WasteSignage {
    &__footer {
        position: absolute;
        bottom: 0;
        z-index: 0;
    }

    &__poster {
        position: relative;
        display: flex;

        width: $A4width;
        height: $A4height;

        // Workaround:
        // For the missing millimeter in the page height to be consistent on all edges of the document
        border: 1mm solid white;
        border-bottom: none;

        page-break-after: always;
    }

    &__dataContainer {
        width: 70%;
        padding: 48px;

        padding-right: 0px;

        display: flex;
        flex-direction: column;

        overflow-x: visible;
        z-index: 1;

        font-size: 32px;
        line-height: 1.2;
        color: $blue-cocircular;
    }

    &__imageContainer {
        position: relative;
        width: 30%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: white;
    }

    &__logo {
        width: 180px;
    }

    &__uppertext {
        margin-top: 32px;
        font-size: 24px;
        font-weight: 300;
        text-transform: uppercase;
        color: $blue-cocircular-light-3;
    }

    &__title {
        font-size: 96px;
        width: 115%;
        line-height: 1;
        margin-top: 16px;
        text-transform: uppercase;
        font-weight: 800;
        word-break: break-word;

        --_text-border-color: white;
        text-shadow: 2px 0 var(--_text-border-color), -2px 0 var(--_text-border-color), 0 2px var(--_text-border-color),
            0 -2px var(--_text-border-color), 1px 1px var(--_text-border-color), -1px -1px var(--_text-border-color),
            1px -1px var(--_text-border-color), -1px 1px var(--_text-border-color);
    }

    &__restrictedUsage {
        font-weight: 800;
        text-transform: uppercase;
        color: $red-status;
    }

    &__desc {
        margin-top: 24px;
        font-size: 28px;
    }

    &__descLabel {
        font-size: 32px;
        font-weight: 300;
        margin-bottom: 8px;
        color: $blue-cocircular-light-3;
    }

    &__descValue {
        font-weight: 800;
    }

    &__spacer {
        font-weight: 300;
        word-break: break-all;
        color: $grey;
        padding: 0 8px;
        line-height: 1.4;
    }

    &__metaDataContainer {
        margin-top: auto;
        padding-top: 16px;
    }

    &__metaData {
        margin-top: 12px;
        display: flex;
        align-items: flex-start;
        gap: 4px;
    }

    &__metaDataLabel {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 300;
        color: $blue-cocircular-light-3;
    }

    &__metaDataValue {
        position: relative;
        top: -1px;
        font-size: 18px;
        font-weight: 600;
    }

    &__triangle {
        width: 0;
        height: 0;
        border-top: $A4height solid white;
        border-right: 150px solid transparent;

        // Workaround:
        // Ensures that no edges of the background image appear due to imperfect rendering of half pixels
        transform: translate(-3px, -1px);
    }

    &__dangerousImg {
        position: absolute;
        bottom: 24px;
        right: 24px;
        width: 96px;
        height: 96px;
    }
}
