@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.4rem;

    position: sticky;
    top: -3rem;
    z-index: 1;
    background: $light-grey;

    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 2.4rem;

    font-size: 1.4rem;
    z-index: 5;

    &__actions {
        display: flex;
        gap: 0.8rem;
        min-height: 3.6rem;
        margin-left: auto;
    }

    &__actions-dialog {
        // max-width: 600px;
        white-space: normal;
        text-align: left;
    }

    &__actions-tip {
        display: flex;
        align-items: center;
    
        color: $text-grey-light;
        font-size: 1.3rem;
        line-height: 1.4;
        margin-bottom: 1.6rem;
    }

    &__actions-validate-confirm {
        display: flex;
        gap: 0.8rem;
        margin-left: auto;
    }

    &__actions-validate-all {
        color: $purple-medium;
    }

    &__items {
        line-height: 1.3;
    }
}
