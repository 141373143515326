@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.roundedIcon {
    background-color: $light-purple;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    &__circle {
        padding: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 999px;
        width: max-content;

        &-blue {
            background-color: $light-purple;
            svg {
                stroke: $purple-medium;
            }
        }

        &-green {
            background-color: $light-green;
            svg {
                fill: $green;
            }
        }

        &-orange {
            background-color: $orange-light;
        }

        &-white {
            background-color: $white;
        }

        svg {
            max-width: 40px;
        }

        &-purple {
            background-color: $purple-medium;

            svg {
                color: $white;
            }
        }
    }

    &__big {
        padding: 1.25rem;
    }
}
