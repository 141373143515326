@import '../../../styles/abstracts/variables';

.status {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;

    svg {
        margin: 0;
        width: 2rem;
        margin-right: 0.5rem;
    }

    &-green {
        color: $green-status;
    }
    &-orange {
        color: $orange-status;
    }
    &-red {
        color: $red;
    }
    &-blue {
        color: #00bfd8;
    }
}
