@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.ppgcl {
    &__card {
        display: flex;
        flex-direction: column;
        height: auto;
        margin-top: 1.5rem;
        @include res-desk {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__containerButton {
        margin-bottom: 1.5rem;
        justify-content: flex-end;
    }
    &__noWrap {
        @include res-desk {
            flex-wrap: nowrap;
        }
    }

    &__form {
        .input__autocomplete,
        .input__container {
            width: 100%;
            margin-bottom: 1rem;
            padding: 0.5rem;

            @include res-desk {
                width: 30%;
            }

            input {
                width: 100%;
            }
        }
        @include res-desk {
            button {
                margin-top: 1.5rem;
            }
        }

        &-inputs {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0;

            button {
                margin-top: 1.5rem;
            }
        }
    }
    &__checkbox,
    &__checkbox--container {
        width: 100%;
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        align-items: center;
        label {
            margin-right: 1rem;
        }
    }

    &__budgetTitle {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    &__aditionalInfo {
        font-size: 1.2rem;
        width: 100%;
        display: flex;
        font-weight: 700;
        svg {
            margin-right: 1rem;
        }

        span {
            font-weight: 500;
        }
    }

    &__cardsInfoContainer {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        border-radius: $default-border-radius;
        font-size: 1.2rem;
        background-color: $light-grey;
        padding: 1.2rem;
        width: 90%;
        font-weight: 500;
    }

    &__notAccredited {
        margin-top: 0.75rem;
        color: rgb(95, 95, 95);
        display: flex;
        gap: 0.5rem;
        font-size: 1.2rem;
        line-height: 1.6;

        & > svg {
            margin-top: 0.15rem;
            min-width: 20px;
        }

        & > p > span {
            display: block;
        }

        &-warning {
            color: $red-dark;
            margin: -0.5rem 0rem 0.5rem 0rem;
        }
    }
}
