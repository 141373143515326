@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIDetailPrintable {
    padding: 0.25cm;

    width: 110%;
    transform-origin: left top;
    transform: scale(0.9);

    .KPIAlarm__link {
        display: none;
    }
    
    .MandatoryFlowsOverview__legend {
        display: none;
    }

    .MandatoryFlowsOverview__showOther {
        display: none;
    }

    .ValorizationDetail__tabs {
        display: none;
    }

    .MandatoryFlowsDetails__header {
        display: none;
    }

    &__tile {
        padding-bottom: 0.5cm;
    }
}
