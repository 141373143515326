@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.notFound {
    width: 100%;
    &__logo360 {
        margin: 2rem;
        width: 25rem;

        @include res-tablet {
            margin: 5rem;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        padding: 2rem;

        @include res-tablet {
            flex-direction: row;
            padding: 5rem;
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        img {
            width: 35rem;
            margin-top: 4rem;

            @include res-tablet {
                width: 60rem;
                margin-left: 10rem;
            }
        }

        h2 {
            font-size: 2.5rem;
            line-height: 3.6rem;
            margin-bottom: 2rem;

            @include res-tablet {
                font-size: 3.5rem;
            }
        }
    }
}
