@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include res-desk {
        flex-direction: row;
    }

    &__iconUser {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 10rem;
        width: 10rem;
        border-radius: 50%;
        background-color: $light-purple;
        font-size: 3.5rem;
        color: $purple;
        margin-bottom: 1rem;
        @include res-desk {
            margin-bottom: 0;
            margin-right: 1.5rem;
        }
    }

    &__info {
        margin-bottom: 1.5rem;
        text-align: center;
        @include res-desk {
            text-align: left;
            align-self: center;
            line-height: 2.2rem;
        }
        .profile__name {
            font-size: 2rem;
            font-weight: 600;
        }

        .smallText {
            font-size: 1.6rem;
        }
    }

    &__personalInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include res-desk {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    &__link {
        @include res-desk {
            margin-left: auto;
            align-self: flex-start;
        }
    }
}
