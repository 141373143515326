@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesList {
    max-width: $default-container-width;
    width: 100%;
    margin: 0rem auto;
    padding: 1.5rem;

    &__header {
        margin-bottom: 1.6rem;
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 600;
    }
}
