@import 'styles/abstracts/mixins';

.PromotionDetail {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(14, minmax(0, 1fr));
    grid-gap: 2.4rem;
    align-items: start;

    &__headline {
        grid-column: span 14;
        margin-bottom: -1.6rem;
    }

    &__promotionInfo {
        grid-column: span 14;

        @include res-desk {
            grid-column: span 11;
        }
    }
    &__valorizaBadge {
        display: flex; // avoid weird extra height
        grid-column: span 14;
        order: 6;

        @include res-desk {
            grid-column: span 3;
            order: unset;
        }
    }

    // Other Sections
    &__kpis,
    &__main,
    &__aside {
        grid-column: span 14;

        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }

    &__main {
        .PromotionDetail__waste,
        .PromotionDetail__protocolAndExecution {
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
        }

        @include res-desk {
            grid-column: span 11;
        }
    }

    &__aside {
        @include res-desk {
            grid-column: span 3;
        }
    }
}
