@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.detailPromotion {
    &__containerFlex {
        @include res-desk {
            display: flex;
            flex-wrap: wrap;
            margin: 1.5rem 0rem;
            > div {
                width: calc(50% - 0.75rem);
            }
            > div:first-of-type {
                margin-right: 1.5rem;
            }
        }
    }

    &__scroll {
        @include res-desk {
            overflow: scroll;
            height: 45rem;
            width: 100%;
            margin-top: 1.5rem;
            overflow-x: hidden;
        }
    }

    &__requestButtons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 1rem;
        width: 100%;

        & > :first-child {
            margin-right: 2rem;
            margin-bottom: 2rem;

            @include res-desk {
                margin-bottom: 0rem;
            }
        }
    }

    &__containerPpgcl {
        @include res-desk {
            display: flex;
            flex-direction: row;
            > div {
                width: calc(50% - 2.5rem);
            }
            > div:first-of-type {
                margin-right: 5rem;
            }
        }
    }

    &__formSearch {
        display: flex;
        margin-bottom: 2rem;

        button {
            margin-top: 1.5rem;
        }
        @include res-desk {
            flex-direction: row;
            align-items: flex-end;
            .input__autocomplete {
                margin-right: 2rem;
            }

            button {
                margin-top: 0rem;
            }
        }
    }

    &__listPPgcl {
        overflow: scroll;
        height: 80rem;
        width: 100%;
        margin-top: 1.5rem;
        overflow-x: hidden;

        @include res-desk {
            height: 45rem;
        }
    }

    &__container {
        margin-top: 2rem;

        @include res-desk {
            margin-top: 0;
        }
    }
}
