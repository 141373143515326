@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteFlowCarrierSelector {
    width: inherit;

    @keyframes WasteFlowCarrierSelector__spinner-rotate {
        from { transform: rotate(0deg) scale(0.75); }
        to { transform: rotate(360deg) scale(0.75); }
    }

    &__fields {
        position: relative;

        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        & > * {
            flex: 1;
            min-width: 24rem;
        }
    }

    &__info {
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.8rem;
    }

    &__spinner {
        color: inherit;
        width: 32px;
        height: 32px;
        margin: -6px;
        transform: rotate(0deg) scale(0.75);
        &.--animate {
            animation: WasteFlowCarrierSelector__spinner-rotate 1s linear infinite;
        }
    }
}
