@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SemiCircularIndicator {
    --SemiCircularIndicator-circle: #ebebeb;
    --SemiCircularIndicator-progress: var(--SemiCircularIndicator-color, #{$purple});

    width: var(--SemiCircularIndicator-width, 2rem);

    position: relative;
    overflow: hidden;
    aspect-ratio: 2 / 1; // crop height to 50%!

    &__progress {
        display: grid;
        border-radius: 50%;
        padding: 0.5%;
        color: $text-grey;
    }

    circle {
        fill: var(--SemiCircularIndicator-background, white);
    }

    &__percent {
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: var(--SemiCircularIndicator-number-font-size, 1.4rem);
        font-weight: 500;
    }

    &__text {
        max-width: var(--SemiCircularIndicator-width, 2rem);
        font-size: var(--SemiCircularIndicator-text-font-size, 1.4rem);
        text-align: center;
        font-weight: 500;
    }
}
