@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TagBadge {
    $this: &;
    --_background: var(--_color);
    --_foreground: var(--_color);

    background: white;
    position: relative;
    min-height: 2.4rem;
    padding: 0 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    
    &::before {
        content: '';
        display: block;
        background: var(--_background);
        position: absolute;
        opacity: 0.15;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--removable {
        transition: all 0.15s ease-in-out;
        
        &:hover {
            padding-right: 1rem;
            padding-left: 1rem;
            #{$this}__remove {
                opacity: 1;
            }
        }
    }
    
    &__text {
        font-size: 1rem;
        font-weight: 700;
        position: relative;
        top: 1px;
        text-transform: uppercase;
        line-height: 1.1;
        color: var(--_foreground);
    }

    &__remove {
        position: relative;
        &::before {
            content: '';
            display: block;
            background: var(--_background);
            position: absolute;
            opacity: 0.15;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        cursor: pointer;
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 2rem;
        
        border: none;
        font-size: inherit;
        color: inherit;
        padding: 0;

        align-items: center;
        justify-content: center;
        transition: all 0.15s ease-in-out;

        opacity: 0;
    }
}
