@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TooltipContent {
    &__tooltip {
        color: white;
        margin: -1rem;
        background-color: darken($blue, 5%);
        padding: 1rem 1.2rem;
        border-radius: 10px;
        max-width: 42rem;

        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    &__tooltipValue {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1;
    }

    &__tooltipLabel {
        font-size: 1.3rem;
        line-height: 1.4;
    }

    &__tooltipDate {
        font-size: 1.3rem;
        color: lighten($blue, 50%);
        line-height: 1;
        margin-top: 0.3rem;
    }
}
