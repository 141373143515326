.input {
    &__input {
        height: 44px;
        width: 100%;
        background-color: $light-grey;
        border: none;
        border-radius: 1rem;
        padding: 1rem;
        font-family: 'Be Vietnam Pro';
        color: $blue-cocircular;
        outline: none;
        font-size: 1.4rem;

        &::placeholder {
            color: #75787e;
        }

        &--notComplete {
            box-shadow: rgba(250, 128, 114, 1) 0px 0px 0px 1px;
        }

        &--disabled {
            color: grey;
            opacity: 0.5;
        }

        &--align-right {
            text-align: right;
        }
    }

    &__nueva-trazabilidad {
        width: 46rem;
    }

    &__all-width {
        width: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        &--nueva-trazabilidad {
            width: 45%;
            margin-top: 1rem;
        }
    }

    &__autocomplete--nueva-trazabilidad {
        width: 46rem;
    }

    &__fecha {
        display: flex;
        align-items: center;
        border-radius: 1rem;
        width: 100%;
    }

    &__aspa-fecha {
        border: none;
        color: $blue-cocircular;
        font-size: 1.6rem;
        margin-left: -1.2rem;
        margin-right: 2rem;
        cursor: pointer;
        width: 1rem;
        height: 4.4rem;
        padding: 1rem;
        padding-right: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-grey;
        border-radius: 0.4rem;
    }

    &__asunto-nueva-actividad {
        width: 100%;
    }

    &__traceabilityStatus {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }
}

.input-codLer {
    width: 70rem;
    margin-top: 2rem;
    @include res-desk {
        width: 16rem;
        margin-top: 0rem;
    }
}

.input__home {
    background-color: $white;
    width: 46rem;
    border-radius: 1.25rem;
    box-shadow: 0px 5px 15px $light-grey;
}
.datefield {
    width: 100%;
}
