@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterList {
    $this: &;
    padding: 1.5rem;
    margin: 0rem auto;
    max-width: $default-container-width;

    &__header {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__error {
        margin: 2.4rem 0;
    }

    &__list {
        @media (max-width: 1199px) {
            grid-gap: 0.8rem;
            display: grid;
            grid-template-columns: repeat(auto-fill, 1fr);
        }
    }

    &__group-header {
        grid-column: 1 / -1;

        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;

        width: 100%;
        padding: 2.4rem 0 0.4rem 0.8rem;
        color: $text-grey-light;

        @media (max-width: 1199px) {
            margin-bottom: -0.8rem;
        }

        // Makes border of the first item in the group rounded
        & + .WasteRegisterListItem {
            border-top-left-radius: $default-border-radius;
            border-top-right-radius: $default-border-radius;
        }

        &:first-of-type {
            padding-top: 0;
        }
    }

    &--pending {
        .WasteRegisterListItem {
            @include skeleton-animation();
        }
    }

    &--failed {
        padding-top: 2.4rem;
        width: 100%;
        display: flex;
        justify-content: center;

        .WasteRegisterListItem {
            @include skeleton-animation($error: true);
        }
    }
}
