@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.activity {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 1.5rem;
    padding: 1.5rem;

    @include res-desk {
        padding: 2.5rem 3rem;
    }

    .icon__circle {
        display: none;
        @include res-desk {
            display: flex;
            margin-right: 2rem;
            height: 80px;
            width: 80px;

            svg {
                width: 40px;
                height: 35px;
            }
        }
    }

    &__containerRight {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include res-desk {
            flex-grow: 1;
        }
    }

    &__description {
        font-size: 1.4rem;
        margin-top: 1rem;
    }
    &__containerTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include res-desk {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        & > h3 {
            font-size: 1.7rem;
        }
    }
    &__flex {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        button {
            margin-right: 1rem;
        }

        @include res-desk {
            button {
                margin-left: 1rem;
                margin-right: 0rem;
            }
        }
    }
    &__eye {
        display: flex;
    }
    &__smallInfo {
        display: flex;
        align-items: flex-start;
        justify-content: baseline;
        flex-wrap: wrap;
        margin-top: 1rem;
        gap: 1rem;

        @include res-desk {
            margin-top: 0;
        }
    }

    &__p {
        font-size: 1.4rem;
        color: rgba($grey-dark, 0.7);
        margin-bottom: 1rem;

        @include res-desk {
            margin-bottom: 0;
        }
        span {
            font-weight: 600;
        }
    }

    .button__small {
        align-self: flex-start;
        margin-top: 0.5rem;
        @include res-desk {
            align-self: flex-end;
            margin-top: 0;
        }
    }
}
