@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.promotionSettings {
    &__ul {
        margin-top: 1.5rem;
        li:not(:first-child) {
            border-top: 2px solid $light-grey;
        }
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2.4rem;
    }

    &__container {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        h2 {
            margin-bottom: 1.5rem;
        }
    }

    &__promotionsContainer {
        width: 45%;
        margin-right: 2rem;
    }

    &__usersContainer {
        width: 55%;
    }

    &__p {
        font-size: 1.4rem;
        span {
            font-weight: 700;
        }
    }

    &__flex {
        display: flex;
        flex-direction: column;
        @include res-desk {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2rem;
            margin-top: 4rem;
            &-first {
                margin-top: 2rem;
            }
        }
    }

    &__formEditPromotion {
        .input__container,
        .input__autocomplete {
            width: 100%;
        }
        .input__container input {
            width: 100%;
        }

        & > button {
            margin-top: 1.5rem;
        }

        @include res-desk {
            .input__container,
            .input__autocomplete {
                width: 70%;
            }
        }
    }

    &__moreFilters {
        color: $blue-cocircular;
        margin: 1.5rem 0rem;

        p {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.25rem;
            color: #3d5671;
        }
    }

    &__checkbox {
        margin-top: 0.75rem;
        font-size: 1.4rem;

        input {
            margin-right: 1rem;
        }
    }

    .TextFilter {
        max-width: 300px;
    }
}
