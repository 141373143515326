@import '../../../styles/abstracts/_mixins.scss';
@import '../../../styles/abstracts/_variables.scss';

.finalPpgcl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: 300;
    list-style: none;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $tooltip-grey;

    span {
        font-weight: 600;
    }
}

.finalPpgcl__ul {
    font-size: 1.3rem;
}

.finalPpgcl__message {
    display: flex;
    gap: 0.6rem;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.finalPpgcl__messageExists {
    color: $red;
}
.finalPpgcl__messageCreate {
    color: $medium-green;
}
.finalPpgcl .lerLine__error {
    color: $orange;
}
