.newWasteManagerRequest {
    &__textArea {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        textarea {
            width: calc(80% + 2rem);
            height: 7rem;
            font-size: 1.3rem;
        }
    }

    &__flex {
        display: flex;
        align-items: center;

        :first-child {
            margin-right: 2rem;
        }
    }

    &__codLers {
        margin: 2rem 0rem;

        .input__autocomplete {
            width: 30rem;
        }

        & > p {
            margin: 1rem 0rem;
        }
    }

    &__selectedCodLers {
        display: flex;
        flex-wrap: wrap;

        & > div {
            margin-right: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }

    &__mail {
        text-decoration: underline;
    }
}
