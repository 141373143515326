.informacion-desplegable {
    text-align: left;
    transition: 0.2s all ease;
    font-size: 1.5rem;
    font-weight: 350;
    width: 100%;

    &__titulo {
        font-size: 1.6rem;
        font-weight: 600;
    }

    &__codLer {
        font-weight: 300;

        span {
            font-weight: 500;
        }
    }

    &__cabecera {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    &__info {
        display: flex;
    }

    &__info-bloque {
        width: 35rem;
        margin-right: 2rem;
    }
    &__info-bloque__titulo {
        font-weight: 600;
    }

    &__info-bloque__lista {
        list-style: none;
    }

    &__titulo-detalle {
        font-weight: 600;
        font-size: 1.6rem;
    }
    &__accesos-directos {
        display: flex;
    }

    &__titulo-grafico {
        font-weight: 500;
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }

    &__container-grafico {
        margin: 2rem 0;
    }

    &__leyenda-grafico {
        display: flex;
        margin-top: 2rem;
    }

    &__container-leyenda {
        display: flex;
        margin-right: 2rem;
        justify-content: center;
        align-items: center;

        p {
            font-size: 1.3rem;
        }
    }
    &__punto {
        width: 1.2rem;
        height: 1.2rem;
        content: '';
        background-color: $blue-cocircular;
        margin-right: 0.5rem;
        border-radius: 50%;
        &--azul {
            background-color: $blue-cocircular;
        }

        &--verde {
            background-color: $green-cocircular;
        }
    }

    hr {
        border: 1px solid rgba($blue-cocircular, 0.2);
        margin: 1rem 0rem;
    }
}
