@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIThermometer {
    $this: &;
    --_transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    --_bar-background: #e6e9ec;

    position: relative;
    height: 5px;

    &__bar {
        position: absolute;
        left: 0;
        top: 0;

        border-radius: 999px;
        height: 100%;
        width: 100%;
    }

    &__progress {
        position: absolute;
        left: 0;
        top: 0;
        transition: var(--_transition);

        background-color: $purple;
        border-radius: 999px;
        height: 100%;
    }

    &__point {
        position: absolute;
        left: 0;
        top: 0;
        transform: scale(0.7) translate(-50%, -35%);
        opacity: 0;
        transition: var(--_transition);

        width: 9px;
        height: 9px;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 1px white;
        background: $text-grey-dark;
    }

    &__threshold {
        position: absolute;
        top: 0;
        left: 0;

        background: white;
        width: 3px;
        height: 100%;
        transform: translateX(-2px);
    }

    &__threshold-pin {
        position: relative;
        transform: translate(-50%, -50%);
        bottom: 4px;

        &--MIN {
            transform: translate(-50%, -50%);
            left: 3px;
        }

        &--MAX {
            transform: translate(-50%, -50%) scaleX(-1);
            left: 0px;
        }

        path {
            fill: $orange-status;
        }

        &--best-goal {
            path {
                fill: $green-status;
            }
        }
    }

    &__segment {
        background-color: var(--_bar-background);
        height: 100%;
        flex: 1;
    }

    &--continuous {
        #{$this}__bar {
            background-color: var(--_bar-background);
        }
    }

    &--discrete {
        #{$this}__bar {
            display: flex;
            overflow: hidden;
            gap: 2px;
        }
    }

    &--has-value {
        #{$this}__point {
            opacity: 1;
            transform: scale(1) translate(-50%, -25%);
        }
    }

    &--danger {
        #{$this}__progress,
        #{$this}__point,
        &#{$this}__segment {
            background-color: $red-status;
        }

        &.KPIThermometer--continuous {
            #{$this}__bar {
                background-color: transparentize($red-status, 0.85);
            }
        }
    }

    &--success {
        #{$this}__progress,
        &#{$this}__segment {
            background-color: $green-status;
        }
    }

    &--warning {
        #{$this}__progress,
        &#{$this}__segment {
            background-color: $orange-status;
        }

        &.KPIThermometer--continuous {
            #{$this}__bar {
                background-color: transparentize($orange-status, 0.85);
            }
        }
    }
}
