@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

/* Para que no se vea el aspa por default en un input de fecha, esto funciona en Chrome y Safari.
Para que no se vea en Firefox, se le ha añadido el atributo 'required' en el HTML */
input[type='time']::-webkit-clear-button {
    display: none;
}

input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.input,
.documentacion {
    padding: 1rem 1.25rem;
    background-color: $background-grey;
    border: none;
    border-radius: 1rem;
    font-family: $roboto;
    border: 1px solid yellow;

    &__fecha {
        display: flex;
        align-items: center;
        background-color: $background-grey;
        border-radius: 1rem;
        width: 100%;

        @include res-desk {
            min-width: 16rem;
            margin-top: 0rem;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        border-radius: 1rem;
        width: 100%;
        background-color: $background-grey;
        margin-top: -0.25rem;
    }

    &[type='date'] {
        width: 150px;
    }

    &__clear {
        cursor: pointer;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.75rem 0.5rem;
        background-color: transparent;
    }

    &__label {
        color: $blue-cocircular;
        font-size: 1.3rem;
        font-family: 'Be Vietnam Pro', sans-serif;
        font-weight: 300;
        margin-bottom: 2px;
    }

    &-notCompleted {
        box-shadow: rgba(250, 128, 114, 0.8) 0px 0px 5px 0px, rgba(250, 128, 114, 0.8) 0px 0px 1px 0px;
    }

    &__autocomplete {
        width: 100%;

        @include res-tablet {
            width: 20rem;
        }

        &--disabled {
            & > div {
                opacity: 0.5;
            }
        }
    }

    &__large {
        width: 100%;
        @include res-tablet {
            width: 46rem;
        }
    }

    &__nueva-trazabilidad {
        width: 46rem;
    }

    &__all-width {
        width: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        &--nueva-trazabilidad {
            width: 45%;
            margin-top: 1rem;
        }
    }

    &__autocomplete--nueva-trazabilidad {
        width: 46rem;
    }

    &__textarea {
        display: flex;
        flex-direction: column;
        width: 100%;

        textarea {
            width: 100%;
            height: 16rem;

            @include res-tablet {
                width: 100%;
                max-width: 46rem;
            }
        }
    }

    &__asunto-nueva-actividad {
        width: 90rem;
    }
}

.input-codLer {
    width: 70rem;
    margin-top: 2rem;
    @include res-desk {
        width: 16rem;
        margin-top: 0rem;
    }
}
