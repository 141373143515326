@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

.verticalStepper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.verticalStepper__steps {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 25%;
}
.verticalStepper__step {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &:not(:last-child):after {
        content: '';
        height: 80%;
        background-color: $purple-medium;
        width: 2px;
        order: -1;
        position: relative;
        top: 100%;
        left: 1.5rem;
    }
    &:last-child:after {
        width: 0px;
    }
}
.verticalStepper__circle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $light-purple;
    color: $purple;
    border-radius: 100%;
    height: 3rem;
    width: 3rem;
    margin-right: 1rem;

    &--current {
        background-color: $purple;
        color: white;
    }
}

.verticalStepper__circle::after {
    content: ' ';
}
.verticalStepper__step p {
    font-size: 1.4rem;
    flex-wrap: wrap;
    line-height: 1.6rem;
}

.verticalStepper__children {
    width: 75%;
    min-height: 45rem;
    display: flex;
    flex-direction: column;
}

.verticalStepper__containerButtons {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-top: auto;
    .buttonAux {
        padding: 1rem;
    }
}

.verticalStepper__message {
    font-size: 1.4rem;
    margin-top: 1.5rem;
}
