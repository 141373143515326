@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionInfo {
    width: 100%;
    color: $blue-cocircular;

    padding: 1.8rem 2.4rem;

    h1 {
        line-height: 1.5;
    }

    &__address {
        font-size: 1.4rem;
    }

    &__addressAndMail {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem 2rem;
    }

    &__bottomData {
        margin-top: 1.2rem;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem 2rem;

        flex-direction: column;

        @include res-bigdesk {
            flex-direction: row;
            align-items: center;
        }
    }

    &__progressAndTags {
        flex: 1;
        display: flex;
        gap: 1rem 1.5rem;
        flex-wrap: wrap;
        align-items: center;

        @include res-tablet {
            flex-wrap: nowrap;
        }
    }

    &__progressBar {
        width: 100%;
        min-width: 200px;
        max-width: 280px;
    }

    &__viewMore {
        border: none;
        color: $purple;
        padding: 0.5rem 0.75rem;
    }
}
