@import 'styles/abstracts/_mixins.scss';
@import 'styles/abstracts/_variables.scss';

.faq {
    margin: 0 auto 4rem;
    max-width: $large-container-width;
    width: 100%;

    h1 {
        margin-bottom: 2rem;
    }

    .card {
        padding: 0rem;
    }

    &__container {
        display: grid;
        grid-gap: 2rem;

        @include res-desk {
            grid-template-columns: 1.25fr 3fr;
        }
    }

    &__menu {
        padding-bottom: 3rem;
    }

    &__iframe {
        padding: 0.25rem !important;
        overflow: hidden;
        width: 100%;
        position: relative;

        iframe {
            width: 100%;
            height: 725px;
            border: 0;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            right: 2px;
            width: calc(100% - 190px);
            height: 80px;
            background-color: white;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1.5rem 1.75rem;

        h2 {
            font-size: 1.6rem;
            font-weight: 550;
        }

        .icon__circle {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 999px;
            width: max-content;

            svg {
                height: 20px;
                width: 20px;
                min-width: 20px;
            }
        }
    }

    &__option {
        padding: 0.9rem 1.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
        border-top: 1px solid $light-grey;
        font-size: 1.4rem;
        line-height: 1.5;

        &-selected {
            background-color: #f4f4ff;
        }

        &:last-child {
            border-bottom: 1px solid $light-grey;
        }

        &:hover {
            background-color: #fbfbfb;
            cursor: pointer;
        }

        svg {
            min-width: 24px;
        }
    }
}
