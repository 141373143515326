@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIsGoalSettings {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    &__header {
        display: flex;
        flex-wrap: wrap;
        gap: 1.6rem;

        justify-content: space-between;
        align-items: center;
    }


    &__title {
        max-width: 76rem;
        & > p {
            color: $text-grey-light;
            font-size: 1.4rem;
        }
    }
}
