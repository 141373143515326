@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesListKPIs {
    $this: &;
    display: flex;
    gap: 1.6rem;
    flex-wrap: wrap;
    margin-bottom: 1.6rem;

    &__item {
        flex: 1;
        min-width: 20rem;
        padding: 0;
    }

    &__header {
        padding: 1.2rem 1.6rem 0rem;
    }

    &__label {
        color: lighten($blue, 5%);
        font-size: 1.2rem;
    }

    &__value {
        color: darken($blue, 15%);
        font-size: 2rem;
        font-weight: 800;
        line-height: 1.4;
        min-height: 2.8rem;
    }

    &__chart {
        height: 7rem;
        border-radius: 0 0 10px 10px;
        position: relative;
    }

    &__tooltip {
        background-color: darken($blue, 5%);
        color: white;
        box-shadow: 0px 5px 9px transparentize($color: $blue, $amount: 0.9);
        padding: 0.8rem 1.2rem;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    &__tooltipLabel {
        font-size: 1rem;
        color: lighten($blue, 50%);
        line-height: 1;
        text-transform: capitalize;
    }

    &__tooltipValue {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1;
    }

    &--is-loading {
        @include skeleton-animation($speed: 0.3s);
    }
}
