.ValorizaBadge {
    cursor: pointer;
    width: 100%;
    max-width: 260px;

    &__explanation {
        max-width: 70rem;

        a {
            text-decoration: underline;
            color: #5b4ad7;
        }
    }
}
