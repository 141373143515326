@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AddTags {
    --_size: 2.4rem;
    width: var(--_size);
    height: var(--_size);

    &__add {
        z-index: 1;
        width: var(--_size);
        height: var(--_size);
        
        border-radius: 4px;
        background-color: $light-grey;
        font: inherit;
        color: $text-grey-light;
        border: 0;
        padding: 0;
        cursor: pointer;
    }

    &__select {
        position: absolute;
        z-index: 2;
        left: 0;
        top: calc(100% + 0.8rem);
        width: 100%;
        max-width: 240px;

        [class*='-control'] {
            background: white;
            box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
            border-radius: 4px 4px 0 0;
        }

        [class*='-menu'] {
            margin-top: 0;
            border-radius: 0 0 4px 4px;
        }

        [class*='-NoOptionsMessage'] {
            font-size: 1.3rem;
            line-height: 1.3;
            text-align: left;
            color: $text-grey-light;
            opacity: 0.75;
            padding: 8px    ;
        }

        input {
            padding-left: 8px !important;
        }
    }

    &__option {
        display: flex;
    }
}
