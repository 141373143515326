.total-valorizadas {
    width: 13rem;
    height: 13rem;
    background: white;
    border-radius: 100px;
    box-sizing: border-box;
    position: relative;
    text-align: center;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include res-desk {
        width: 15rem;
        height: 15rem;
        margin: 2rem;
    }
    &::before {
        border-radius: 100%;
        content: '';
        padding: 2px;
        width: 13rem;
        height: 13rem;
        top: -2px;
        left: -2px;
        position: absolute;
        z-index: -1;
        box-sizing: content-box;
        @include res-desk {
            width: 15rem;
            height: 15rem;
        }
    }
    &--ahorrado::before {
        background-image: linear-gradient(to right top, rgba($green-cocircular, 1) 75%, rgba($white, 1) 78%);
    }
    &--valorizadas::before {
        background-image: linear-gradient(to right bottom, rgba($green-cocircular, 1) 75%, rgba($white, 1) 78%);
    }
    &--gestionadas::before {
        background-image: linear-gradient(to left bottom, rgba($green-cocircular, 1) 75%, rgba($white, 1) 78%);
    }

    &--co2::before {
        background-image: linear-gradient(to left top, rgba($green-cocircular, 1) 75%, rgba($white, 1) 78%);
    }

    &__icono {
        width: 5rem;
        padding-bottom: 1rem;
        @include res-desk {
            width: 5rem;
            padding-bottom: 1.3rem;
        }
    }

    &__importe {
        font-size: 2.5rem;
        line-height: 1rem;
        font-weight: 700;

        span {
            padding-left: 0.3rem;
        }
    }

    &__titulo {
        padding-top: 0.4rem;
        text-transform: uppercase;
        font-size: 1.3rem;
    }
}
