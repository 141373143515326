@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.DropdownList {
    $this: &;
    position: relative;

    &__list {
        position: absolute;
        z-index: 99;
        right: 0;
        background: white;
        list-style: none;
        padding: 0.8rem;
        margin: 0;
        box-shadow: 0px 5px 9px transparentize($color: $blue, $amount: 0.9);
        border-radius: 8px;

        transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;

        overflow: hidden;
        height: 0;
        transform: translateY(0px) scaleY(0.9);
        pointer-events: none;
        opacity: 0;
        top: 100%;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 0.6rem;
        }

        & > * {
            white-space: nowrap;
            display: block;
            padding: 0.8rem 1.2rem;
            border-radius: 8px;
            color: inherit;

            &:hover {
                background: $background-grey;
            }
        }

        & > button {
            appearance: none;
            background: none;
            border: none;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            width: 100%;
            cursor: pointer;
        }
    }

    &--is-open {
        #{$this}__list {
            transform: translateY(10px) scaleY(1);
            pointer-events: all;
            opacity: 1;
            height: auto;
        }
    }
}
