@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ComparativeObservationsModal {
    max-width: 725px;

    color: $blue-cocircular;
    font-size: 1.4rem;

    .input__textarea {
        margin: 2rem 0rem;

        textarea {
            min-height: 150px;
            width: 100%;
            max-width: unset;

            @include res-desk {
                width: 600px;
            }
        }
    }
}
