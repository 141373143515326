@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterNotifications {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        background: $light-grey;
        padding: 0 0 0.4rem;
        border-radius: 10px;
    }

    &__table {
        margin: 0 -2.4rem -0.8rem;
        border-spacing: 0px;

        tr {
            display: flex;
            flex-wrap: wrap;
            border-top: 1px solid lighten($border-grey, 6.5%);
            gap: 1.6rem;
            padding: 1.2rem 2.4rem;
            background: white;

            @include res-desk {
                flex-wrap: nowrap;
            }

            &.KPIGoals--change-scope {
                border-top: 0.4rem solid lighten($border-grey, 4%);
            }
        }

        th,
        td {
            display: flex;
            align-items: center;

            // Stakeholder
            &:nth-child(1) {
                flex: 1 1 100%;
                max-width: 21rem;
            }
            // Emails
            &:nth-child(2) {
                flex: 1;
            }
            // Actions
            &:nth-child(3) {
                flex: 1;
                min-width: 3.2rem;
                max-width: 3.2rem;
            }
        }

        thead {
            tr {
                background: $light-grey;
                display: none;
                padding-top: 1.6rem;
                padding-bottom: 1.6rem;
                border-top: 0;

                @include res-desk {
                    display: flex;
                }
            }

            th {
                padding: 0 1rem;
                line-height: 100%;
                text-align: left;
                font-size: 1.2rem;
                font-weight: 600;
                color: $text-grey-dark;
            }
        }

        tbody {
            tr:first-child {
                // border-bottom: 1px solid $blue;
                padding-top: 2.4rem;
                padding-bottom: 2.4rem;
            }
            tr:last-child {
                border-bottom: none;
            }
        }
    }

    &__error {
        margin-bottom: 2.4rem;
    }

    &__spinner {
        width: 3.2rem;
        height: 3.2rem;
        margin: 1rem auto;
    }
}
