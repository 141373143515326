@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TreatmentContractList {
    &__header {
        margin-top: 2.4rem;
    }
    &__headerTxt {
        font-size: 1.4rem;
        color: $dark-grey;
    }
    &__error {
        margin-top: 1.6rem;
    }
}

.treatmentContract {
    display: none;

    @media print {
        display: block;

        h3 {
            // this avoids weird page breaks -> https://stackoverflow.com/questions/907680/css-printing-avoiding-cut-in-half-divs-between-pages
            // display: table is required. break-inside: avoid doesn't work by its own
            display: table;
            break-inside: avoid;
        }
    }

    h2 {
        font-size: 3.5rem;
    }

    small {
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

    h3 {
        margin-top: 2.75rem;
        margin-bottom: 0.5rem;
        font-size: 1.8rem;
    }

    table {
        border-collapse: collapse;
        border: 1.5px solid #dddddd;
        width: 100%;
        margin-top: 1rem;
    }

    td,
    th {
        border: 1.5px solid #dddddd;
        text-align: left;
        padding: 0.5rem 1rem;
    }
    th {
        font-weight: 600;
    }

    tbody > tr:nth-child(odd) {
        background-color: #ededed;
    }

    p,
    li,
    th,
    td {
        line-height: 1.5;
        font-size: 1.4rem;
    }

    ul {
        margin-left: 1.5rem;
    }

    &__sign {
        margin-top: 4rem;
        display: flex;
        justify-content: space-around;
    }

    &__signSquare {
        height: 150px;
        width: 275px;
        border: 1.5px solid #dddddd;
    }

    &__logos {
        margin-top: 4rem;

        p {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        & > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 2rem;
        }

        img {
            max-width: 175px;
        }
    }
}
