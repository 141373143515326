@import '../../../styles/abstracts/_mixins.scss';
@import '../../../styles/abstracts/_variables.scss';

.lerLine {
    border-bottom: 1px solid $tooltip-grey;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.lerLine__info {
    font-size: 1.3rem;
    font-weight: 600;
    span {
        font-weight: 300;
        margin-left: 0.5rem;
    }
}
.lerLine__info .lerLine__error {
    font-weight: 600;
    color: $orange;
}
.lerLine__info .lerLine__percent {
    font-weight: 600;
}
.lerLine__warning {
    margin-bottom: 1rem;
}
