@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.editProfile {
    &__card {
        margin-bottom: 1.5rem;

        .input__container {
            margin-bottom: 1rem;
            @include res-desk {
                width: 100%;
            }

            input {
                width: 100%;
                @include res-desk {
                    width: 20%;
                }
            }
        }

        button {
            margin-top: 2rem;
        }

        .titulo__h3 {
            margin-bottom: 1rem;
        }
    }
}
