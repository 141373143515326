@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.card__rounded {
    background-color: $light-purple;
    padding: 1rem 1.5rem;
    border-radius: 5rem;
    display: flex;
    align-items: baseline;
    font-size: 1.4rem;
    font-weight: 700;

    span {
        font-size: 2rem;
        margin-right: 0.3rem;
    }
}
