@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.EnterpriseAutocomplete {
    flex: 1;

    &__container {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    &__label {
        display: flex;
        gap: 0.4rem;
        align-items: center;
    }

    &__tooltip {
        padding-top: 2.2rem;
    }

    &--disabled {
        opacity: 0.4;
    }
}
