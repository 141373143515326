@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.toggle {
    height: 2.5rem;
    width: 5.5rem;
    border-radius: 16px;
    display: inline-block;
    position: relative;
    margin: 0;
    background: $light-purple;
    transition: all 0.2s ease;
    &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: white;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
    }
    &:checked {
        background: $purple;
        &:after {
            transform: translatex(30px);
        }
    }
    @include reset-checkbox();
}

.resultAssociate {
    border-radius: 0.75rem;
    border: 2px solid $light-grey;
    padding: 1.5rem;
    margin-top: 1rem;

    &__toggle {
        margin-right: 1rem;
    }

    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        h3 {
            margin-right: 1rem;
            font-size: 1.5rem;
            margin-left: 1rem;
        }
    }

    &__containerCards {
        display: flex;

        .smallMetricCard {
            background-color: $light-grey;
            div {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                p {
                    margin-right: 0.5rem;
                    font-size: 1.4rem;
                }
            }
        }
    }

    &__infoManager {
        display: flex;
        margin-bottom: 0.5rem;
    }

    &__warning {
        color: $orange;
    }

    &__transportist {
        margin-left: 1rem;
    }

    &__info {
        display: flex;
        align-items: flex-start;
        font-size: 1.3rem;
        flex-direction: column;
        margin-right: 1rem;

        @include res-desk {
            flex-direction: row;
        }

        span {
            margin-right: 0.5rem;
            font-weight: 600;
        }
    }
}
