@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.DynamicForm {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    gap: 2.4rem;
}
