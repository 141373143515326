@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.metricas {
    display: flex;
    margin: 2rem 0rem;
    flex-direction: column;

    @include res-desk {
        flex-direction: row;
    }

    & > * {
        margin-bottom: 1.5rem;
        @include res-desk {
            margin-right: 2rem;
            margin-bottom: 0;
        }
    }
}

.card__costes {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;

    @include res-desk {
        flex-direction: row;
    }

    .card__rounded {
        width: 12rem;
        justify-content: center;
    }

    .costes__info {
        display: flex;
        flex-direction: column;
        @include res-desk {
            flex-direction: row;
        }
    }

    .costes__buttons {
        display: flex;
        align-items: center;
    }

    .button__small {
        margin-right: 0.5rem;
    }

    .costes__text {
        @include res-desk {
            margin-left: 2rem;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .costes__smallText {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
    }
}
