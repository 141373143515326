@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.Guides {
    &__header {
        text-align: center;
        width: 100%;
        margin: 1rem 0rem 2rem 0rem;
    }

    &__title {
        font-weight: $text-grey-dark;
    }

    &__subtitle {
        font-size: 1.4rem;
        color: $text-grey-light;
    }
}
