@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesListKPIsPrintable {
    .TraceabilitiesListKPIs__item {
        border: 0;
        border-radius: 0;
        box-shadow: none;

        // &#accumulatedCostPerTraceability {
        //     display: none;
        // }
    }
    .TraceabilitiesListKPIs__header {
        padding: 0;
    }
}
