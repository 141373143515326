.HasPermissions {
    &__tooltip {
        &--disabled {
            cursor: not-allowed;
            & > * {
                opacity: 0.5;
            }
            * {
                pointer-events: none;
            }
        }
    }
}
