@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.documentation {
    &__folderContainer {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 2rem;
    }

    &__numberFiles {
        background-color: $light-purple;
        display: flex;
        margin-top: 0.8rem;
        width: min-content;
        padding: 0.3rem 1rem;
        border-radius: 3rem;
        align-items: center;
        color: #7f42f5;

        svg {
            margin: 0;
            margin-right: 0.4rem;
        }
    }

    &__descriptionFolder {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: 1.3rem;
        font-weight: 500;
    }
    &__select {
        display: flex;
        flex-direction: row;
        align-items: center;

        h4 {
            margin-left: -24rem;
            @include res-tablet {
                margin-left: -9rem;
            }
        }
    }

    &__containerBuildingDocs {
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 1rem;

        @include res-desk {
            grid-gap: 2rem;
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &__message {
        margin-top: 2rem;
    }

    &__eye {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__iconEye {
        margin-top: 0.8rem;
    }

    &__empty {
        color: $tooltip-grey;
        text-align: center;
        font-size: 1.8rem;
        padding: 4.8rem 1.6rem;
    }

    .create-button {
        margin: 0rem;
    }

    .titulo__h2 {
        margin: 2.25rem 0rem 1.25rem 0rem;
    }
}

.newDocument {
    &__BIM {
        margin-bottom: 2rem;

        & a {
            text-decoration: underline;
        }
    }
}

.downloadListPage {
    &__results {
        margin-top: 2rem;

        & .docDownloadRequest + .docDownloadRequest {
            margin-top: 1.5rem;
        }
    }
}
