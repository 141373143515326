@import 'styles/abstracts/variables';

.WasteFlowSelectorOption {
    padding: 0.25rem 0rem;
    color: $text-grey-dark;

    svg {
        width: 18px;
        min-width: 18px;
        height: 18px;
        stroke-width: 1.5;
    }

    &__ler {
        color: $text-grey-dark;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    &__agents {
        font-weight: 380;
        font-size: 1.3rem;

        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    &__agent {
        display: flex;
        flex-direction: row;
        gap: 0.35rem;
        min-width: 45%;
        width: 45%;
        max-width: 45%;
        line-height: 1.5;

        span {
            align-self: center;

            overflow-x: hidden;
            overflow-y: visible;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    mark {
        background-color: rgba(184, 150, 251, 0.5);
    }
}
