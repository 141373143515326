@import 'styles/abstracts/variables';

.PromotionItem {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    font-size: 1.3rem;
    color: $light-blue-cocircular;

    svg {
        height: 16px;
        width: 16px;
        min-width: 16px;
        stroke-width: 1.8;
        stroke: $light-blue-cocircular;
    }
}
