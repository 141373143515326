@import '../../../styles/abstracts/_mixins.scss';
@import '../../../styles/abstracts/_variables.scss';

.transferOperator__list {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-weight: 300;
}
.transferOperator {
    font-size: 1.3rem;
    list-style-type: none;
    padding-bottom: 1rem;
    border-bottom: 1px solid $tooltip-grey;

    &__optionsSection {
        margin-top: 1.6rem;
    }
}
.transferOperator__question {
    margin-top: 1rem;
}
.transferOperator__info {
    font-size: 1.3rem;
    span {
        font-weight: 600;
    }
}
.transferOperator__buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    gap: 1rem;

    button {
        font-weight: 300;
        font-size: 1.3rem;
    }

    .input__input {
        height: 30px;
    }
}

.transferOperator__inputsContainer {
    display: flex;
    gap: 3rem;
    margin-top: 0.5rem;

    .input__input {
        height: 40px;
    }
}

.transferOperator__active {
    background-color: $light-purple;
    color: $purple;
    border-color: $purple;

    &:hover {
        background-color: $light-purple-hover;
    }
}

.transferOperator__textArea {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.transferOperator__textArea {
    textarea {
        width: 35rem;
        resize: none;
        height: 10rem;
    }
}
