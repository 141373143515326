@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionDataForm {
    &__form {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }

    &__row {
        @include res-bigdesk() {
            display: flex;
            flex-wrap: wrap;
            gap: 2.4rem;
        }

        &.--align-right {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__control {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;

        @include res-bigdesk() {
            &.--full {
                flex: 1;
            }

            &.--large  {
                min-width: 30rem;
            }

            &.--medium-large {
                min-width: 20rem;
                max-width: 20rem;
            }

            &.--medium {
                min-width: 15rem;
                max-width: 15rem;
            }
    
            &.--small {
                min-width: 10rem;
                max-width: 10rem;
            }
        }
    }
}
