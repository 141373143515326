@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingAiBadge {
    display: inline-flex;
    align-items: center;
    column-gap: 0.5em;

    padding: 0.5em 1em;
    width: fit-content;
    background-color: hsl(295, 100%, 99.5%);
    position: relative;

    color: $purple;
    font-weight: 550;
    font-size: 1.3rem;
    font-style: normal;

    border: 1px solid hsl(274, 100%, 96%);
    border-radius: 10px;
    line-height: 1.1;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background: radial-gradient(49.54% 76.56% at 50.46% 100%, hsl(295, 100%, 92%) 0%, transparent 100%);
    }

    svg {
        width: 1.6em;
        min-width: 1.6em;
        height: 1.6em;
    }
}
