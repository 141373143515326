// media query manager

/* 600-900px     Tablet */
@mixin res-tablet {
    @media (min-width: 600px) {
        @content;
    }
}

/* 900-1200px    Tablet landscape*/
@mixin res-desk {
    @media only screen and (min-width: 900px), only print and (min-width: 0mm) {
        @content;
    }
}

/* 1200-1800px    Bigdesk*/
@mixin res-bigdesk {
    @media (min-width: 1200px) {
        @content;
    }
}

/* 1800px    Superdesk*/
@mixin res-superdesk {
    @media (min-width: 1800px) {
        @content;
    }
}

// Skeletons
@mixin skeleton-animation($error: false, $speed: 0.4s) {
    opacity: 1;
    will-change: opacity;
    animation: SkeletonAnimation $speed linear alternate infinite;

    @if $error {
        --skeleton-color: #{transparentize($red, 0.25)};
        animation: none;
    }
}

@mixin skeleton($letters, $transparent: false) {
    $content: '';
    $color: transparent;

    @for $i from 1 through $letters {
        $content: $content + '0';
    }

    @if $transparent == false {
        $color: $grey;
    }

    &::after {
        content: $content; // just for skeleton text
        display: inline;
        color: transparent;
        background: var(--skeleton-color, darken($color, $amount: 3%));
        line-break: anywhere;
        border-radius: 999px;
    }
}


@mixin reset-checkbox() {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    &:focus {
        outline: 0;
    }
}

@mixin error-animation-shake() {
    @keyframes ErrorAnimationShake {
        10%, 90% { 
            transform: translateX(-1px);
            filter: brightness(0.85) sepia(0.5) hue-rotate(310deg);
        }
        20%, 80% { 
            transform: translateX(2px);
        }
        30%, 50%, 70% { 
            transform: translateX(-4px);
            filter: brightness(0.85) sepia(0.5) hue-rotate(310deg);
        }
        40%, 60% { 
            transform: translateX(4px); 
        }
    }

    animation: ErrorAnimationShake 0.85s ease-in-out;
}
