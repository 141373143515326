@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIsPrintable {
    // A workaround to ensure that the page is not cut off when printing
    width: 125%;
    transform-origin: left top;
    transform: scale(0.8);

    .card {
        padding: 0;
        // border: none;
        box-shadow: none;
        border-radius: 0;
    }

    .KPIs__tab {
        pointer-events: none;
    }

    .KPIs__showMoreButton {
        display: none;
    }
}
