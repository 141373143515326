@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.gestora {
    width: 100%;
    max-width: $default-container-width;
    margin: 0 auto;

    &__container {
        margin-top: 1rem;
    }

    &__row {
        margin-bottom: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @include res-bigdesk {
            flex-wrap: nowrap;
        }

        & > div {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            @include res-bigdesk {
                max-width: 65%;
                margin-bottom: 0;
            }

            & > span {
                margin-left: 1.5rem;
                margin-right: 1rem;
                line-height: 1.4;
            }
        }

        &:last-child {
            margin-bottom: 0rem;
        }
    }

    &__enterprise-group {
        margin-bottom: 2.4rem;
    }

    &__enterprise-group-name {
        color: $text-grey-light;
        font-weight: bold;
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 0.8rem;
    }
}
