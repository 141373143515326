@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingList {
    $this: &;

    input[type='checkbox'] {
        appearance: auto;
        width: 16px;
        height: 16px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 2.4rem;

        position: sticky;
        top: -3.2rem;
        z-index: 1;
        background: $light-grey;

        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        padding-left: 2.4rem;

        font-size: 1.4rem;
    }

    &__actions {
        display: flex;
        gap: 0.8rem;
        min-height: 3.6rem;
        margin-left: auto;
    }

    &__list {
        width: 100%;
        color: $text-grey-light;
        font-size: 1.4rem;
        font-weight: 300;
        position: relative;
    }

    &--is-loading {
        #{$this}__list {
            @include skeleton-animation($speed: 0.3s);
        }
    }

    .EmptyState {
        max-width: 56rem; // message 'extracting DIs' in a single line
    }
}
