@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.CircularProgress {
    display: grid;
    border-radius: 50%;
    transition: all 0.3s linear;
    padding: 2%;
    color: $text-grey;
    position: relative;

    --CircularProgress-circle: #ebebeb;
    --CircularProgress-progress: #{$purple};

    circle {
        fill: var(--CircularProgress-background, white);
    }

    &__percent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: var(--CircularProgress-font-size, 1.4rem);
    }

    &__text {
        font-size: var(--CircularProgress-font-size, 1.4rem);
        text-align: center;
        line-height: 140%;
        margin-top: 0.8rem;
    }
}
