.spinner {
    & > img {
        width: 70px;
        height: 70px;
        animation: rotate-animation 2.5s infinite linear;
    }

    &__relative {
        display: flex;
        justify-content: center;

        & > img {
            position: relative;
        }
    }

    &__absolute {
        height: 100px;
        width: 100px;
        background-position: -140px -580px;
        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -50px;
            margin-top: -50px;
        }
    }
}

@keyframes rotate-animation {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
