@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.buscador {
    .titulo__h2 {
        margin-bottom: 0.5rem;
        font-weight: 550;
        font-size: 1.6rem;
    }

    form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;

        @include res-tablet {
            flex-direction: row;
            align-items: flex-end;
            gap: 2rem;
        }

        & > button {
            margin-top: 1rem;

            @include res-tablet {
                margin: 0rem;
            }
        }
    }

    &-vertical {
        form {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            gap: 1rem;

            & > button {
                margin-top: 1rem;
            }
        }
    }

    &__nueva-actividad {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include res-bigdesk {
            flex-direction: row;
        }

        .input__autocomplete {
            width: 100%;
        }
    }
}
