@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SupportForm {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    &__footer {
        display: flex;
        margin-top: 3.2rem;
        justify-content: space-between;
    }

    &__sentTitle {
        font-size: 2.4rem;
        text-align: center;
    }
    
    &__sentMsg {
        font-size: 1.5rem;
        line-height: 1.6;
        text-align: center;
        padding-bottom: 4rem;

        a {
            color: $purple;
            text-decoration: underline;
        }
    }

    &__sentImg {
        object-fit: none;
        object-position: center;
        width: 30rem;
        height: 30rem;
        margin: -1rem auto -4rem;
        transform: scale(0.65);
    }

    &__check {
        display: flex;
        gap: 0.8rem;

        margin-top: 1rem;

        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 300;

        & > input[type="checkbox"] {
            width: 1.6rem;
            height: 1.6rem;
            margin-top: 1px;
        }
    }
}
