@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

.stepper {
    &__stepper {
        display: flex;
        justify-content: space-evenly;
        margin: 2rem 0rem;
    }

    &__option {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        & > button {
            cursor: pointer;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            border: 2px solid $grey;
            box-shadow: 0px 5px 15px $light-grey;
            -webkit-box-shadow: 0px 5px 15px $light-grey;
            background-color: white;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }

        & > span {
            text-align: center;
        }

        &:not(:last-child):after {
            content: '';
            position: relative;
            top: 2rem;
            height: 2px;
            background-color: #e0e0e0;
            order: -1;
            left: 50%;
            width: calc(100% - 8rem);
        }

        &-active {
            & > button {
                background-color: $blue-cocircular;
                border: 2px solid $blue-cocircular;
                color: white;
                font-weight: 500;
            }

            & > span {
                font-weight: 500;
            }
        }
    }

    &__buttons {
        @include res-tablet {
            display: flex;
            justify-content: center;
            margin: 3rem 0rem;

            button {
                margin-right: 3rem;
            }
        }
    }

    &__button1 {
        margin: 4rem;
        display: flex;
        justify-content: center;
        @include res-tablet {
            margin: 0rem;
            justify-content: end;
            width: 40%;
        }
    }

    &__button2 {
        margin: 4rem;
        display: flex;
        justify-content: center;
        @include res-tablet {
            margin: 0rem;
            width: 20%;
        }
    }

    &__button3 {
        margin: 4rem;
        display: flex;
        justify-content: center;
        @include res-tablet {
            margin: 0rem;
            justify-content: start;
            width: 40%;
        }
    }
}
