@import '../../styles/abstracts/mixins';

.newPromotion {
    h2 {
        margin-top: 0.25rem;
        margin-bottom: 2rem;
        font-size: 2.2rem;
    }

    &__popover {
        @include res-desk {
            width: 50%;
            height: fit-content;
            .message-not-complete {
                margin-bottom: 1rem;
            }
            .input__autocomplete {
                width: 50rem;
            }
            .input__container {
                margin-bottom: 1rem;
            }
        }
    }
}
