@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.Walle {
    display: flex;
    flex-direction: column;
    height: calc(100% + 6rem);
    gap: 2rem;
    max-width: 100%;

    @include res-desk {
        flex-direction: row;
    }

    @include res-desk {
        max-height: 100%;
    }

    &__list {
        flex: 1;
        overflow: auto;
        margin-top: -3rem;
        margin-bottom: -3rem;
        padding-top: 3rem;

        @include res-desk {
            max-width: 50%;
        }
    }

    &__preview {
        flex: 1;
    }
}
