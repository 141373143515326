@import '../../../styles/abstracts/variables';

.basicChip {
    display: flex;
    align-items: center;
    background-color: $light-purple;
    color: $purple;
    border: none;
    padding: 0.35rem 0.5rem 0.35rem 1.25rem;
    border-radius: 3rem;
    font-size: 1.4rem;
    width: min-content;

    & > span {
        display: flex;
    }

    &__removeButton {
        cursor: pointer;
        display: flex;
        background-color: transparent;
        border: none;
        padding: 0.5rem;
        border-radius: 3rem;
        margin-left: 0.75rem;

        &:hover {
            background-color: $light-purple-hover;
        }
    }
}
