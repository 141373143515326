@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

$breakpoint: 899px;

.c-stepper {
    display: flex;
    margin-top: 1rem;
}

.subsequent {
    flex: 1 1;
    gap: clamp(0.25rem, 2vw, 0.5rem);
    content: '';
    list-style-type: none;

    @media (max-width: $breakpoint) {
        display: flex;
        gap: 1.6rem;
    }

    &:last-child {
        flex: 0 0 auto;
    }

    &:not(:last-child) {
        .c-stepper__item {
            width: 100%;
            display: flex;
            gap: clamp(0.25rem, 2vw, 0.5rem);
            align-items: center;
            margin-bottom: 0.75rem;

            @media (max-width: $breakpoint) {
                flex-direction: column;
                width: 4rem;
            }

            &:not(:last-child)::after {
                content: '';
                position: relative;
                height: 2px;
                background-color: $purple;
                flex: 1;
                margin-right: 0.5rem;

                @media (max-width: $breakpoint) {
                    // display: flex;
                    height: 100%;
                    min-height: 4.8rem;
                    width: 2px;
                }
            }
        }
    }
}

.subsequent__info {
    width: 100%;
    margin-top: 0.4rem;
    padding-right: 0.8rem;

    .subsequent__wasteManager {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    .smallText {
        line-height: 1.8rem;
        font-size: 1.3rem;
        font-weight: 500;
    }
}

.finalTraceability {
    .titulo__h4 {
        font-weight: 600;
    }

    .finalTraceability__text {
        font-size: 1.2rem;
        font-style: italic;
    }
}

.subsequent__container {
    display: flex;
    margin-top: 1rem;

    @media (max-width: $breakpoint) {
        flex-direction: column;
    }
}

.subsequent__message {
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    color: $orange;
}

.subsequent__percent {
    margin-top: 1.4rem;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    background-color: $light-purple;
    padding: 1rem;
    border-radius: $default-border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18rem;
    line-height: 1.4;
    margin-bottom: 2rem;
    span {
        font-size: 1.9rem;
        font-weight: 600;
    }
}
