@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

$container: 1024px;

.dashboard__btn {
    background-color: $light-grey;
    border: 1px solid $dark-grey;
    padding: 0.5rem 1.5rem;
    gap: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Be Vietnam Pro', 'sans-serif';
    font-size: 1.4rem;
    border-radius: 0.5rem;
    font-weight: 300;
    transition: 0.25s all ease;

    svg {
        height: 2.5rem;
        padding: 0;
        margin: 0;
    }

    &:hover {
        transform: translateY(-0.25rem);
    }
}

.dashboard__filterContainer {
    display: flex;
    gap: 1.5rem;
    width: 100%;
    max-width: $container;
    margin: auto auto;
    margin-bottom: 3.2rem;
}

.dashboard__btn-active {
    background-color: $light-purple;
    border-color: $purple;
    color: $purple;
    font-weight: 400;
}

.dashboard__buttonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;

    width: 100%;
    max-width: $container;
    margin: 0 auto;
}

.dashboard__select {
    width: 80%;

    [class*='-control'] {
        min-height: 44px !important;
        height: auto !important;
    }
}

.dashboard {
    iframe {
        border: none;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__content {
        width: 100%;
        max-width: $container;
        margin: 0 auto;
    }

    &__alert {
        max-width: $container;
        margin: 0rem auto 3.2rem auto;
        width: 100%;
        display: flex;
    }
}
