@import '../../../styles/abstracts/_mixins.scss';
@import '../../../styles/abstracts/_variables.scss';

.wasteManagerStep__form {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-end;
    margin-bottom: 2rem;
}
.wasteManagerStep__container {
    height: 60%;
    overflow-y: auto;
}
.wasteManagerStep__buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}
.wasteManagerStep__buttonLer {
    background-color: transparent;
    border: 1px solid $purple;
    color: $purple;
    padding: 0.25rem 1rem;
    font-family: 'Be Vietnam Pro', 'sans-serif';
    border-radius: $default-border-radius;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.wasteManagerStep__buttonLer--notselect {
    border: 1px solid $text-grey;
    color: $text-grey;
}
