@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ActivitySiteCheckingSection {
    font-size: 1.4rem;

    .titulo__h4 {
        margin-bottom: 1rem;
    }

    &__incidences {
        margin-bottom: 5rem;
        ul {
            margin-left: 2rem;
        }
    }

    &__wasteSection {
        width: 100%;
        max-width: 100%;
        margin-bottom: 5rem;

        // suggestion card
        > div:last-child {
            margin-top: 1rem;
        }
    }

    &__tableWrapper {
        overflow-x: auto;
    }

    &__table {
        display: table;
        width: 100%;
        max-width: 100%;
        border-spacing: 15px 5px;
        margin-left: -15px;
        margin-top: -5px;
        margin-bottom: 0.5rem;

        @include res-desk {
            width: unset;
        }

        th {
            font-weight: 450;
        }
    }

    &__row {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include res-desk {
            display: table-row;
        }

        & > td,
        & > th {
            padding: 3px;

            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @include res-desk {
                display: table-cell;
            }
        }
    }

    &__desktopHeaders {
        display: none;
        @include res-desk {
            display: contents;
            text-align: left;
        }
    }

    &__mobileHeaders {
        display: block;
        min-width: 20rem;

        @include res-desk {
            display: none;
        }
    }

    &__lerType {
        font-weight: 600;
        margin-top: 2rem;

        @include res-desk {
            font-weight: 400;
            margin-top: 0rem;

            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .button__small {
        font-size: 1.2rem;
        padding: 0.6rem 1.2rem !important;
        min-height: fit-content;
    }

    &__image {
        display: inline-block;
        max-width: 12rem;
        margin-right: 3rem;
        cursor: pointer;
        border-radius: 0.75rem;

        &-hidden {
            display: none;
        }
    }
}

.VisitRecordFieldResult {
    display: flex;
    align-items: center;
    min-width: 100px;

    svg {
        margin-right: 0.5rem;
        width: 18px;
        min-width: 18px;
        height: 18px;
    }

    &-good {
        color: $green-status;
    }

    &-moderate {
        color: $orange-status;
    }

    &-bad {
        color: $red-status;
    }
}
