@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.folder {
    width: 200px;
    height: 110px;
    margin-top: 25px;
    position: relative;
    background-color: $white;
    border-radius: 6px 15px 15px 15px;
    box-shadow: $default-border-radius;
    cursor: pointer;
    padding: 4px 1.5rem;
}

.folder:before {
    content: '';
    width: 50%;
    height: 25px;
    border-radius: 15px 20px 0 0;
    background-color: $white;
    position: absolute;
    top: -20px;
    left: 0px;
}
