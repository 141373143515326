.VisibleDataDropdown {
    &__item,
    svg,
    label {
        cursor: pointer;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 0rem;
        padding-left: 1.5rem;

        svg {
            max-width: 20px;
            width: 20px;
            height: 20px;
        }

        label {
            padding: 0.5rem 1.5rem 0.5rem 1rem;
            width: 100%;
        }
    }
}
