@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

.ppgcl {
    $selected-ppgcl-background: lighten($purple, 44%);
    font-size: 1.3rem;
    padding: 0.75rem 1rem 0.75rem;

    border-bottom: 1px solid #eff3f7;
    border-radius: 8px;

    &:hover {
        background-color: #f8f9fb;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 1.6rem;
        column-gap: 4rem;
    }

    &__agents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        column-gap: 3rem;
        row-gap: 1rem;

        @include res-bigdesk {
            flex-direction: row;
        }
    }

    .icon__circle {
        padding: 0.5rem;
        margin-right: 1.5rem;
        cursor: pointer;
    }

    &__flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    &__containerTooltip {
        line-height: 0;
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 600;
    }

    &__agent {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        color: $light-blue-cocircular;

        max-width: 250px;

        @include res-bigdesk {
            min-width: 250px;
        }

        svg {
            stroke: 1;
            min-width: 24px;
        }
    }
    &-openedDoc {
        .ppgcl__content {
            align-items: flex-start;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            column-gap: 2rem;
        }

        .ppgcl__agents {
            flex-direction: column;
            align-items: flex-start;
        }

        .ppgcl__agent {
            min-width: 350px;
        }
    }

    &-selected {
        background-color: $selected-ppgcl-background;

        &:hover {
            background-color: $selected-ppgcl-background;
        }
    }

    &__ler {
        padding: 0.15rem 1rem;
        font-weight: 650;
        background-color: $light-purple;
        border-radius: 10px;
        width: 100%;
    }

    &__containerButtons {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        // margin-left: auto;

        // .Tooltip {
        //     margin-right: -1.2rem;
        // }
    }

    &__purpleAssociate {
        .button__small {
            border: 1px solid lighten($purple, 35%);
            color: $purple;
        }
    }

    &__message {
        color: $purple;
        max-width: 600px;
        word-break: break-all;
    }

    &__ler-container {
        min-width: 180px;
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    @keyframes blinkEffect {
        0% {
            background-color: lighten($purple, 40%);
        }

        100% {
            background-color: $selected-ppgcl-background;
        }
    }
}
