@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteFlowInfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    font-size: 1.3rem;
    color: $text-grey-light;
    font-style: italic;
    font-weight: 300;

    & > p {
        font-style: normal;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.2rem;
        background-color: $light-purple;
        padding: 0.25rem 1.25rem;
        border-radius: $default-border-radius;
        width: fit-content;
        color: $purple;
        font-weight: 500;

        & > span {
            display: flex;
            align-items: center;
            color: $text-grey;
            cursor: pointer;
        }
    }

    &__pill {
        font-style: normal;
        align-items: center;
        gap: 0.5rem;
        font-size: 1.2rem;
        background-color: $light-purple;
        padding: 0.25rem 1.25rem;
        border-radius: $default-border-radius;
        width: fit-content;
        color: $purple;
        font-weight: 500;
    }
}
