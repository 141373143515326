@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ProtocolAndExecution {
    $this: &;

    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    &__section {
        position: relative;

        font-size: 1.4rem;
        color: $blue-text;

        display: flex;
        gap: 2rem 6rem;
        flex-wrap: wrap;
    }

    &__indicatorWrapper {
        --SemiCircularIndicator-width: 12rem;
        --SemiCircularIndicator-background: white;
        --SemiCircularIndicator-number-font-size: 2rem;
        --SemiCircularIndicator-text-font-size: 1.6rem;

        &.--success {
            --SemiCircularIndicator-color: #{$green-status};
        }

        &.--warning {
            --SemiCircularIndicator-color: #{$orange-status};
        }

        &.--danger {
            --SemiCircularIndicator-color: #{$red-status};
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        position: relative;

        min-width: 136px;

        h3 {
            text-transform: uppercase;
            font-size: 1.2rem;
            margin-bottom: 0.4rem;
        }

        p {
            display: flex;
            align-items: center;
            gap: 0.6rem;

            span {
                display: flex;
                align-items: center;
            }

            svg {
                height: 18px;
                width: 18px;
                min-width: 18px;
            }
        }
    }

    &__status {
        &.--success {
            color: $green-status;
        }

        &.--warning {
            color: $orange-status;
        }

        &.--danger {
            color: $red-status;
        }
    }

    &.--skeleton {
        @include skeleton-animation();

        #{$this}__indicatorWrapper {
            display: none;
        }

        #{$this}__group {
            h3 {
                display: none;
            }
            p {
                @include skeleton($letters: 16);
            }
        }
    }
}
