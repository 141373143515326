@import 'styles/abstracts/variables';

.SiteCheckingModeChip {
    padding: 0.4rem 1.4rem 0.4rem 1.2rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
    font-size: 1.2rem;
    border-radius: $default-border-radius;

    svg {
        min-width: 16px;
        max-width: 16px;
        height: 16px;
        stroke-width: 1.8;
    }

    &--purple {
        color: $purple;
        background-color: $light-purple;
    }

    &--blue {
        color: #1f4c80;
        background-color: $blue-light;
    }
}
