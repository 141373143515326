@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ChangePasswordForm {
    
    &__picture {
        width: 100%;
        max-width: 30rem;
        margin: 0 auto;
        display: block;
        margin-bottom: 3.2rem;
    }

    &--has-error {
        max-width: 64rem;
    }
}
