@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.OnboardingPopover {
    width: 90dvw;
    max-width: 96rem;

    &__header {
        text-align: center;
        width: 100%;
        margin-top: -2.5rem;
        margin-bottom: 3.2rem;
    }

    &__title {
        font-weight: $text-grey-dark;
    }

    &__subtitle {
        font-size: 1.4rem;
        color: $text-grey-light;
    }
}
