@import '../abstracts/variables';
@import '../abstracts/mixins';

.donut__container {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    flex-direction: column;
    max-height: 225px;
    height: 225px;
    min-width: 100%;

    & > div {
        min-width: 100px;
        max-width: 275px;
        min-height: 100px;
        max-height: 100%;
    }
}

.barras__container {
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -2rem;

    & > div {
        min-height: 200px;
        max-height: 350px;
        max-width: 500px;

        @include res-bigdesk {
            max-width: 100%;
        }
    }
}

.legend {
    &__container {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__info {
        display: flex;
    }
    &__colors {
        display: flex;
        flex-direction: column;
    }

    &__color {
        margin: 0.4rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
    }
    &__lers {
        display: flex;
        flex-direction: column;
    }

    &__ler {
        margin: 0.4rem;
        height: 2rem;
        font-size: 1.4rem;
    }
}

.donut__tooltip {
    background-color: $white;
    padding: 1rem;
    box-shadow: 0 2px 12px rgb(201, 201, 201);
    border-radius: 6px;
    z-index: 2;
}

.donut__tooltip__description {
    color: $blue-cocircular;
    font-size: 1.4rem;
    span {
        font-weight: 700;
    }
}

.donut__nodata {
    font-size: 1.4rem;
}
