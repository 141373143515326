@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

.relatedEnterprise {
    $this: &;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    border: 1px solid $grey;
    border-radius: $default-border-radius;
    padding: 1rem 1.75rem;

    &__data {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        gap: 1.5rem;
    }

    &__role {
        padding: 0.5rem 1.5rem;
        background-color: $light-purple;
        color: $purple;
        font-size: 1.2rem;
        border-radius: 999px;
        font-weight: 500;
    }

    &__price,
    &__mainEnterprise {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 999px;
        font-weight: 500;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    &__price {
        background-color: $light-green;
        color: $green;
    }

    &__mainEnterprise {
        background-color: $orange-light;
        color: $orange;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
    }

    &--is-skeleton {
        @include skeleton-animation();

        #{$this}__data {
            @include skeleton($letters: 20);
            & span {
                display: none;
            }
        }

        #{$this}__role {
            @include skeleton($letters: 10);
        }

        #{$this}__price {
            & svg {
                visibility: hidden;
            }
        }

        #{$this}__mainEnterprise {
            display: none;
        }

        #{$this}__buttons button {
            @include skeleton($letters: 10);
            & > span,
            svg {
                display: none;
            }
        }
    }
}

.relatedEnterprisesStep {
    &__list {
        margin-bottom: 1.5rem;

        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .button__blue {
            margin-top: 2rem;
        }
    }
}

.newRelatedEnterprise {
    margin-top: 3rem;
    margin-bottom: 6rem;

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
        margin-bottom: 2rem;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
    }

    form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 3rem;
    }

    &__input {
        &-medium {
            min-width: 200px;
        }

        &-large {
            min-width: 350px;
        }
    }

    &__checkbox {
        &:hover {
            cursor: pointer;
        }

        label {
            font-size: 1.4rem;
        }

        input {
            display: block;
            appearance: auto !important;
            width: 2rem;
            height: 2rem;
        }
    }
}
