@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.Report {
    &__info {
        max-width: 80rem;
        margin: 0 auto;
        position: relative;
        margin-bottom: 3.2rem;
    }

    &__infoContent {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        min-height: 4.8rem;
    }

    &__progress {
        width: 5rem;
        --CircularProgress-background: #{$light-purple};
        --CircularProgress-font-size: 1rem;
    }

    &__progressContainer {
        display: flex;
        align-items: center;
        gap: 1rem;
    }


    &__document {
        overflow: visible !important;
        @media screen {
            max-width: 80rem;
            padding: 0;
            margin: 0 auto;
            position: relative;
        }
    }
    
    &__page {
        padding: 2.5cm 1cm 1cm;
        // min-height: 297mm;
        background: white;
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;

        @media screen {
            margin-top: 2.4rem;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
            position: relative;
            min-height: 1050px; // aprox A4 height simulation
            &::before {
                content: '';
                display: block;

                position: absolute;
                top: 0;
                left: 0;
                background: lighten(lightslategray, 45%);
            
                width: calc(100%);
                height: 80px;
            }
        }
    }

    &__break-avoid{
        position: relative;
        z-index: 1;
        page-break-inside: avoid;
        &:not(:first-child) {
            padding-top: 2.5cm;
        }
        &:not(:last-child) {
            margin-bottom: -2cm;
        }
    }

    &__header {
        @media screen { display: none; }

        font-size: 12pt;
        position: fixed;
        z-index: 2;
        gap: 1cm;
        width: 100%;
        top: 0;
        left: 0;
        padding: 0.5cm 1cm;
        display: flex;
        align-items: center;
        justify-content: space-between;

        line-height: 1.3;

        p {
            margin: 0;
            padding: 0;
        }
    }

    &__sectionHeader {
        margin-bottom: 0.25cm;
        
        &:not(:first-child) {
            padding-top: 1cm;
        }
        h2 {
            font-size: 14pt;
            font-weight: 600;
            line-height: 1.3;
        }

        h3 {
            font-size: 12pt;
            font-weight: 600;
            line-height: 1.3;
        }
    }

    &__headerLogo {
        width: 3cm;
    }

    &__cover {
        --_padding: 1.5cm;
        background: url('/assets/auth-background.jpg') no-repeat center center;
        background-size: cover;
        width: 100%;
        height: 100vh;
        min-height: 297mm;
        position: relative;
        z-index: 3;
        padding: var(--_padding);

        @media screen {
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
        }
    }
    
    &__coverLogo {
        position: absolute;
        top: var(--_padding);
        left: var(--_padding);
        width: 5cm;
    }

    &__coverText {
        position: absolute;
        top: 8%;
        left: 0;
        text-align: left;
        font-size: 3.2rem;
        padding: var(--_padding);
        max-width: 95%;
    }

    &__coverTitle {
        position: relative;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 5.5rem;
        line-height: 1.1;
    }

    &__coverInfo {
        position: absolute;
        width: 100%;
        padding: var(--_padding);
        left: 0;
        bottom: 0;
    }
}
