@import 'styles/abstracts/_mixins.scss';
@import 'styles/abstracts/_variables.scss';

.WasteSummary {
    color: $blue-cocircular;

    display: flex;
    flex-direction: column;
    gap: 3rem;

    padding-bottom: 3.5rem;

    @include res-desk {
        gap: 0rem;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    &__section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:first-child {
            @include res-desk {
                margin-left: -3rem;
            }
        }

        & > svg {
            width: 100%;
            max-width: 200px;
            margin-bottom: -3rem;
            margin-top: -3rem;

            @include res-desk {
                margin-top: -4rem;
            }
        }

        &:not(:last-child):after {
            content: url('../../../../../../assets/iconos/metrics_evolution/arrow.svg');
            order: -1;
            position: absolute; // this is necesary (with position: relative in parent) bcs if we set this as position:relative, it does something weird with block height
            align-self: flex-start; // to remove being centered by align-items: center
            top: 60px; // puesto a ojo ngl
            left: calc(50% + 40%); // 50% + half of the icon width + left spacing
            width: calc(100% - 80%); // 100% - icon width - left margin - right margin
            min-height: 38px;
            display: none;

            @include res-desk {
                display: block; // need 'block' type to work on Chrome. do not use flex (idk why flex doesn't work on Chrome)
            }
        }
    }

    &__data {
        margin-left: 4.5rem;

        & h4 {
            font-size: 1.6rem;
            font-weight: 500;
        }

        & ul {
            margin-left: 4.5rem;
            margin-top: 1.75rem;
            padding: 1.25rem 0rem 1.25rem 1.75rem;
            position: relative;

            // line connecting label to the braces
            &:before {
                content: '';
                position: absolute;
                top: -10px;
                left: -28px;
                height: 45px;
                width: 30px;
                border: 2px solid #e2d1ee;
                border-top: none;
                border-right: none;
                border-radius: 0rem 0rem 0rem 1.25rem;
            }

            // braces
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 25px;
                border: 2px solid #e2d1ee;
                border-right: none;
                border-radius: 1.25rem 0rem 0rem 1.25rem;
            }

            & li {
                list-style-type: none;
                font-size: 1.4rem;
                display: flex;
                align-items: flex-start;
                gap: 0.5rem;
                margin: 0.2rem 0rem;
                line-height: 1.6;
            }
        }
    }
}
