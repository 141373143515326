.Select {
    width: 100%;
    position: relative;
    box-shadow: none !important;

    [class*='-singleValue'] {
        width: 100%;
        background-color: transparent;
    }

    &--disabled {
        [class*='-placeholder'] {
            opacity: 0.15;
        }
    }

    &__wasteFlow {
        position: relative;

        [class*='-control'] {
            min-height: 44px;

            mark {
                background-color: transparent;
            }
        }
    }

    &__extraElement {
        position: absolute;
        right: 0;
    }

    &__nativeEl {
        width: 0%;
        height: 0%;
        pointer-events: none;
        opacity: 0;
        position: absolute;
        top: 100%;
        right: 50%;
    }
}
