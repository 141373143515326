@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.DynamicFormSection {
    &__title {
        font-size: 1.3rem;
        text-transform: uppercase;
        color: $text-grey-dark;
        font-weight: 800;
    }

    &__children {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 2.4rem
    }
}
