@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionList {
    $this: &;

    display: flex;
    flex-direction: column;
    gap: 4rem;

    &__searchAndFilters {
        display: flex;
        align-items: center;
        gap: 2rem;

        .buttonAux {
            border: 1.5px solid darken($grey, 5%);
            height: 40px !important;
            border-radius: 10px;
        }
    }

    &__infiniteScroll {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-bottom: 35rem;
    }

    &__grid {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        column-gap: 2rem;
        row-gap: 2rem;

        @include res-desk {
            grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
        }
    }

    &__noResults {
        color: $blue-cocircular;
        animation: appearText 1s;
        -moz-transition: appearText 1s;
        -ms-transition: appearText 1s;
        -o-transition: appearText 1s;
        -webkit-transition: appearText 1s;
        transition: appearText 1s;
    }

    // TRICK to make the text appear after a few seconds
    @keyframes appearText {
        0% {
            color: transparent;
        }
        99% {
            color: transparent;
        }
        100% {
            color: $blue-cocircular;
        }
    }

    &--loading {
        #{$this}__grid {
            @include skeleton-animation();
        }
    }

    &--failed {
        #{$this}__grid {
            @include skeleton-animation($error: true);
        }
    }
}
