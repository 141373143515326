@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.HelpCenterPage {
    width: 100%;
    max-width: $default-container-width;
    margin: 0 auto;
    margin-bottom: 4rem;

    &__tabs {
        margin-bottom: 2.4rem;
    }

    &__support {
        margin-top: 2.4rem;
    }
}
