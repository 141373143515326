.editorClassName {
    font-size: 1.4rem;
    font-family: 'Be Vietnam Pro';
    padding: 1rem;
    width: 100%;
    background-color: $light-grey;
    height: 50rem;
    margin-bottom: 1.5rem;
    &--notComplete {
        box-shadow: rgba(250, 128, 114, 0.8) 0px 0px 5px 0px, rgba(250, 128, 114, 0.8) 0px 0px 1px 0px;
    }
}

.wrapperClassName {
    background-color: $light-grey;
    border-radius: 0.4rem;
    width: 100%;
}

.toolbarClassName {
    border-radius: 4px;
}

.rdw-option-wrapper:hover {
    background-color: $light-grey;
}

.rdw-option-active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
    background-color: $light-grey;
}

.rdw-block-wrapper {
    font-family: 'Be Vietnam Pro';
}

.rdw-block-dropdown {
    width: 150px;
}

.public-DraftStyleDefault-block {
    margin: 0.15em 0;
}

.rdw-editor-main blockquote {
    border-left: 5px solid #bbbbbb;
}

div[title='Monospace'] {
    display: none;
}

.rdw-dropdown-optionwrapper li:last-child {
    display: none;
}
