@import 'styles/abstracts/variables';

.DiExtractionWarning {
    font-weight: 600;
    font-size: 1.3rem;
    color: white;
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;

    label {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 20px;
        height: 20px;
    
    
        &--yellowCircle {
            background-color: #fcbf22;
            border-radius: 99%;
        }

        &--redDiamond {
            label {
                position: absolute;
                z-index: 1;
            }

            &:after {
                position: absolute;
                z-index: 0;
                content: '';
                width: 100%;
                height: 100%;

                background-color: #e04401;
                transform: rotate(45deg) scale(0.85);
                border-radius: 3px;
            }
        }
    }

    &__message {
        max-width: 24rem;
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
}
