@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterDI {
    width: 100%;
    padding: 8rem;
    background-color: $background-grey;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    overflow-y: scroll;

    button {
        min-height: 44px;
    }

    &__diWrapper {
        max-width: 800px;
    }

    @media print {
        *:has(* .WasteRegisterDI) {
            display: none;
        }
    }
}
