@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIStatusIcon {
    &--danger {
        color: darken($red-status, 15%);
        path:nth-child(2) {
            fill: transparentize($red-status, 0.9);
        }
    }

    &--success {
        color: darken($green-status, 15%);
        circle {
            fill: transparentize($green-status, 0.9);
        }
    }

    &--warning {
        color: darken($orange-status, 15%);
        path:nth-child(3) {
            fill: transparentize($orange-status, 0.9);
        }
    }

    &--null, &--indeterminate, &--not_applicable {
        color: lighten($blue, 25%);
        circle {
            fill: transparentize($blue, 0.95);
        }
    }
}
