@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

.users {
    &__buttons {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        @include res-desk {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0rem;
        }
    }

    &__usersSelected {
        display: inline-block;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        background-color: $light-purple;
        padding: 0.2rem 1rem;
        border-radius: $default-border-radius;
        width: fit-content;
        margin-top: 1.5rem;

        .icon {
            margin-left: 1rem;
            cursor: pointer;
        }
    }

    &__form {
        @include res-desk {
            width: 50%;
        }

        button {
            margin-top: 2rem;
        }
    }
}
