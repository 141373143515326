@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterListItem {
    $this: &;

    border: 1px solid $border-grey;
    background: white;
    position: relative;
    padding: 1.6rem 2.4rem;
    transition: box-shadow 0.3s linear;
    cursor: pointer;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    gap: 1.6rem 4rem;

    &:has(+ #{$this}) {
        @include res-bigdesk {
            border-bottom: none;
        }
    }

    &:first-of-type {
        border-top-left-radius: $default-border-radius;
        border-top-right-radius: $default-border-radius;
    }

    &:not(:has(+ #{$this})) {
        border-bottom-left-radius: $default-border-radius;
        border-bottom-right-radius: $default-border-radius;
    }

    @media (max-width: 1199px) {
        border-radius: $default-border-radius;
        padding: 2.4rem;
    }

    &:hover {
        box-shadow: 0px 0px 12px 0 transparentize($blue, 0.9);
        z-index: 3;
    }

    &__data {
        display: flex;
        flex-direction: column;
        justify-content: center;

        gap: 0.4rem;

        font-size: 1.3rem;
        color: $text-grey-light;
        line-height: 1.2;

        & > p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        & > strong {
            display: block;
            font-size: 1.4rem;
            font-weight: 500;
            color: $text-grey-dark;
            line-height: 1.2;
        }

        &.--meta {
            order: 1;
            flex: 1 1 calc(50% - 2rem);
            @include res-tablet {
                flex: 1;
                min-width: 12rem;
                max-width: 12rem;
            }
            @include res-bigdesk {
                width: 100%;
                max-width: 15rem;
                max-width: 15rem;
            }
        }

        &.--ler {
            order: 1;
            flex: 1 1 calc(50% - 2rem);
            @include res-tablet {
                flex: 0 0 calc(100% - 12rem - 4rem); // 100 - meta - gap
            }
            @include res-bigdesk {
                width: 100%;
                min-width: 15rem;
                max-width: 15rem;
            }
        }

        &.--manager {
            order: 3;

            max-width: 0;
            min-width: 100%;
            @include res-tablet {
                min-width: unset;
                max-width: 100%;
            }
            @include res-bigdesk {
                min-width: 20rem;
                max-width: 20rem;
            }
        }

        &.--carrier {
            order: 3;
            max-width: 0;
            min-width: 100%;
            @include res-tablet {
                min-width: unset;
                max-width: 100%;
            }
            @include res-bigdesk {
                min-width: 20rem;
                max-width: 20rem;
            }
        }

        &.--volumen {
            order: 4;

            width: 100%;
            min-width: 0%;
            max-width: 7rem;

            @include res-bigdesk {
                margin-left: unset;
            }
        }

        &.--actions {
            order: 10;

            min-width: calc(100%);
            max-width: calc(100%);

            flex-direction: row;
            justify-content: flex-end;

            @include res-bigdesk {
                order: 5;
                min-width: unset;
                margin-left: auto;
            }

            button,
            a {
                background: transparent;
                border: 0;
                line-height: 1;
                appearance: none;
                cursor: pointer;
                transition: all 0.15s linear;
                font-family: inherit;
                font-size: inherit;
                color: inherit;
                font-weight: inherit;
                border-radius: 5px;

                display: flex;
                align-items: center;
                gap: 0.6rem;
                padding: 0.8rem;
                font-size: 1.4rem;

                &:hover {
                    background: $background-grey;
                }
                &:disabled {
                    pointer-events: none;
                }
            }
        }

        &.--status {
            order: 7;
            margin-left: auto;

            @include res-bigdesk {
                order: 5;
            }

            padding: 4px 10px;
            margin-top: 1px;

            border-radius: 999px;
            font-weight: 700;
            background: #f2f4f7;
            font-size: 1rem;
            text-transform: uppercase;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;

            & > span {
                position: relative;
                bottom: -1px;
            }

            &.--READED {
                color: $purple;
                background: transparentize($purple, 0.85);
            }
        }

        &.--observation {
            order: 6;
            flex: 1 1;
            min-width: 100%;

            font-style: italic;
            color: $blue;
            line-height: 1.6;

            margin-bottom: auto;
            margin-top: auto;

            display: flex;
            justify-content: flex-start;
        }
    }

    &__preDi {
        color: $purple !important;
        & > span {
            @media (max-width: 1199px) {
                display: none;
            }
        }
    }

    &--updating {
        @include skeleton-animation();
    }

    &--skeleton {
        @include skeleton-animation();
        pointer-events: none;

        #{$this}__data {
            &.--meta {
                & > * {
                    @include skeleton($letters: 14);
                }

                & > strong {
                    @include skeleton($letters: 8);
                }
            }

            &.--ler {
                & > * {
                    @include skeleton($letters: 14);
                }

                & > strong {
                    @include skeleton($letters: 8);
                }
            }

            &.--manager {
                @include skeleton($letters: 18);
            }

            &.--carrier {
                @include skeleton($letters: 18);
            }

            &.--volumen {
                @include skeleton($letters: 4);
            }

            &.--actions {
                display: none;
            }

            &.--status {
            }

            &.--observation {
            }
        }
    }
}
