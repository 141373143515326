@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

.newUserForm {
    &__containerInputs {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        @include res-desk {
            flex-direction: row;
            align-items: center;
        }

        & > * {
            flex: 1;
        }
    }

    .buttonAux {
        margin-top: 1.5rem;
    }
}

.newUser {
    &__form {
        .input__container input,
        .input__container .input__autocomplete {
            width: 100%;
        }
        .input__container {
            margin-right: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    &__containerPromotions {
        margin-bottom: 1rem;
        .input__autocomplete {
            width: 100%;
        }
    }

    &__flex {
        display: flex;
        flex-direction: row;
        .input__container,
        .input__container .input__autocomplete {
            width: 100%;
            margin-right: 1rem;
        }
    }

    &__radioContainer {
        display: flex;
        flex-direction: column;
        font-size: 1.3rem;
        margin: 1rem 0rem;

        h3 {
            margin-bottom: 0.75rem;
        }
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 0.5rem;
            .checkbox {
                width: 2rem;
                margin-right: 0.5rem;
            }
            label {
                line-height: 2rem;
                width: 100%;

                span {
                    font-weight: 700;
                }
            }
        }
    }

    &__promotionsSelected {
        border-radius: 15px;
        background-color: $light-purple;
        padding: 0.2rem 2rem;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-bottom: 1rem;
        svg {
            margin-left: 2rem;
            cursor: pointer;
            width: 15px;
        }
    }

    &__containerPromotionsSelected {
        margin-top: 1rem;
    }
}
