@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.documentType {
    cursor: pointer;

    /* Este CSS intenta sobreescribir el estilo de la librería de selectAutocomplete. Esta librería genera
        random el nombre de las clases, así que hay que aplicar CSS por tipo de elementos etc. Por eso no es legible */

    & > :nth-child(2) {
        & > :nth-child(1) {
            padding: 0.3rem;
            background: url('../../../assets/iconos/tablerIcons/folder_Mesa\ de\ trabajo\ 1.png') no-repeat left;
            background-size: 30px;
            padding-left: 10px;
            background-origin: content-box;

            & > * {
                opacity: 0;
            }
        }

        & > :nth-child(2) {
            span {
                background-color: $purple;
            }

            svg {
                fill: $purple;
            }
        }
    }
}
