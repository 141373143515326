@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.smallMetricCard {
    border-radius: $default-border-radius;
    background-color: $light-purple;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 1.5rem;

    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1rem;

        @include res-desk {
            flex-direction: column;
            align-items: flex-start;
            padding: 0.4rem 0rem;
            width: 100%;
            gap: 0.25rem;
        }
    }

    &__title {
        font-weight: 700;
    }
}
