@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.document {
    $this: &;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 1.5rem 0;
    padding: 1rem 1rem;

    @include res-tablet {
        flex-direction: row;
        padding: 2rem 2rem;
    }

    &__infoContainer {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;

        @include res-tablet {
            margin-bottom: 0rem;
        }

        .icon__circle {
            margin-right: 1.5rem;
        }
    }

    &__date {
        font-weight: 600;
        font-size: 1.4rem;
    }

    &__containerButtons {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &__traceabilitiesNumber {
        background-color: $light-purple;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.2rem 1rem;
        font-size: 1.3rem;
        color: $grey-dark;
        margin-right: 1rem;

        .icon {
            margin-right: 0.5rem;
        }
    }

    &--is-skeleton {
        @include skeleton-animation();

        #{$this}__icon {
            font-size: 2.8rem;
            padding-right: 2rem;
            @include skeleton($letters: 3);
        }

        #{$this}__info {
            // display: flex;
            font-size: 1.4rem;
            & > div:nth-child(1) {
                @include skeleton($letters: 24);
            }
            & > div:nth-child(2) {
                @include skeleton($letters: 18);
            }
        }
    }
}
