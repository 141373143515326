@import 'styles/abstracts/variables';

.WasteByValorization {
    &__header {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: space-between;

        h3 {
            font-weight: 500;
            color: $blue-cocircular;
            font-size: 1.6rem;
        }
    }

    &__noResultsMessage {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 250px;

        color: $tooltip-grey;
        font-weight: 600;
        font-size: 2.4rem;
    }
}
