@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteSummaryPrintable {
    .card {
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
    }

    .WasteSummary {
        transform: scale(0.9);
    }
}
