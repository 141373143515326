@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.ErrorBoundary {
    background: white;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
    overflow: auto;

    &__title {
        color: $text-grey-dark;
        font-weight: 900;
        font-size: 2.4rem;
    }

    &__container {
        max-width: 60rem;

        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        align-items: center;
        text-align: center;
        // justify-content: center;
    }

    &__msg {
        color: $text-grey-light;
        font-size: 1.4rem;
        font-weight: 400;
    }

    &__beaver {
        max-width: 40rem;
        transform: scaleX(-100%);
    }

    &__goHomeButton {
        margin-top: 2.4rem;
    }

    &__link {
        color: $purple;
    }
}
