@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SupportWidget {
    $this: &;
    position: fixed;
    z-index: 100;
    bottom: 0;
    right: 0;

    padding: 0 0.8rem 0.8rem 0;

    display: flex;
    gap: 1.6rem;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    &--open {
        #{$this}__buttonContent.--open {
            opacity: 0;
            transform: translate(-50%, -50%) scale(0.85);
        }

        #{$this}__buttonContent.--closed {
            opacity: 1;
            transform: translate(-50%, -50%) rotate(0deg) scale(1);
        }

        #{$this}__modal {
            display: block;
            animation: SupportWidget__modal--open 0.45s cubic-bezier(0, 1.2, 1, 1);
            
            transform: scale(1);
            opacity: 1;
        }
    }

    &__modal {
        position: relative;
        background: white;
        border-radius: $default-border-radius;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
        width: 100%;

        max-height: calc(100vh - 12rem);
        overflow: auto;

        width: 34.4rem;

        @include res-tablet {
            width: 48rem;
        }

        display: none;

        transition: all 0.35s cubic-bezier(0, 1.2, 1, 1) allow-discrete;

        transform: scale(0.35);
        transform-origin: bottom right;
        opacity: 0;
        color: $text-grey-dark;
    }

    &__modalHeader {
        padding: 1.2rem 2.4rem;
        
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px solid $grey;
    }

    &__button {
        position: relative;

        --_size: 58px;
        width: var(--_size);
        height: var(--_size);
        border-radius: 100%;
        
        background-color: $purple;
        border-color: $purple;
        color: white;
        
        cursor: pointer;

        font-size: 1.2rem;
        font-weight: 600;
        transition: 0.25s all ease;
        outline: none;

        & > svg {
            transform: scaleX(-1)
        }

        &:active {
            transform: scale(0.85);
            transition: transform 134ms cubic-bezier(0.45, 0, 0.2, 1);
        }
    }

    &__buttonContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;

        transition: 0.35s all cubic-bezier(0.45, 0, 0.2, 1);

        &.--open {
            opacity: 1;
            padding-bottom: 0.4rem;
        }

        &.--closed {
            opacity: 0;
            transform: translate(-50%, -50%) rotate(90deg) scale(0.85);
        }
    }

    &__modalContent {
        padding: 2.4rem;
    }

    &__modalHeaderClose {
        border-radius: 6px;
        cursor: pointer;
        padding: 3px;
        margin-right: -3px;
        &:hover {
            background: $light-grey;
        }
    }

    &__cta {
        display: flex;
        gap: 1.6rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-style: italic;
        text-align: center;
        background: #f8f8ff;
        padding: 2.4rem;
        border-radius: $border-radius-buttons;
    }

    &__ctaText {
        font-size: 1.4rem;

        &.--small {
            font-size: 1.2rem;
        }
    }

    &__ctaButton {
        min-width: 18rem;
        justify-content: center;
    }
}


@keyframes SupportWidget__modal--open {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        transform: scale(1.025);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
