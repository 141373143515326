@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilityDetail {
    $this: &;

    .card {
        padding: 1.6rem;
        @include res-desk {
            padding: 2.4rem;
        }
    }

    &__errorMsg {
        margin-bottom: 1.6rem;
    }

    &__section {
        margin-top: 4rem;

        & > h2 {
            margin-bottom: 2.4rem;
        }
    }

    &__cardTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 3.2rem;
        gap: 1rem;
    }

    &__break {
        flex: 1 1 100%;
    }

    &__data {
        $_gap: 3.2rem;

        display: flex;
        gap: 0 $_gap;
        flex-wrap: wrap;
        margin-bottom: -2.4rem;

        dl {
            font-size: 1.4rem;
            margin-bottom: $_gap;
        }

        dt {
            font-weight: 300;
            color: lighten($blue, 15%);
        }

        dd {
            font-weight: 500;
            color: $blue;

            p {
                margin-bottom: 1.6rem;
            }
        }

        &--one-field-per-row {
            & > * {
                flex: 1 1 100%;
            }
        }
    }

    &__status {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        &--PENDING {
            --_display: block;
            --_color: #{$purple};
        }
        &--WARNING {
            --_display: block;
            --_color: #{$orange-status};
        }
        &--COMPLETED {
            --_display: block;
            --_color: #{$green-status};
        }
        &--DENIED {
            height: 23.8px; // line height

            &::after {
                content: '\D7';
                font-size: 4rem;
                font-weight: 300;
                color: #{$red-status};
                // align 'X' vertically
                position: relative;
                bottom: 1.5px;
            }
        }
        &--CANCELED {
            --_display: block;
            --_color: #{$red-status};
        }

        &::before {
            content: '';
            display: var(--_display, none);
            width: 1.2rem;
            height: 1.2rem;
            background: var(--_color);
            border-radius: 50%;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;

        gap: 3.2rem;

        @include res-desk {
            flex-direction: row;
        }
    }

    &__section {
        margin-top: 3.2rem;
    }

    &__stakeholders {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;

        @include res-desk {
            flex-direction: row;
        }

        & > * {
            flex: 1 1 100%;
        }
    }

    &--is-loading,
    &--has-error {
        #{$this}__data {
            dt {
                @include skeleton($letters: 6);
            }

            dd {
                @include skeleton($letters: 16);
            }
        }
    }

    &--is-loading {
        #{$this}__data {
            @include skeleton-animation();
        }
    }

    &--has-error {
        #{$this}__data {
            @include skeleton-animation($error: true);
        }
    }
}
