*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

// this defines what 1rem is
html {
    font-size: 62.5%;
    overflow: hidden;
}

body {
    color: $grey-dark;
    font-size: 1.6rem;
    overflow: hidden;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    /* div principal */
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    height: 100%;
    height: 100vh;
    overflow: hidden;

    @media (max-width: 600px) {
        height: calc(var(--vh, 1vh) * 100);
    }
    @include res-bigdesk {
        flex-direction: row;
    }
}

a {
    text-decoration: none;
    color: $grey-dark;
}

a:link :hover :visited {
    text-decoration: none;
}

form {
    display: flex;
    flex-direction: column;
}
.hr__container {
    display: flex;
    align-items: center;
    justify-content: center;
    hr {
        width: 95%;
    }
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}

/* input[type="date"] {
	display: block;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	min-height: 1.2em;
} */

.mt-1 {
    margin-top: 1rem;
}
.mb-1 {
    margin-bottom: 1rem;
}

.link {
    color: $purple;
    text-decoration: underline;
}
