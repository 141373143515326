@import '../../../styles/abstracts/_mixins.scss';
@import '../../../styles/abstracts/_variables.scss';

.wasteManagerResult {
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid $tooltip-grey;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
.wasteManagerResult h5 {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1;
}
.wasteManagerResult p {
    font-size: 1.2rem;
    font-weight: 300;
}
.wasteManagerResult__checkbox {
    width: 1.5rem;
}

.carrierResult {
    align-items: center;
    margin-left: 3rem;
    border-bottom: none;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.carrierResult span {
    font-weight: 600;
}
.carrierResult .lerLine__error {
    color: $orange;
}
