@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesSummaryPrintable {
    // A workaround to ensure that the page is not cut off when printing
    width: 133%;
    transform: scale(0.75);
    transform-origin: top left;
    margin-bottom: -15%;

    tr {
        // avoid page breaks in the middle of the card
        page-break-inside: avoid;
    }

    .card {
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
    }

    .Tooltip {
        display: none;
    }

    .TraceabilitiesSummary__header {
        display: none;
    }
}
