@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIBarChart {
    position: relative;
    margin: 0 -2.4rem;
    width: calc(100% + 4.8rem);
    $this: &;

    &::after {
        position: absolute;
        right: 0;
        top: 0;

        content: '';
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        width: 3.2rem;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
    }

    &__scroll {
        overflow-x: auto;
        @include res-bigdesk() {
            overflow-x: unset;
        }
    }

    &__chart {
        height: 300px;
        padding: 1.6rem;
        @include res-bigdesk() {
            min-width: unset !important;
        }
    }

    &__legend {
        padding: 0 2.4rem;
        display: flex;
        gap: 3.2rem;
    }

    &__legend-item {
        display: flex;
        align-items: center;
    }

    &__legend-text {
        font-size: 1.3rem;
        color: $text-grey-light;
        margin-left: 0.8rem;
        line-height: 100%;
    }

    &__label {}

    &--clickable {
        g g rect {
            cursor: pointer;
        }
        #{$this}__label {
            cursor: pointer;
        }
    }
}
