@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.FlowHistoryChart {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.6rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: $text-grey-dark;
    }

    &__goback {
        padding: 0.5rem;
        margin-left: -1rem;
        color: $purple;
        margin-bottom: 0.8rem;
    }
}
