@import '../../../styles/abstracts/variables';

.fastAccessButtons {
    background-color: rgba($light-purple-hover, 0.3);
    border: none;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Be Vietnam Pro', sans-serif;
    cursor: pointer;
    color: $purple;
    font-weight: 500;
    transition: 0.2s all ease-out;
    margin-left: 1.5rem;

    &:hover {
        background-color: $light-purple-hover;
    }
}
