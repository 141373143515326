@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

.switch {
    $this: &;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    label {
        font-size: 1.4rem;
        color: $text-grey-dark;
        cursor: pointer;
    }

    &--small {
        #{$this}__component {
            transform: scale(0.75);
            margin-left: -6px;
            margin-right: -6px;
        }

        label {
            font-size: 1.3rem;
        }
    }
}
