@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.DateRangeFilter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 1.2rem;

    &__clear {
        width: 1.6rem;
        height: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        color: $text-grey;
        cursor: pointer;
        margin-left: 0.8rem;
    }

    &__label {
        text-transform: capitalize;
        font-size: 1.3rem;
        font-weight: 300;
        padding-top: 0.85rem;
        white-space: nowrap;
    }

    &__fields {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        background: $background-grey;
        // border: 1px solid darken($background-grey, 5%);
        border-radius: 10px;
        padding: 0.4rem;
    }

    &__separator {
        font-weight: 300;
        color: $tooltip-grey;
    }

    .input__autocomplete {
        width: auto;
        display: flex;
        align-items: center;

        & > * {
            flex: 1 1 100%;
        }
    }

    .input__wrapper {
        background: none;
        margin: 0;
    }

    .input__input {
        border-radius: 0;
        min-width: 12.5rem;
        background: none;
        padding: 0.8rem 0.6rem;
        height: auto;
        font-weight: 500;
        text-transform: uppercase;
        color: $purple;
        font-size: 1.3rem;
        border-bottom: 1px solid transparentize($grey-dark, 0.9);
        padding: 6.75px 8px;
        background: $light-purple;
        border-radius: 10px;
        border: none;
        min-height: 3.2rem;

        &[value=''] {
            background: transparent;
            letter-spacing: -0.5px;
            text-transform: lowercase;
            color: $tooltip-grey;
        }
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
    }

    &--is-chip {
        flex-direction: row;
        gap: 0.8rem;
    }
}
