@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingItem {
    $this: &;
    border: 1px solid $border-grey;
    border-top-color: transparent;
    background: white;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    position: relative;
    will-change: margin, border-radius, height;

    &--clickable {
        #{$this}__row {
            cursor: pointer;
        }

        &:hover {
            box-shadow: 0px 0px 20px 0 transparentize($blue, 0.9);
            z-index: 3;
        }
    }

    &:has(+ #{$this}--expanded) {
        border-bottom-left-radius: $default-border-radius;
        border-bottom-right-radius: $default-border-radius;
    }

    &--expanded {
        border-top-color: $border-grey;
        border-radius: $default-border-radius;

        &:not(:first-child) {
            margin-top: 2.4rem;
        }
        &:not(:last-child) {
            margin-bottom: 2.4rem;
        }

        #{$this}__row {
            border-bottom: 1px solid $border-grey;
        }

        #{$this}__detail {
            animation: AutomaticReadingItem__expand 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
        }

        & + #{$this} {
            border-top-left-radius: $default-border-radius;
            border-top-right-radius: $default-border-radius;
            border-top-color: $border-grey;
        }
    }

    &--collapsed {
        &:first-child {
            border-top-color: $border-grey;
            border-top-left-radius: $default-border-radius;
            border-top-right-radius: $default-border-radius;
        }

        &:last-child {
            border-bottom-left-radius: $default-border-radius;
            border-bottom-right-radius: $default-border-radius;
        }
    }

    &__row {
        min-height: 80px;
        padding: 1.6rem 2.4rem;
        font-weight: 300;
        font-size: 1.3rem;

        display: flex;
        align-items: center;
        gap: 2.4rem;

        position: relative;
        background: white;

        @media (max-width: 1360px) {
            flex-wrap: wrap;
        }
    }

    &__checkbox {
        flex: 0 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        min-width: 5.6rem;

        // To expand clickable area
        padding: 2rem;
        margin: -2rem;
    }

    &__metadataRow {
        flex: 1 1;
        min-width: 30rem;
        @media (max-width: 475px) {
            min-width: calc(100% - 4rem);
        }
    }

    &__resultsRow {
        flex: 1 1 auto;
        min-width: 30rem;
        max-width: 45rem;
        display: flex;

        @media (max-width: 475px) {
            min-width: unset;
        }
    }

    &__statusRow {
        margin-left: auto;
        flex: 1;
        min-width: 16rem;
        max-width: 20rem;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.8rem;

        @media (max-width: 475px) {
            min-width: unset;
        }
    }

    &__filename {
        color: $text-grey-dark;
        font-weight: 400;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__metadataContainer {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        color: $text-grey-light;
    }

    &__metadata {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        min-width: 0px;

        & > svg {
            min-width: 16px;
        }

        & > span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__detail {
        position: relative;
        z-index: 1;
    }

    &__actions {
        display: flex;
        gap: 0.4rem;

        max-width: 0px;
        opacity: 0;
        transition: all 0.3s ease-in-out 0s;
        pointer-events: none;

        button {
            min-width: 2.4rem;
            min-height: 2.4rem;
            padding: 0.4rem;
            border-radius: 100%;
        }
    }

    #{$this}__row:hover #{$this}__actions {
        opacity: 1;
        max-width: 60px;
        pointer-events: unset;
        transition-delay: 0.5s;
    }

    &--is-skeleton {
        @include skeleton-animation();
        pointer-events: none;

        #{$this}__filename {
            @include skeleton($letters: 18);
            font-size: 12px;
        }

        #{$this}__metadataContainer {
            font-size: 8px;
            @include skeleton($letters: 18);
        }

        #{$this}__resultsRow {
            font-size: 8px;
            @include skeleton($letters: 60);
        }

        #{$this}__statusRow {
            font-size: 16px;
            @include skeleton($letters: 8);
        }
    }
}

@keyframes AutomaticReadingItem__expand {
    0% {
        // max-height: 0;
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        // max-height: 100vh;
        transform: translateY(0%);
        opacity: 1;
    }
}
