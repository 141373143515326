@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TagManager {
    $this: &;

    &__link {
        color: lighten($purple, 15%);
        transition: all .3s linear;
        &:hover {
            color: darken($purple, 15%);
        }
    }

    &__list {
        margin: 3.2rem -2.4rem -2.4rem;
        padding: 0;
        list-style: none;
    }

    &__enterprise {
        max-width: 48rem;
    }

    &__listHeader {
        background: $light-grey;
        padding: 1.2rem 2.4rem;
        font-size: 1.3rem;
        color: $text-grey-dark;
    }

    &__listFooter {
        padding: 2.4rem;
        display: flex;
        justify-content: center;
    }

    &--updating {
        #{$this}__list {
            @include skeleton-animation();
        }
    }
}
