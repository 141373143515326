@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilityDetailPreview {
    /** chrome background preview color */
    $_pdf-color: #393d3f;

    display: flex;
    align-items: center;
    justify-content: center;

    flex: 0 0 70rem;
    height: 42rem;
    border-radius: 1rem;
    overflow: hidden;
    border: 1px solid $grey;
    background: $grey;
    color: transparentize($color: $blue, $amount: 0.65);
    position: relative;
    font-size: 1.4rem;

    iframe {
        border: none;
        height: 100%;
        width: 100%;
    }

    &__openDi {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }

    &__fallback {
        color: darken($color: $grey, $amount: 25%);
        font-size: 5.6rem;
        font-weight: 700;
        opacity: 0.33;
    }
}
