@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.visita {
    &__data {
        @include res-tablet {
            display: flex;
            justify-content: space-between;
        }

        & > div {
            @include res-tablet {
                width: 50%;
                min-height: 100%;
            }

            &:first-child {
                margin-bottom: 2rem;
                @include res-tablet {
                    margin-right: 2rem;
                    margin-bottom: 0rem;
                }
            }
        }

        .PromotionInfo {
            h1 {
                font-size: 2rem;
            }
        }
    }

    &__onSite {
        cursor: pointer;
        margin-top: 3rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        input[type='checkbox'] {
            display: inline-block;
            appearance: checkbox;
            width: 1.75rem;
            height: 1.75rem;
        }
    }

    &__dataInputs {
        display: flex;
        flex-wrap: wrap;

        & > *:not(:last-child) {
            margin-bottom: 2rem;

            @include res-desk {
                margin-right: 3rem;
                margin-bottom: 0rem;
            }
        }
    }

    &__lerTypes {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 3rem;
        overflow-x: auto;

        @include res-bigdesk {
            max-height: 500px;
        }
    }

    &__lerSection {
        & > h3 {
            margin-top: 3rem;
            margin-bottom: 1.5rem;
        }
    }

    &__containerSection {
        border: 2px solid $grey;
        padding: 2rem;
        border-radius: $default-border-radius;
        margin-bottom: 2rem;

        & > div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 2rem;
        }
    }

    &__containerData {
        display: grid;
        gap: 3.5rem;

        @include res-tablet {
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }
    }

    &__option {
        & > :first-child {
            margin-bottom: 0.5rem;
        }
    }

    &__resumenSection {
        margin-bottom: 4rem;

        & > h4 {
            margin-bottom: 0.5rem;
        }

        & > ul {
            margin-top: 1rem;
            margin-left: 1.5rem;
        }

        & .button__submit {
            margin: 2rem 0rem;
        }

        &-small {
            @include res-bigdesk {
                max-width: 40%;
            }
        }
    }
}

.lerTypes {
    &__type {
        display: flex;
        align-items: center;
        margin: 1.25rem;
        margin-left: 0rem;
        flex-wrap: wrap;

        & > span {
            min-width: 18rem;
        }
    }

    &__options {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.5rem;
    }

    &__option {
        & > input {
            display: none;
        }

        & > label {
            text-align: center;
            display: block;
            cursor: pointer;
            min-width: 8rem;
            padding: 0.4rem 2rem 0.25rem;
            border-radius: 999px;
            border: 2px solid $grey;
            color: lighten($blue, 15%);

            &:hover {
                background-color: #f8f8ff;
            }
        }

        & > input[type='radio']:checked + label {
            background-color: $purple;
            border: 2px solid $purple;
            color: white;
        }
    }
}
