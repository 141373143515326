@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SegmentedControl {
    display: flex;
    align-items: center;

    &__item {
        overflow-x: hidden;
        padding: 0.4rem 1.6rem;
        min-height: 4rem;
        min-width: 5.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 1px solid $grey;
        font-size: 1.3rem;
        cursor: pointer;

        @include res-tablet {
            min-width: 4.2rem;
            min-height: 3.6rem;
            padding: 0.2rem 1.6rem;
        }

        &:first-child {
            border-radius: 10px 0px 0px 10px;
        }

        &:last-child {
            border-radius: 0px 10px 10px 0px;
        }

        &:not(:last-child) {
            border-right: none;
        }

        &--active {
            background: $light-purple;
            color: $purple;
        }
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        display: flex;
        align-items: center;
    }

    input {
        visibility: hidden;
        position: absolute;
    }
}
