@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionAnalysis {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 2rem;

    img {
        max-width: 960px;
    }
}
