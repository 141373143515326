.SelloValorizaBilleableForm {
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;

    &__type {
        flex: 1 1 100%;
        font-size: 1.4rem;
    }
}
