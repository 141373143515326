@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.newPromotionRequest {
    &__form {
        .input__autocomplete,
        .input__container {
            @include res-desk {
                width: 40%;
                margin: 0;
                margin-bottom: 1rem;
            }
        }
        .input__container input {
            width: 100%;
        }
        button {
            margin-top: 2rem;
        }

        p {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }
    }
    &__containerInputs {
        display: flex;

        .input__autocomplete,
        .input__container {
            margin-right: 2rem;
        }
    }

    &__textArea {
        display: flex;
        flex-direction: column;

        textarea {
            width: calc(80% + 2rem);
            height: 15rem;
        }
    }
}
