@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

@keyframes SpinnerTiny__rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.SpinnerTiny {
    width: 20px;
    height: 20px;
    position: relative;

    & > svg {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        animation: SpinnerTiny__rotate 1s linear infinite;
    }
}
