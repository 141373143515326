@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.OnboardingResourceItem {
    $this: &;
    position: relative;
    display: flex;
    gap: 2rem;
    padding: 1.2rem;
    border-radius: $default-border-radius;
    transition: all 0.2s;

    color: $blue-cocircular;

    &:hover {
        cursor: pointer;
        background: $light-grey;
    }

    &.--big {
        @include res-bigdesk {
            flex-direction: column;
            gap: 1.2rem;

            #{$this}__thumbnail {
                padding-bottom: 50%;
                max-width: 100%;
            }

            #{$this}__info {
            }
        }
    }

    &.--tiny {
        @include res-bigdesk {
            gap: 1rem;
            padding: 0.8rem 0.6rem;
            &:hover {
                background: unset;
            }

            #{$this}__thumbnail {
                max-width: 5rem;
                width: 5rem;
                height: 4rem;
            }

            #{$this}__title {
                // max 1 line
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            #{$this}__description {
                font-size: 1.3rem;

                // max 2 lines
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            #{$this}__state {
                transform: translate(-40%, -40%) scale(0.75);
            }
        }
    }

    &__thumbnail {
        padding-bottom: 6%;
        width: 100%;
        max-width: 14rem;
        background: $grey;
        position: relative;
        border: 1px solid $border-grey;
        border-radius: $default-border-radius;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: $default-border-radius;
            object-fit: cover;
            object-position: center center;
            transform: scale(1.1);
        }
    }

    &__state {
        position: absolute;
        z-index: 1;

        transform: translate(-40%, -40%);
        border: 1px solid $border-grey;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        background: white;
        color: white;

        height: 2rem;
        width: 2rem;

        &.--seen {
            color: $green;
            border-color: $green;
            background: lighten($green, 60%);

            div {
                display: flex;
                align-items: center;
            }
        }
    }

    &__info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        font-size: 1.4rem;
        font-weight: $text-grey-dark;
        font-weight: 475;
    }

    &__description {
        font-size: 1.4rem;
        color: $text-grey-light;
    }
}
