@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

.button__icon {
    min-width: 3.2rem;
    position: relative;
    padding: 0.2rem 0.7rem;
}
.button__icon--disabled {
    color: $tooltip-grey;
    cursor: not-allowed;
}
