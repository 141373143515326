@import 'styles/abstracts/variables';

.Accordion {
    $this: &;
    $animation-duration: 0.35s;
    width: 100%;
    border-bottom: 1px solid #e2e2e2;

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        background-color: transparent;
        border: none;
        cursor: pointer;

        span {
            font-family: 'Be Vietnam Pro', sans-serif !important; // not inheriting this font family by default??? why??
        }

        svg {
            height: 24px;
            width: 24px;
            min-width: 24px;

            transition: transform $animation-duration;
            transform-origin: center 14px; // half of icon's height (12px) + a small increase to improve the effect's look
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transform: rotate(-180deg);
        }
    }

    &__content {
        pointer-events: none;
        opacity: 0;
        height: 0px;

        -moz-transition: height $animation-duration;
        -ms-transition: height $animation-duration;
        -o-transition: height $animation-duration;
        -webkit-transition: height $animation-duration;
        transition: height $animation-duration;
    }

    &--is-open {
        border-bottom: none;

        #{$this}__header {
            svg {
                transform: rotate(0deg) !important;
            }
        }

        #{$this}__content {
            pointer-events: unset;
            opacity: 1;
        }
    }
}
