@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteByValorizationTable {
    $this: &;
    max-width: 100%;
    overflow-x: scroll;

    &__scroll-container {
        overflow-y: auto;
        margin: 0 -2.4rem;
        padding: 0 2.4rem;

        &::-webkit-scrollbar {
            display: none;
        }

        @include res-desk {
            overflow-y: unset;
            margin: 0;
            padding: 0;
        }
    }

    table {
        width: 100%;
        text-align: right;
        font-size: 1.4rem;
        border: 0;
        min-width: 80rem;
        border-collapse: collapse;
    }

    th,
    td {
        padding: 0.8rem 1.2rem;
    }

    th {
        font-weight: 400;
    }

    td {
        color: darken($blue, 15%);
        white-space: nowrap;

        span {
            line-height: 1.1;
        }

        & > div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.2rem;
        }
    }

    thead {
        th {
            color: lighten($blue, 5%);
            text-transform: uppercase;
            font-size: 1.2rem;
            white-space: nowrap;
        }
    }

    &--green {
        color: #46b958 !important;
    }

    &--yellow {
        color: #c5ab00 !important;
    }

    &--red {
        color: #c84424 !important;
    }

    tbody {
        th {
            text-align: left;
        }

        tr:nth-child(odd) {
            > * {
                background: $grey;
                // border-top: 1px solid darken($grey, 5%);
            }

            > *:first-child {
                border-radius: 1rem 0 0 1rem;
            }

            > *:last-child {
                border-radius: 0 1rem 1rem 0;
            }
        }
    }

    &__mixed {
        color: #e38420;
        font-weight: 500;

        &::before {
            content: '⚠ ';
        }
    }

    &__no-results-msg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        z-index: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $tooltip-grey;
        font-weight: 600;
        font-size: 2.4rem;
    }

    &__smallText {
        font-size: 1.2rem;
        color: #3d5671;
    }

    &--skeleton {
        th,
        td {
            @include skeleton($letters: 18);
            background: none !important;
            border-bottom: 1px solid $grey;
        }
    }

    &--loading {
        @include skeleton-animation();
    }

    &--error {
        @include skeleton-animation($error: true);
    }

    &--empty {
        table {
            opacity: 0.3;
        }
    }
}
