@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterForm {
    width: 100%;    
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    $this: &;

    &__header {
        background: $light-grey;
        padding: 2.4rem;
        margin: 0 -1.6rem;
        &:not(:first-child) {
            margin-top: 3.2rem;
        }
    }

    &__header-content {
        max-width: $default-container-width;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.4rem;
        color: $text-grey-light;
        line-height: 1.5;
        strong {
            color: $text-grey-dark;
            font-size: 1.8rem;
        }
    }
    
    &__form {    
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2.4rem;

        & > * {
            flex: 1 1 auto;
            width: min-content;
        }
    }

    &__form, .DynamicForm {
        max-width: $default-container-width;
        margin-left: auto;
        margin-right: auto;
    }


    &__flow {
        flex: 1 1 100%;
        width: 100%;

        .WasteFlowSelectorOption__agents {
            flex-direction: column;
            gap: 0.2rem;
            margin-top: 0.4rem;
        }

        .WasteFlowSelectorOption__agent {
            max-width: 100%;
            width: 100%;
            min-width: 0%;
        }
    }

    &__notificationTo {
        color: #3d5671;
        font-style: italic;
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    &__observation {
        flex: 1 1 100%;
        width: 100%;

        textarea {
            min-height: 12rem;
            line-height: 1.6;
        }
    }

    &__footer {
        width: 100%;
        max-width: $default-container-width;
        margin-left: auto;
        margin-right: auto;

        display: flex;
        gap: 1.6rem;

        background: white;
        border-top: 1px solid $light-grey;
        position: sticky;
        bottom: -1.6rem;
        margin-bottom: -1.6rem;
        left: 0;
        padding: 1.6rem 0;
        width: 100%;
    }

    .input__container {
        display: block;
        margin-top: unset;
    }

    &__field {
        &.--small {
            flex: 1;
            @include res-tablet {
                flex: 0;
            }            
            min-width: 15rem;
        }
    }

    &--mutation-error {
        #{$this}__form {
            @include error-animation-shake();
        }
    }

    &--loading {
        @include skeleton-animation();
        pointer-events: none;
    }

    &__toast {
        font-size: 1.4rem;
        a {
            color: $purple;
            text-decoration: underline;
        }
    }
}
