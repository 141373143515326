@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.MandatoryFlowSelect {
    border: 1px solid $grey;
    padding: 0.4rem 2.4rem 0.4rem 1.6rem;
    border-radius: 10px;
    font-size: 1.3rem;
    width: auto;
    background-image: url("data:image/svg+xml,<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path fill='lightgrey' d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 1.2rem center;
    background-size: 1.2rem;
    &:focus {
        outline: none;
    }

    &--selected {
        background-color: $light-purple;
        color: $purple;
        border-color: $light-purple;
    }

    option[value=""] {
        color: $grey;
    }
}
