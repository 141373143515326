@media print {
    /* All this elements (ALL) are neccesary. Do not remove any of them. We have to override the overflow: hidden in all of them to show all pages */
    body,
    div#root,
    div.layout,
    div.layout__content,
    main#main-layout {
        overflow: visible !important;
    }

    /* Hide or change items / remove box-shadows or backgrounds of existing page elements */
    nav {
        display: none !important;
    }

    main.layout__main {
        padding: 0rem !important;
        background-color: transparent !important;
    }
}
