@import 'styles/abstracts/variables';

.WalleMailAddress {
    appearance: none;
    background: none;
    border: none;
    padding: 0;

    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 0.4rem;

    font-size: 1.4rem;
    border-radius: 10px;
    white-space: nowrap;
    color: $purple;

    display: flex;
}
