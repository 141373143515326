@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ProductsAndServices {
    &__formContainer {
        @include res-desk {
            display: flex;
            gap: 6.4rem;
        }
    }

    &__column {
        & > section {
            margin-bottom: 3.2rem;
        }

        &--first {
            @include res-desk {
                flex: 1 1 55%;
            }
        }

        &--second {
            @include res-desk {
                flex: 1 1 45%;
            }
        }
    }

    &__actions {
        padding-top: 3rem;
    }

    &__form-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 1.5rem;

        h3 {
            margin-left: 1rem;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 3rem;
    }

    &__visits {
        margin-bottom: 2.4rem;
    }

    &__option {
        display: flex;
        padding-left: 1.2rem;

        & label {
            font-size: 1.5rem;
            cursor: pointer;
            display: flex;
            flex-direction: column;
        }

        &-more {
            color: lighten($blue, 15%);
            font-weight: 300;
            font-size: 13px;
            line-height: 1.6;
        }

        & input[type='checkbox'],
        input[type='radio'] {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem;
            margin-top: 0.6rem;
        }

        + * {
            margin-top: 1rem;
        }
    }
}
