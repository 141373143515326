@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteManagementChart {
    &__checks {
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 1;
        
        margin-top: 1.6rem;
        @include res-desk {
            margin-top: -2.4rem;
        }

        font-size: 1.4rem;
        label {
            display: flex;
            gap: 0.4rem;
        }
    }
}
