@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.HomePage {
    margin-bottom: 14rem;

    &__actions {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }

    &__tabs {
        max-width: $default-container-width;

        // 0 margin for tabs in mobile version
        margin: 3.2rem -1.5rem 1.6rem;
        width: calc(100% + 3rem);

        position: relative;

        @include res-desk {
            width: 100%;
            margin: 0rem auto;
        }
    }

    &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        min-width: 16rem;
    }

    &__content {
        max-width: $default-container-width;
        margin: 4rem auto 0rem auto;
    }
}
