@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.topbar {
    display: none;

    background-color: $white;
    box-shadow: $default-box-shadow;
    align-items: center;
    padding: 0.8rem;
    font-size: 1.4rem;
    height: 5.5rem;
    border-bottom: 1.5px solid $grey;
    justify-content: flex-start;

    @include res-bigdesk {
        padding: 1.5rem 3rem;
    }

    &.--showOnMobile {
        display: flex;
    }

    @include res-bigdesk {
        display: flex;
    }

    & > *:last-child {
        margin-left: auto;
    }

    &__logo {
        display: none;
        height: 4rem;
        margin-right: 1.6rem;

        img {
            height: 4rem;
        }
        @include res-tablet {
            display: block;
        }
    }

    &__item {
        display: flex;
        gap: 0.6rem;
        align-items: center;
        line-height: 1;
        font-size: 1.4rem;
        padding: 0.6rem 1.6rem;

        @include res-tablet {
            font-size: 1.4rem;
        }

        &.--highlighted {
            color: $purple;
            font-weight: 600;
        }

        &:is(a):hover {
            background-color: $light-purple-hover;
            color: $purple;
        }

        &:not(.--first):not(.--last) {
            display: none;
            @include res-tablet {
                display: flex;
            }
        }
    }

    &__promoData {
        display: flex;
        gap: 0.4rem;
        align-items: center;
    }

    &__separator {
        background-color: $grey;
        width: 2px;
        content: '';
        height: 2rem;

        &.--lean {
            transform: rotate(16deg);
        }
    }

    &__user {
        cursor: pointer;
        display: flex;
        align-items: center;
        .iconUser {
            margin-left: 1rem;
            border-radius: 50%;
            background-color: $light-purple;
            width: 3.2rem;
            height: 3.2rem;
            font-size: 1.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $purple;
            padding: 0.5rem;
            @include res-tablet {
                width: 4rem;
                height: 4rem;
                font-size: 1.6rem;
            }
        }
    }

    &__userName {
        display: none;

        @include res-bigdesk {
            display: inline;
        }
    }

    &__menu {
        border: 0;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
}
