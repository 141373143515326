@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.Users {
    width: 100%;
    max-width: $default-container-width;
    margin: 0 auto;

    &__header {
        display: flex;
        gap: 24px;
        margin-bottom: 2.4rem;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__add {
        margin-bottom: 2.4rem;
    }

    &__add-content {
        display: flex;
        align-items: flex-end;
        gap: 1.6rem;
    }

    &__search {
        // max-width: 48rem;
        flex: 1;
    }
}
