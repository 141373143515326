@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.Tabs {
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    border-bottom: 1px solid lighten($grey-dark, 65%);
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }

    &__list {
        display: flex;
        list-style: none;
    }

    &__item {
        & > * {
            white-space: nowrap;
            padding: 0.8rem 1.6rem;
            font-weight: 400;
            display: block;
            font-size: 1.4rem;
            position: relative;
            color: $dark-grey;

            &:focus-visible {
                transition: background 0.15s linear;
                outline: none;
                background: #00000008;
            }
        }

        &--active {
            & > * {
                color: $grey-dark;
            }
        }

        @include res-tablet {
            & > * {
                padding: 1.2rem 2.4rem;
            }
        }
    }

    &__indicator {
        transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
        transform-origin: left;
        height: 2px;
        background: black;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}
