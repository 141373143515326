@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WMPlannerItem {
    &--updating {
        @include skeleton-animation();
        pointer-events: none;
    }

    &--mutation-error {
        @include error-animation-shake();
    }

    // Input overrides
    label {
        /** Visually hide the label */
        overflow: hidden;
        position: absolute;
        clip: rect(0 0 0 0);
    }

    .input__container {
        margin-top: 0;
    }

    &__selector {
        height: 44px;
        [class*='-singleValue'] {
            .LerSelectorOption__description { display: none; }
        }
    }

    &__ler {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }
}
