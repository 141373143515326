@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SettingsLayout {
    display: grid;
    --_sidebar-width: 220px;
    --_gap: 2.4rem;

    grid-auto-flow: column;
    grid-template-columns: auto 0 minmax(0, calc(100% - var(--_sidebar-width) - var(--_gap)));
    grid-gap: var(--_gap);
    
    @media (max-width: 1024px) {
        grid-auto-flow: row;
        grid-template-columns: 1fr !important;
    }
    
    @media (min-width: 1080px) {
        --_sidebar-width: 200px;
    }
    
    @media (min-width: 1200px) {
        --_sidebar-width: 256px;
    }

    @media (min-width: 1440px) {
        --_sidebar-width: 296px;
        --_gap: 4.8rem;
    }

    &__sidebar {
        grid-column: 1;

        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    &__link {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        padding: 0.8rem;
        border-radius: 8px;
        font-size: 400;
        transition: background 0.2s linear;

        &:hover {
            background: transparentize($dark-grey, 0.95);
        }
        
        &[aria-current="true"] {
            background: transparentize($dark-grey, 0.8);
        }

        & > span {
            line-height: 100%;
        }
    }

    &__main {
        grid-column: 2/span 2;

        @media (max-width: 1024px) {
            grid-column: 1 !important;
        }
    }

    &__group {
        font-size: 11px;
        font-weight: 800;
        text-transform: uppercase;
        color: $text-grey-dark;

        &:not(:first-child) {
            margin-top: 1.6rem;
        }
    }
}

