
@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

$drag-and-drop-bg: #f6faff;
$drag-and-drop-border-color: #b4d3ff;

.DocumentPreview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    $this: &;

    &--is-loading {
        border: 1px solid $grey;
        background: $light-grey;
    }

    &--has-file {
        #{$this}__dragAndDrop {
            flex: 0
        }
    }

    .dragndrop__file {
        width: 100%;
    }

    .dragndrop__placeholder {
        display: none;
    }

    .dragndrop__container {
        padding: 1.6rem;
        min-height: 0 !important;
    }

    &__preview {
        flex: 1;
        min-height: calc(100% - 14rem);
        position: relative;
        overflow: hidden;
        border: 1px solid $grey;
        border-radius: 8px;
        background: $light-grey;
        min-height: 42rem;

        iframe {
            border: none;
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    &__dragAndDrop {
        display: flex;
        flex-direction: column;
        flex: 1;

        & > div {
            background-color: $drag-and-drop-bg;
            border: 2px dashed $drag-and-drop-border-color;
        }

        & > svg {
            min-width: 40px;
            width: 40px;
            height: 40px;
            stroke-width: 1.5;
            color: $text-grey-light;
        }

        .icon-tabler-upload {
            color: $text-grey-light;
        }

        .dragndrop__file {
            margin: 0.8rem;
        }

        .input__label {
            display: none;
        }

        .dragndrop__container {
            min-height: 14rem;
            height: 100%;
        }
    }

    &__title {
        color: $text-grey-light;
        font-size: 1.8rem;
        font-weight: 450;
        margin: 0.5rem 0rem;
    }

    &__subtitle {
        color: $text-grey-light;
        font-size: 1.4rem;
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & > svg {
            animation: button__spinner-rotate 1s linear infinite;
            width: 64px;
            height: 64px;
        }
    }
}

@keyframes button__spinner-rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
