@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteByValorizationChartPrintable {
    .WasteByValorization__header {
        display: none;
    }

    .WasteByValorizationChart {
        pointer-events: none;
    }

    .card {
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
    }
}
