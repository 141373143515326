@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIs {
    padding: 0 !important;
    overflow: hidden; // for rounded borders to work

    &__vitalsLogo {
        background: transparent;
        border: none;
        padding: none;
        font: inherit;
        text-align: inherit;
        cursor: pointer;

        img {
            max-width: 120px;
            margin-bottom: 0.6rem;
        }
    }

    &__tabs {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 0.8rem;
        padding: 0.8rem;
        @include res-desk {
            gap: 0;
            padding: 0;
        }
    }

    &__tab {
        background: transparent;
        border: none;
        padding: none;
        font: inherit;
        text-align: inherit;
        background-color: transparentize($light-grey, 0.35);

        position: relative;
        padding: 2rem 2.4rem;
        cursor: pointer;
        border-radius: 8px;

        @include res-desk {
            border-radius: 0px;
            border-bottom: 1px solid;
            border-right: 1px solid;
            border-color: transparentize($blue, 0.85);
        }

        &:first-child {
            @include res-desk {
                border-top-left-radius: 8px;
            }
        }

        &:last-child {
            @include res-desk {
                border-top-right-radius: 8px;
                border-right: none;
            }
        }

        &--white {
            background: white;
            box-shadow: 0 0 0 2px transparentize($blue, 0.85);

            @include res-desk {
                box-shadow: none;
                border-bottom-color: transparent;
            }

            &:hover {
                background-color: transparentize($light-grey, 0.35);
            }
        }

        &--grey,
        &--active {
            &:hover {
                background: white;
            }
        }
    }

    &__widget-container {
        padding: 2.4rem;
    }

    &__chart {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 1.6rem;
    }

    &__chart-main-header {
        margin-top: 3.2rem;
    }

    &__chart-secondary-header {
        margin-top: 3.2rem;
    }

    &__showMoreButton {
        width: 100%;
        justify-content: center;
        color: #3d5671;
        border: none;
        border-radius: 0px;
        border-top: 1px solid rgba(52, 73, 96, 0.15);

        &:hover {
            background-color: transparentize($light-grey, 0.35);
        }
    }
}
