.pdf-root {
    .results__table {
        font-size: 1rem;
    }

    .results__table__head {
        background-color: #e7e7e7;
        border-radius: 0px;
    }

    .results__table__titles {
        padding: 0.5rem 1rem;
    }

    .results__table__filas__result {
        height: 1rem;
        padding: 0.5rem 1rem;
    }

    .results__table__container {
        page-break-inside: auto;
    }

    table {
        page-break-inside: auto;
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto;
        border-bottom: 1px solid #e7e7e7;
    }
}

.reports-month-name {
    page-break-inside: avoid;
    margin-top: 20px;
    margin-bottom: -15px;
}

.reports-section-name {
    page-break-inside: avoid;
    margin-top: 20px;
    margin-bottom: 10px;
}

.reports-section-desc {
    page-break-inside: avoid;
    margin-top: -5px;
    margin-bottom: 10px;
}

.flex {
    display: flex;
    align-items: center;
    .button-create {
        margin-left: 1rem;
    }
}

.report-root {
    display: flex;
    flex-direction: column;

    .pdf-button {
        margin: 5rem 0rem;
        align-self: center;
    }
}

.pdf-normal-text {
    page-break-inside: avoid;
    font-size: 1.2rem;
    margin: 1rem 0rem;
}

.resumen-ejecutivo {
    padding: 15px;
    font-family: 'Be Vietnam Pro';
    font-size: 1.2rem;
}

.accordion-trazabilidad__resultado--circulo {
    padding: 0.5rem 1rem;

    .makeStyles-root-1 {
        margin: 0;
    }
}

//Datos generales
.datos-generales {
    &__info {
        font-family: 'Be Vietnam Pro';
        font-size: 1.2rem;
        font-weight: 500;
        span {
            font-weight: 300;
        }
    }

    &__avance-obra {
        display: flex;
        flex-direction: row;
        width: 100%;
        border: 2px solid red;
    }
}

//Portada

.pdf-portada {
    background: url('../../assets/logo-madera-sin-web.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 950px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 50px;

    &__container {
        width: 90%;
        background-color: rgba($color: white, $alpha: 0.8);
        padding: 2rem;
        position: absolute;
        margin-bottom: 5rem;
    }

    &__logos {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    &__logo-cocircular {
        width: 15rem;
    }

    &__titulo {
        text-transform: uppercase;
        line-height: 35px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__logo360 {
        font-size: 2rem;

        &--advisor {
            font-size: 2rem;
            color: #737475;
        }
    }
}

.pdf__none {
    display: none;
}

.display-none {
    display: none;
}

.reports-valorizadas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .total-valorizadas {
        width: 10rem;
        height: 10rem;
        margin: 2rem;
    }

    .total-valorizadas::before {
        width: 10rem;
        height: 10rem;
    }

    .total-valorizadas__icono {
        width: 3rem;
    }

    .total-valorizadas__importe {
        font-size: 1.6rem;
    }
}
