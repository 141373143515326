@import 'styles/abstracts/variables';

.NotificationSettings {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    margin-bottom: 12rem;

    &__header {
        max-width: 76rem;
        & > p {
            color: $text-grey-light;
            font-size: 1.4rem;
        }
    }
}
