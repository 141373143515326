@import 'styles/abstracts/mixins';
@import 'styles/abstracts/variables';

.di {
    h3 {
        font-size: 1.4rem;
        border-bottom: 1px solid rgba($grey-dark, 0.3);
        margin-bottom: 0.5rem;
    }

    h2 {
        font-size: 1.3rem;
    }
    p {
        font-size: 1.1rem;
        font-weight: 700;
        span {
            font-weight: 400;
            margin-left: 0.5rem;
        }
    }

    &__logo {
        width: 15rem;
    }

    &__titleContainer {
        margin-top: 1.5rem;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        h2 {
            font-size: 1.6rem;
        }

        p {
            font-size: 1.2rem;
            font-weight: 300;
        }
    }

    > div > div {
        margin-top: 2rem;
    }

    &__container {
        display: flex;
        flex-direction: row;
        > div {
            width: 50%;
        }
    }

    &__singContainer {
        display: flex;
        flex-direction: row;

        > div {
            width: 33%;
            background-color: $grey;
            margin-right: 0.5rem;
            padding: 1rem;
        }

        p {
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
}
