@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingPage {
    max-width: $default-container-width;
    width: 100%;
    margin: 0rem auto;
    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;
}
