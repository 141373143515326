@import 'styles/abstracts/variables';

.AlertDetailPopover {
    color: $blue-cocircular;
    max-width: 600px;

    .popover__title {
        margin-bottom: 0.5rem;

        h2 {
            white-space: unset;
        }
    }

    &__detail {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem 2rem;
        margin-bottom: 2rem;
    }

    &__level {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        svg {
            min-width: 18px;
            width: 18px;
            height: 18px;
        }

        &--red {
            color: #c84424;
        }

        &--orange {
            color: #c87224;
        }
    }

    &__info {
        font-size: 1.4rem;

        ul {
            margin-top: 0.5rem;
            margin-left: 1.25rem;
        }
    }

    &__link {
        display: block;
        margin-top: 2rem;

        font-size: 1.4rem;
        color: $purple;
        text-decoration: underline;
    }
}
