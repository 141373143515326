@import '../../../styles/abstracts/_mixins.scss';
@import '../../../styles/abstracts/_variables.scss';

.codlerWasteManager__container {
    border: 1px solid hsl(220, 6%, 90%);
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.codlerWasteManager__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    .button__small {
        border: none;
        color: $purple;
        margin: 0;
    }
}

.codlerWasteManager__p {
    font-weight: 600;
}

.codlerWasteManager__ul {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.codlerWasteManager__buttonClose {
    display: flex;
    justify-content: flex-end;
    .button__small {
        border: none;
        color: $purple;
    }
}
.codlerWasteManager__form {
    margin-top: 1rem;
}
