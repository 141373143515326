@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ComparativeExplanation {
    max-width: 925px;

    color: $blue-cocircular;
    font-size: 1.4rem;

    li {
        // display: block;
        margin-left: 4rem;
        margin-bottom: 0.5rem;
    }

    b {
        display: block;
        margin-top: 3rem;

        font-weight: 550;
    }

    p {
        margin-bottom: 0.5rem;
    }

    span {
        background-color: lighten($blue-cocircular, 65%);
        color: lighten($blue-cocircular, 10%);
        // font-size: 1.2rem;
        padding: 0.25rem 0.5rem;
        border-radius: 6px;
    }

    i {
        display: block;
        margin-top: 3rem;
    }
}
