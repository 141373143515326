@import '../../../styles/abstracts/variables';

.containerIconos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icon--edit {
    margin-top: 1rem;
}

.icono {
    &__circle {
        font-size: 0.95rem;
        font-weight: 650;
        border-radius: 50%;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: max-content;

        &-blue {
            background-color: $light-purple;
            svg {
                stroke: $blue-cocircular;
            }
        }

        &-green {
            background-color: $light-green;
            svg {
                stroke: $green;
            }
        }
        &-red {
            background-color: rgba($red, 0.3);
            svg {
                stroke: $red-dark;
            }
        }

        &-orange {
            background-color: $orange-light;
            svg {
                stroke: $orange;
            }
        }
        &-purpledark {
            background-color: rgba(84, 39, 143, 0.7);
            svg {
                stroke: $purple;
            }
        }
        &-purplelight {
            background-color: rgb(128, 125, 186);
            svg {
                stroke: $purple;
            }
        }

        &-small {
            padding: 0.75rem;
        }
        &-purple {
            background-color: $light-purple;
            svg {
                stroke: $purple;
            }
        }

        &-green-ideal {
            background-color: $green-potential;
            svg {
                stroke: $green;
                fill: $green;
            }
        }

        &-purple-ideal {
            background-color: $purple-real;
            svg {
                stroke: rgb(241, 234, 255);
                fill: rgb(241, 234, 255);
            }
        }

        &-purple-standard {
            background-color: $purple-standard;
            svg {
                stroke: $purple;
                fill: $purple;
            }
        }
    }
}
