@import 'styles/abstracts/variables';

.TreatmentContractAssociation {
    color: #2c3e50;
    font-size: 1.4rem;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    h4 {
        font-size: 1.8rem;
        margin-bottom: 0.5rem;
    }

    svg {
        cursor: pointer;
        border-radius: 999px;

        min-width: 24px;
        width: 24px;
        height: 24px;

        &:hover {
            background-color: lighten($purple, 44%);
        }
    }

    &__ppgclData {
        display: flex;
        align-items: center;
        gap: 1rem 2rem;

        p {
            max-width: 400px;
        }
    }

    &__contractAssociated {
        margin-top: 2rem;
        font-weight: 650;
    }

    .SegmentedControl {
        margin: 2rem 0rem;
    }

    &__results {
        padding: 0 1.6rem;
        border: 1.5px solid #eff3f7;
        border-radius: 8px;
        min-height: 125px;

        & > p {
            margin: 1.5rem 0rem;
        }
    }

    &__document {
        display: flex;
        align-items: flex-start;
        gap: 1.6rem;
        width: 100%;
        padding: 1rem 0rem;
        cursor: pointer;

        input[type='radio'] {
            margin-top: 0.6rem;
        }

        &--noDocumentOption {
            font-weight: 600;
        }
    }

    &__open-external {
        margin-left: auto;
    }

    iframe {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
}
