@import 'styles/abstracts/variables';

.Alerts {
    padding-top: 2rem;

    &__title {
        color: $blue-cocircular;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    &__alert {
        cursor: pointer;
        font-size: 1.4rem;
        line-height: 1.6;

        display: flex;
        align-items: flex-start;
        gap: 0.75rem;

        padding: 1rem;
        border: 1px solid black;
        border-radius: 10px;

        svg {
            min-width: 20px;
            width: 20px;
            height: 20px;

            margin-top: 0.5rem;
        }

        &-red {
            color: #c84424;
            background-color: #fff7f7;
            border-color: #fff2f2;
        }

        &-orange {
            color: #c87224;
            background-color: #fffcf7;
            border-color: #fff7f2;
        }
    }

    .EmptyState {
        margin-top: 0rem;
    }
}
