@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ComparativeUpdater {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8rem;

    &__nextUpdate {
        font-size: 13px !important;

        display: flex;
        align-items: center;
        gap: 0.5rem;

        svg {
            vertical-align: bottom;
        }
    }
}
