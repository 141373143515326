@import 'styles/abstracts/variables';

.WasteByValorizationChart {
    &__showAllBars {
        position: absolute;
        z-index: 2;
        top: 31px;
        left: 80px;
    }

    g > rect {
        cursor: pointer;
    }

    rect[stroke*='reuseTons'] {
        fill: url(#lines);
        stroke: #2b9248;
    }

    rect[stroke*='finalMaterialTons'] {
        fill: #46b958;
        stroke: #2b9248;
    }

    rect[stroke*='materialTons'] {
        fill: #8ad796;
        stroke: #46bf59;
    }

    rect[stroke*='energyTons'] {
        fill: #f8fc8b;
        stroke: #dadf55;
    }

    rect[stroke*='storageTons'] {
        fill: #fbef95;
        stroke: #eace6a;
    }

    rect[stroke*='dumpTons'] {
        stroke: #da8989;
        fill: url(#dots);
    }

    rect[stroke*='nonValorizedTons'] {
        fill: #fff;
        stroke: #da8989;
    }

    rect[stroke*='greyRectangle'] {
        fill: #e0e0e0;
        stroke: #e0e0e0;
        opacity: 0;
        transition: ease-out;
    }

    &__legend {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.5rem 3rem;
        margin: 2rem 0rem;
        font-size: 1.2rem;

        &-item {
            display: flex;
            align-items: center;
            column-gap: 0.75rem;

            svg,
            rect {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__scrollButton {
        position: absolute;
        width: 20px;
        height: 75px;
        border: 1px solid #d3d3d3;
        top: 100px;
        cursor: pointer;
        z-index: 1;
        border-radius: 3px;
        background-color: white;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            color: $text-grey;
        }

        &-left {
            left: 70px;
        }

        &-right {
            right: 10px;
        }

        &:hover {
            background-color: #f9f9f9;
        }
    }
}
