@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

$grey-border-separator: rgb(230, 230, 230);

.TraceabilityFieldset {
    $this: &;
    height: 100%;
    padding: 0rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;

    form {
        flex: auto; // vertically fill all
        justify-content: space-between;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 2rem;
        align-items: flex-end;
        width: 100%;
    }

    .Select__wasteFlow {
        max-width: 950px;

        [class*='-single'] {
            .SelectAutocompleteOption__agent {
                min-width: unset;
                width: fit-content;
                max-width: 200px;
            }
        }
    }

    .input__container {
        margin: 0rem;
    }

    &__input {
        width: 100% !important;

        @include res-tablet {
            &-small {
                width: 78px;
                max-width: 78px;
            }

            &-fullWidth {
                width: 100%;
                max-width: 950px;
            }

            &-numDi {
                min-width: 260px;
            }

            &-vehiclePlate {
                max-width: 158px;
            }

            &-date {
                width: 180px;
                .input__fecha {
                    min-width: unset;
                }
            }
        }
    }

    &__field {
        position: relative;
        & > p {
            position: absolute;
            font-size: 1rem;
            margin-top: 0.5rem;
            line-height: 1.5;
            min-width: 200px;
        }
    }

    &__break {
        display: none;
        @include res-bigdesk {
            display: block;
            flex: 1 1 100%;
            height: 0;
        }

        &--always {
            display: block;
            flex: 1 1 100%;
            height: 0;
        }
    }

    &__showObservations {
        margin-right: auto;
        margin-left: -2px;

        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.6rem;

        cursor: pointer;
        font-size: 1.4rem;
        color: $blue;
        font-weight: 300;

        svg {
            stroke: $blue;
            width: 20px;
            min-width: 20px;
            height: 20px;
            animation: 0.5s ease fade-in;
        }
    }

    &__observations {
        width: 100% !important;
        animation: 0.5s ease fade-in;
    }

    &__direction {
        display: none !important;

        @include res-desk {
            display: flex !important;
        }
    }

    &__incompleteCheck {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        margin-top: 0.75rem;

        color: $blue;
        font-size: 1.4rem;
        font-weight: 300;

        input[type='checkbox'] {
            width: 16px;
            min-width: 16px;
            height: 16px;
        }
    }

    &--is-skeleton {
        @include skeleton-animation();
        margin-bottom: 3rem;

        #{$this}__preview {
            background-color: $grey;
            border: 20px solid white;
        }

        h3 {
            visibility: hidden;
        }

        #{$this}__input,
        .input__container {
            label {
                visibility: hidden;
            }
        }

        input[type='date'] {
            color: transparent;
        }

        #{$this}__buttons button,
        p {
            @include skeleton($letters: 10, $transparent: true);

            & * {
                display: none;
            }
        }
    }

    &--has-preview {
        #{$this}__preview {
            border: none;
        }
    }

    &--vertical {
        @include res-bigdesk {
            form {
                min-height: 300px;
            }
        }
    }

    &--horizontal {
        @include res-bigdesk {
            flex-direction: row;
            min-height: 55vh;
        }

        #{$this}__preview {
            width: 100%;

            @include res-bigdesk {
                min-height: 350px;
                height: auto;
            }
        }
    }

    &--vertical {
        #{$this}__preview {
            min-height: 50vh;
        }

        #{$this}__content {
            width: 100% !important;
            max-width: 800px !important;
        }
    }

    &--pending {
        opacity: 0.5;
        pointer-events: none;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
