@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TableFilters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.8rem;

    &__Filter {
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 0.4rem;

        font-size: 13px;
        font-weight: 500;
        line-height: 1;

        color: $purple;
        background: $light-purple;

        border-radius: 10px;
        padding: 0.8rem 1.6rem;

        &:hover {
            background: lighten($light-purple, 1%);
        }

        svg {
            max-width: 18px;
            width: 18px;
            height: 18px;
        }
    }
}
