@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionInfoPrintable {
    .TagBadge {
        display: none;
    }

    .PromotionInfo__viewMore {
        display: none;
    }

    .PromotionInfo__progressBar {
        max-width: unset;
    }

    .WalleMailAddress {
        display: none;
    }

    h1 {
        font-size: 20px;
    }
}
