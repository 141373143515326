@import 'styles/abstracts/mixins';
@import 'styles/abstracts/variables';

$grey-border-separator: rgb(230, 230, 230);

.diExtraction {
    $this: &;
    padding: 0rem;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 3rem;
    }

    &__form {
        padding: 1.6rem;
    }

    &__preview {
        overflow: hidden;
        position: relative;
        border-right: 1px solid $grey-border-separator;
        min-height: 400px;
        background: $grey;
        border: 12px solid white;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        iframe {
            width: 100%;
            height: 100%;
            border: none;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__previewExtension {
        font-size: clamp(10rem, 10vw, 16rem);
        color: transparentize($color: $tooltip-grey, $amount: 0.5);
        font-weight: 600;
    }

    &__iframeButtons {
        position: absolute;
        right: 0.8rem;
        bottom: 0.8rem;
        display: flex;
        gap: 1.5rem;
    }

    &__buttons {
        width: 100%;
        margin-top: 3rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;
    }

    &__direction {
        display: none !important;

        @include res-desk {
            display: flex !important;
        }
    }

    &__toast {
        font-size: 1.4rem;
        a {
            color: $purple;
            text-decoration: underline;
        }
    }

    &__warnings {
        display: flex;
        align-items: center;
        gap: 0.6rem;

        margin-left: 1.2rem;
        padding-right: 0.8rem;
    }

    &--is-skeleton {
        @include skeleton-animation();
        margin-bottom: 3rem;

        #{$this}__preview {
            background-color: $grey;
            border: 20px solid white;
        }

        h3 {
            visibility: hidden;
        }

        #{$this}__input,
        .input__container {
            label {
                visibility: hidden;
            }
        }

        input[type='date'] {
            color: transparent;
        }

        #{$this}__buttons button,
        p {
            @include skeleton($letters: 10, $transparent: true);

            & * {
                display: none;
            }
        }
    }

    &--has-preview {
        #{$this}__preview {
            border: none;
        }
    }

    &--vertical {
        @include res-bigdesk {
            form {
                min-height: 300px;
            }
        }
    }

    &--horizontal {
        @include res-bigdesk {
            flex-direction: row;
            min-height: 55vh;
        }

        #{$this}__preview {
            width: 100%;

            @include res-bigdesk {
                min-height: 350px;
                height: auto;
            }
        }
    }

    &--vertical {
        #{$this}__preview {
            min-height: 50vh;
        }

        #{$this}__content {
            width: 100% !important;
            max-width: 800px !important;
        }
    }

    &--pending {
        opacity: 0.5;
        pointer-events: none;
    }
}
