@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: sticky;
    z-index: 4;
    margin-top: -0.8rem;
    padding: 0.8rem 0;
    background: $background-grey;

    top: -1.6rem;
    @include res-desk {
        top: -3.2rem;
    }
}
