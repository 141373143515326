@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ImpactPrintable {

    .card {
        padding: 0;
        border: none;
        box-shadow: none;
        border-radius: 0;
    }

    .Impact__graph {
        height: auto;
    }

    .reports__page {
        page-break-inside: avoid !important;
        max-width: 17.9cm !important;
        padding: 0rem;
        position: relative;
        &:not(:first-child) {
            padding-top: 2.5cm;
        }

        & > h2 {
            margin-bottom: 1.5rem !important;
            font-weight: 800 !important;
        }
    }

    .reports__impact {
        .card {
            page-break-after: always;
        }
        .impact__card {
            display: flex;
            gap: 2rem;
        }

        .impact__kpisContainer {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            margin: 0rem;
        }

        .impactKpi {
            min-width: 125px;
            p {
                font-weight: 700;
                line-height: 1.1;
                font-size: 1.2rem !important;
            }
            span {
                display: block; // for line-height to work
                line-height: 1.2 !important;
                font-size: 1.2rem !important;
            }
        }

        .impactKpi__containerIcon {
            width: 25px;
        }

        .Impact__containerGraphs {
            width: 100%;
            flex-direction: column;
            padding: 0rem;

            & > div {
                width: 100%;
                max-width: 100%;
            }
        }

        .Impact__graph {
            page-break-inside: avoid !important;
            margin: 2rem 0;
        }

        .card {
            h2 {
                font-size: 1.6rem !important;
                font-weight: 500 !important;
                margin-bottom: 0rem;
            }
        }

        .Impact__graph {
            h2 {
                display: none;
            }
        }

        .button {
            display: none;
        }

        .impact__equivalenciesContainer,
        .card__alert {
            padding: 0rem;
            margin-top: -2rem;
            position: relative;
            display: block;
        }

        .card + .card {
            margin-top: 4rem;
        }

        h3,
        .impact__equivalencia {
            font-size: 1.2rem !important;
            line-height: 1.3;
        }

        .impact__small {
            margin: 0rem;
            font-size: 1.2rem;
            line-height: 1.3;
        }

        .card__alert__content {
            display: block;
            min-height: min-content;
            page-break-inside: avoid !important;
        }

        .popover__table {
            margin-bottom: 2rem;
            font-size: 1.4rem;
            line-height: 1.4;
            border: 1px solid #a1a1a1 !important;
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;

            th,
            td {
                border: 2px solid #a1a1a1 !important;
            }
        }

        .Impact__containerLegends {
            width: 100%;
            justify-content: flex-end;
        }
    }
}
