@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TextFilter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__label {
        font-size: 1.3rem;
        font-weight: 300;
        padding-top: 0.85rem;
        white-space: nowrap;
    }

    &__fields {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        background: $background-grey;
        // border: 1px solid darken($background-grey, 5%);
        border-radius: 10px;
        padding: 0.4rem;
    }

    &__clearInput {
        cursor: pointer;
        margin-left: -3.75rem;
        padding: 0.5rem;

        display: flex;
        align-items: center;

        color: #b9a0fa;
        background: $light-purple;

        svg {
            transform: scale(1.2);
            min-width: 12px;
            width: 12px;
            height: 12px;
            stroke-width: 3;
        }
    }

    .input__container {
        margin: 0rem;
        width: 100%;
    }

    .input__wrapper {
        background: none;
        margin: 0;
    }

    .input__input {
        border-radius: 0;
        min-width: 12.5rem;
        background: none;
        padding: 0.8rem 0.6rem;
        height: auto;
        font-weight: 500;
        color: $purple;
        font-size: 1.3rem;
        border-bottom: 1px solid transparentize($grey-dark, 0.9);
        padding: 6.75px 8px;
        background: $light-purple;
        border-radius: 10px;
        border: none;
        min-height: 3.2rem;

        &[value=''] {
            background: transparent;
            letter-spacing: -0.5px;
            color: $tooltip-grey;
        }
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
    }

    &--is-chip {
        flex-direction: row;
        gap: 0.8rem;
    }
}
