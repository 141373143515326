@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.icon {
    &__tooltip {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}

.Tooltip {
    display: block;
    width: fit-content;

    &__content {
        background-color: darken($blue, 5%);
        padding-bottom: 1rem;
        transform-origin: var(--radix-tooltip-content-transform-origin);
        animation: Tooltip__scaleIn 0.25s ease-in-out forwards;
    }
    
    &__arrow {
        fill: darken($blue, 5%);
    }
}

[data-radix-popper-content-wrapper] {
    z-index: 999 !important;
}


@keyframes Tooltip__scaleIn {
    from {
        opacity: 0;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(-2px);
    }
  }
