.results__table {
    display: block;
    overflow-x: auto;
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
    border-radius: 6px;
    font-size: 1.4rem;
    text-align: left;
    table-layout: fixed;
    width: 100%;

    @include res-desk {
        overflow-x: none;
        display: table;
        margin: 0;
    }

    &--trazabilidad {
        margin-top: 0rem;
    }
    th:first-child {
        border-radius: 4px 0 0 4px;
    }

    th:last-child {
        border-radius: 0 4px 4px 0;
    }

    &__head {
        background-color: $light-grey;
        padding: 0.5rem;

        border-radius: 6px;
    }
    &__titles {
        padding: 1rem;
        font-weight: 600;
    }

    &__filas {
        border: 2px solid $light-grey;

        &__result {
            height: 3rem;
            padding: 0.5rem 1rem;
        }

        &__actions {
            display: flex;
            align-items: center;
        }
        &__valorizacion {
            display: flex;
            align-items: center;
        }
    }

    &__image {
        width: 4rem;
        cursor: pointer;
        margin: 0.5rem;
    }
}
