@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIGoal {
    // Input overrides
    label {
        /** Visually hide the label */
        overflow: hidden;
        position: absolute;
        clip: rect(0 0 0 0);
    }

    .input__container {
        margin-top: 0;
        position: relative;
    }

    .input__input {
        text-align: inherit;
    }

    &__selector {
        height: 44px;
        [class*='-singleValue'] {
            .LerSelectorOption__description { display: none; }
        }
    }

    &__form-group {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    &__severity-option {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        color: $text-grey-dark;

        span {
            line-height: 0;
        }

        svg {
            width: 16px;
        }

        &--1 {
            color: darken($red-status, 10%);
        }

        &--2 {
            color: darken($orange-status, 20%);
        }
    }

    &__value {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        flex: 1 1;
        max-width: 100%;

        & > * {
            flex: 1 1;
        }
    }

    &__input-unit {
        position: relative;
        top: 1px;
        padding-right: 1rem;
        margin-left: -0.5rem;
        color: lighten($color: $text-grey-light, $amount: 30%);
        font-size: 1.4rem
    }

    &__input-prefix {
        position: absolute;
        left: 1rem;
        color: lighten($color: $text-grey-light, $amount: 30%);
        font-size: 1.4rem
    }

    &--updating {
        @include skeleton-animation();
        pointer-events: none;
    }

    &--mutation-error {
        @include error-animation-shake();
    }
}
