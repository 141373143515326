@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.activities {
    &__containerButtons {
        display: flex;

        button {
            border: 1.5px solid $purple-medium;
            color: $purple-medium;

            &:hover {
                border: 1.5px solid $purple;
                color: $purple;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;

        @include res-desk {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 3rem;

            & > div {
                width: 45%;
                max-width: 45%;
            }
        }
    }
}
