@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SidePanel {
    $this: &;

    &__header {
        border-bottom: 1px solid $grey;
        margin: -1.6rem -1.6rem 0;
        padding: 1.2rem 1.6rem;
        font-size: 1.4rem;

        position: sticky;
        top: -1.6rem;
        background: white;
        z-index: 1;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        flex: 1 1 auto;
    }

    &__closeBtn {
        background: transparent;
        border: 0;
        line-height: 1;
        appearance: none;
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        transition: all 0.15s linear;
        cursor: pointer;
        color: $text-grey;
        &:hover {
            background: $background-grey;
        }
    }

    &__overlay {
        position: fixed;
        inset: 0px;
        z-index: 100;

        transition: all 0.25s linear;

        &.ReactModal__Overlay--after-open {
            background: transparentize($color: $background-grey, $amount: 0.35);
        }

        &.ReactModal__Overlay--before-close {
            background: transparent;
        }
    }

    &__sheet {
        display: flex;
        flex-direction: column;

        inset: 40px;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 4px;
        padding: 20px;
        position: absolute;
        background: rgb(255, 255, 255);
        overflow: auto;
        outline: none;

        height: 100%;
        width: 100%;
        top: 0;
        left: unset;
        right: 0;
        border: none;
        border-radius: 0;
        max-width: 425px;
        padding: 1.6rem;
        box-shadow: transparentize($color: $blue, $amount: 0.9) -5px 0px 20px;

        transition: all 0.25s cubic-bezier(0.65, 0, 0.35, 1);

        transform: translate(0, 100%);
        @include res-desk() {
            transform: translate(100%, 0);
        }

        &--full {
            max-width: 100%;
        }

        &.ReactModal__Content--after-open {
            transform: translate(0, 0);
        }

        &.ReactModal__Content--before-close {
            transform: translate(0, 100%);
            @include res-desk() {
                transform: translate(100%, 0);
            }
        }
    }
}
