@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WallePanel {
    height: 100%;

    &__container {
        height: 100%;

        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }

    &__actions {
        flex: 0 0 auto;
        display: flex;
        gap: 1.6rem;
        justify-content: space-between;
    }

    &__documents {
        flex: 1 1 auto;
        display: grid;
        gap: 2.4rem;

        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;

        @include res-bigdesk {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
    }

    &__assign {
        flex: 1 1;
        max-width: 500px;
        display: flex;
        gap: 1.6rem;
    }

    &__promotion {
        width: 100%;

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    &__empty {
        height: 100%;
        width: 100%;
        border-radius: 1rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        color: $tooltip-grey;
        text-align: center;
        padding: 2.4rem;
    }

    &__empty-title {
        font-size: 2.4rem;
        margin-top: 1rem;
        font-weight: 600;
        line-height: 1.2;
    }

    &__empty-msg {
        margin-top: 0.8rem;
        font-size: 1.4rem;
        text-decoration: italic;
    }

    &__preview {
        border: none;
        border-radius: 1rem;
        background: $grey;
        height: 100%;
        width: 100%;

        @include res-bigdesk {
            grid-row: span 1;
        }

        &--1 {
            grid-column: span 4;

            @include res-bigdesk {
                grid-column: span 2;
                grid-row: span 2;
            }
        }

        &--2 {
            grid-column: span 2;
        }

        &--more {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            font-size: 2.4rem;
            color: $tooltip-grey;
            text-align: center;
            font-weight: 600;
        }
    }

    &__resume {
        width: 100%;
        text-align: center;
        color: $tooltip-grey;
        font-size: 1.4rem;
        font-weight: 425;
    }
}
