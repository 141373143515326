@import 'styles/abstracts/variables';

.PromotionSearchBar {
    width: 100%;
    position: relative;
    color: $light-blue-cocircular;

    &__search {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5rem;

        width: 20px;
        min-width: 20px;
        height: 20px;
    }

    &__spinner {
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);
        right: 1.5rem;
    }

    input {
        width: 100%;
        height: 40px;
        padding-left: 4.5rem;

        border: 1.5px solid darken($grey, 5%);
        border-radius: 10px;

        font-size: 1.4rem;
        font-family: 'Be Vietnam Pro', sans-serif;
        color: $light-blue-cocircular;

        &:focus {
            outline: none; // accesibility issues tho
        }

        // Do not group these rules. Instead, make a separate rule for every selector
        // (one invalid selector in a group makes the whole group invalid)
        &::placeholder {
            font-size: 1.4rem;
            font-family: 'Be Vietnam Pro', sans-serif;
            color: $light-blue-cocircular;
            opacity: 1;
        }
        &::-webkit-input-placeholder {
            font-size: 1.4rem;
            font-family: 'Be Vietnam Pro', sans-serif;
            color: $light-blue-cocircular;
            opacity: 1;
        }
        &:-moz-placeholder {
            font-size: 1.4rem;
            font-family: 'Be Vietnam Pro', sans-serif;
            color: $light-blue-cocircular;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            font-size: 1.4rem;
            font-family: 'Be Vietnam Pro', sans-serif;
            color: $light-blue-cocircular;
            opacity: 1;
        }
    }
}
