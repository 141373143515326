@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionWasteRegisterPage {
    width: 100%;

    &__header {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 1.6rem;
    }

    &__modeSwitch {
        margin: 1.4rem;

        @include res-tablet {
            margin: 0rem;

            position: absolute;
            right: 29px;
            top: 61px;
        }

        & > :not(.SegmentedControl__item--active) {
            background-color: white;
        }
    }
}
