@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.menu {
    position: relative;
    .button__icon {
        border: none;
    }

    &__dropdown {
        position: absolute;
        z-index: 1;
        right: 0;
        margin: 0;
        border: 0;
        border-radius: 1rem;
        background-color: $white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

        li {
            cursor: pointer;
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            padding: 0.5rem 1rem;
            margin: 0.5rem;
            border-radius: 0.5rem;

            &:hover {
                background-color: $light-purple;
                color: $purple;
            }
        }
    }
}
