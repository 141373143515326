@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SettingsExporter {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &__uploadMsg {
        line-height: 1;
    }
}
