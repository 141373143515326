@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.LerSelectorOption {
    color: $text-grey-dark;
    margin-bottom: -0.4rem;

    svg {
        width: 18px;
        min-width: 18px;
        height: 18px;
        stroke-width: 1.5;
    }

    &__ler {
        color: $text-grey-dark;
        font-weight: 600;
        margin-bottom: 0.4rem;
    }

    &__lerType {
        font-weight: 400;
    }

    &__description {
        display: flex;
        font-size: 1.3rem;
        flex-direction: row;
        line-height: 1.5;
        font-weight: 300;
        color: $text-grey-light;

        span {
            align-self: center;

            overflow-x: hidden;
            overflow-y: visible;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    mark {
        background-color: rgba(184, 150, 251, 0.5);
    }
}
