@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.usuario {
    &__container {
        margin-top: 1rem;
    }

    &__button {
        height: 35px;
        font-size: 1.4rem;
    }

    &__row {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        margin-top: 1.6rem;
        flex-wrap: wrap;

        @include res-tablet {
            flex-wrap: nowrap;
        }

        & > div {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0rem;
        }

        button {
            margin: 0rem;
        }
    }

    &__data {
        display: flex;
        flex-direction: column;
        margin-left: 1.25rem;
    }

    &__name {
        line-height: 1.5;
    }

    &__desc {
        font-size: 1.3rem;
        color: $dark-grey;
        line-height: 1.5;
    }

    &__enterprise-group-name {
        color: $text-grey-light;
        font-weight: bold;
        font-size: 1.2rem;
        text-transform: uppercase;
        margin: 0 0 -0.8rem;
    }

    &__enterprise-group-list {
        margin-bottom: 2rem;
    }
}
