$blue-cocircular: hsl(212, 30%, 22%); //#273749
$blue-cocircular-light: hsl(207, 7%, 51%);
$blue-cocircular-light-2: #354650;
$blue-cocircular-light-3: rgba($blue-cocircular, 0.8);
$blue-cocircular-light-4: rgba($blue-cocircular, 0.4);

$blue-cocircular-dark: hsl(212, 30%, 18%);

$green-cocircular: #6ecec1;
$blue-light: hsl(212, 100%, 90%);
$blue-light-hover: hsl(212, 100%, 70%);
$green-light: #e4f1e7;
$white: #fff;
$grey: hsl(220, 13%, 91%);
$light-grey: hsl(0, 7%, 97%); /* #f8f7f7 */

$red: hsl(0, 100%, 72%); //#ff7070
$red-dark: hsl(0, 100%, 67%); //#ff5757

$enEstudio: #c6beb0;
$enCurso: #8f989e;
$terminadas: #70987d;

/* Cosas no-cocirculeras */
$grey: hsl(218, 25%, 96%);
$dark-grey: hsl(220, 2%, 52%);
$background-grey: hsl(240, 7%, 97%);
$light-grey: hsl(0, 7%, 97%);
$text-grey: hsl(223, 3%, 50%);
$tooltip-grey: #b4b8c1;

$light-blue: hsl(212, 100%, 95%); /*#e5f1ff*/

$green: hsl(140, 20%, 32%);
$light-green: hsl(137, 51%, 88%); /*#d1f0da*/
$medium-green: hsl(137, 51%, 58%);
$medium-green2: hsl(137, 51%, 38%);
$medium-green3: hsl(137, 53%, 30%);
$dark-green: #41624c;

//Status
$green-status: #00bb76;
$orange-status: #ffbf43;
$red-status: #e84420;

$default-border-radius: 1.25rem;
$border-radius-buttons: 0.8rem;
$roboto: 'Roboto', 'sans-serif';

//new colors
$light-purple: hsl(240, 100%, 95%); //#E8E8FF
$light-purple-hover: hsl(240, 100%, 93%); //	#dbdbff
$purple: hsl(260, 90%, 54%); //#671FF3
$purple-medium: hsl(260, 90%, 61%); //#7F42F5
$purple-dark: hsl(260, 82%, 43%);
$grey-dark: rgb(60, 60, 60);
$blue: hsl(211, 30%, 29%); // #344960
$blue-cocircular: hsl(212, 30%, 22%); //#273749
$orange: #e3b100;
$orange-light: #ffefb8;

// Impact metrics colors
$green-potential: #d1f0da;
$purple-real: #7f42f5;
$purple-standard: #b3b3ff;

// Layout
$default-container-width: 1304px; // 1440 - paddings and menu
$large-container-width: 1600px; // 1440 - paddings and menu

// Originally from traceabilities table
$text-grey-light: lighten($blue, 5%);
$text-grey-dark: darken($blue, 15%);
$border-grey: lighten($blue, 60%);

// new colors
$light-blue-cocircular: #4e6178;
$default-box-shadow: 0px 5px 15px transparentize($blue, 0.94);
$hover-default-box-shadow: hsl(223, 3%, 90%) 0px 5px 35px;

// new
$blue-text: #3d5671;
