@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesSummaryChart {
    $this: &;
    margin-bottom: 1.6rem;

    &__chart {
        height: 30rem;
    }

    &__scrollButton {
        position: absolute;
        width: 20px;
        height: 75px;
        border: 1px solid #d3d3d3;
        top: 100px;
        cursor: pointer;
        z-index: 1;
        border-radius: 3px;
        background-color: white;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            color: $text-grey;
        }

        &-left {
            left: 0px;
        }

        &-right {
            right: 10px;
        }

        &:hover {
            background-color: #f9f9f9;
        }
    }

    svg {
        text {
            animation: 1.5s ease fade-in; // avoid weird animation adding delay
        }

        > g > g:nth-child(3) text {
            // axis Y
            fill: #777777 !important;
        }

        > g > g:nth-child(4) text {
            fill-opacity: 0.45;
            font-weight: 600;
        }
    }

    &__legend {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;
        column-gap: 3rem;
        font-size: 1.3rem;
        padding: 1rem 0rem 0.75rem 5rem;
    }

    &__legendElement {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 0.75rem;

        & > div {
            width: 14px;
            height: 14px;
            border-radius: 100%;
        }

        & > p {
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__noResultsMessage {
        position: absolute;
        z-index: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60%;
        color: $tooltip-grey;
        font-weight: 600;
        font-size: 2.4rem;
    }

    &--noResults {
        svg {
            text {
                display: none;
            }

            & > g {
                rect {
                    fill: #f5f5f5;
                }
            }
        }
    }

    &--skeleton {
        .TraceabilitiesSummaryChart__loadingContent {
            @include skeleton-animation();
        }

        svg {
            text {
                display: none;
            }

            & > g {
                rect {
                    fill: darken($grey, $amount: 3%);
                }
            }
        }
        .TraceabilitiesSummaryChart__legendElement {
            @include skeleton($letters: 10);

            div,
            p {
                display: none;
            }
        }
    }
}
