@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SupportResources {
    font-size: 1.4rem;
    
    &__topicHeader {
        font-style: italic;
        padding: 0.8rem 1.2rem;

        display: flex;
        align-items: stretch;

        margin-bottom: 0.8rem;
        border-radius: $border-radius-buttons;
        background: #f8f8ff;
    }

    &__faqLink {
        color: $purple;
        display: flex;
        align-items: center;
        padding-left: 0.8rem;
        line-height: 1;
        gap: 0.4rem;
        margin-top: 1.6rem;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    &__resources {
        padding-bottom: 3.2rem;
    }
}
