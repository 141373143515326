@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

.impactKpi {
    display: flex;
    border-radius: $default-border-radius;
    align-items: center;
    gap: 1rem;
    width: max-content;
    padding: 0.5rem 1.5rem 0.8rem;
    width: auto;
    line-height: 1.3;
}

.impactKpi--purple-real {
    background-color: rgba($color: $purple-real, $alpha: 0.15);

    .impactKpi__containerIcon svg {
        color: $purple;
        fill: $purple;
        stroke: $purple;
    }
}
.impactKpi--green-potential {
    background-color: rgba($color: $green-potential, $alpha: 0.45);
    .impactKpi__containerIcon svg {
        fill: $medium-green;
        stroke: $medium-green;
    }
}
.impactKpi--purple-standard {
    background-color: rgba($color: $purple-standard, $alpha: 0.15);
    .impactKpi__containerIcon svg {
        fill: $purple-medium;
        stroke: $purple-medium;
    }
}

.impactKpi__label {
    font-size: 1.4rem;

    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.impactKpi__containerIcon {
    width: 28px;
    display: flex;
    align-items: center;

    svg {
        width: fit-content;
    }
}
.impactKpi__value {
    font-weight: 800;
    font-size: 2rem;
    line-height: 1.1;

    margin-top: 0.4rem;
}

.impactKpi__moreInfoButton {
    cursor: pointer;
}
