@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

@keyframes AutomaticReadingResult__loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.AutomaticReadingResult {
    &__text {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        gap: 4px;

        &--link {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: $purple;
            &:hover {
                text-decoration: underline;
            }
        }

        &--error {
            color: $red-status;
        }

        &--loading {
            & > img {
                color: $purple;
                animation: AutomaticReadingResult__loading 1.5s linear infinite;
            }
        }
    }

    &__extractionDataRow {
        font-size: 1.3rem;

        display: flex;
        gap: 1.6rem;

        flex-wrap: wrap;
        @include res-tablet {
            flex-wrap: nowrap;
        }
    }

    &__extractionData {
        display: flex;
        align-items: center;
        gap: 6px;
        min-width: 11rem;

        &--error {
            // color: $red-status;
            opacity: 0.3;
        }

        & > span {
            max-width: 25rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-width: 0px;
        }
    }
}
