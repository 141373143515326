@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';
.message {
    margin-top: 1rem;
    font-size: 1.4rem;
    color: $blue-cocircular;
    font-weight: 300;

    &__error {
        font-size: 1.5rem;
        color: $red;
        margin-bottom: 1rem;
        font-weight: 500;
    }
}
