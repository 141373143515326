@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteManagementPlanner {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &__table {
        margin: 0 -2.4rem -0.8rem;
        border-spacing: 0px;

        td {
            padding: 1.2rem 0.8rem;
            border-top: 1px solid $border-grey;
            &:first-child {
                padding-left: 2.4rem;
            }
            &:last-child {
                padding-right: 2.4rem;
            }
        }
    

        thead {
            background: $light-grey;

            th {
                padding: 1.6rem 1.8rem;
                line-height: 100%;
                text-align: left;
                font-size: 1.2rem;
                font-weight: 600;
                // text-transform: uppercase;
                color: $text-grey-dark;

                &:first-child {
                    padding-left: 3.2rem;
                }
                &:last-child {
                    padding-right: 3.2rem;
                }
            }
        }

        tbody {
            tr:first-child {
                td {
                    border-bottom: 2px solid $border-grey;
                }
            }
        }
    }

    &__actions {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        padding-bottom: 6px;
    }

    &__additionalOptions {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-top: 2.4rem;

        label {
            font-size: 1.4rem;
            color: $text-grey-light;
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }

}
