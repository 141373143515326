@import 'styles/abstracts/variables';

.EllipsisText {
    // Ellipsis effect without setting fixed widths: https://stackoverflow.com/a/44452722
    position: relative;
    display: block;
    max-height: 24.5px; // 23.8 is the height of the line (added some margin just in case)
    overflow: hidden;
    color: transparent;
    word-break: break-all;

    &::after {
        content: attr(title);
        color: $text-grey-light;
        position: absolute;
        top: 0;
        left: 0;
        right: 0; // this one is also neccesary!!
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
