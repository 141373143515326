@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.EmptyState {
    max-width: 44rem;
    margin: 6.4rem auto 0;
    text-align: center;
    color: $tooltip-grey;
    line-height: 1.4;

    & > h2 {
        margin-bottom: 1.2rem;
        margin-top: 1.6rem;
    }

    & > p {
        font-style: italic;
    }
}
