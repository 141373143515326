@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ColorSelectorOption {
    padding: 0.25rem 0rem;
    color: $text-grey-dark;

    display: flex;
    align-items: center;
    gap: 0.8rem;

    &__circle {
        position: relative;
        width: 1.4rem;
        height: 1.4rem;
        border: 1px solid var(--_color);
        border-radius: 50%;
        overflow: hidden;
        background: white;

        &::before {
            content: '';
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: var(--_color);
            opacity: 0.35;
        }
    }

    &__name {
        color: $text-grey-dark;
        font-weight: 400;
    }
}
