@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.resultadoCarga {
    position: absolute;
    z-index: 3;
    background-color: white;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    font-size: 1.4rem;
    width: max-content;
    height: 6rem;
    border-radius: $default-border-radius;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;

    &__containerIcon {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 1rem;
    }
}
