@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilityEditPage__layoutWrapper {
    width: 100%;
    height: 100%;

    .layout__main {
        padding-bottom: 3rem !important;
    }
}

.TraceabilityEditPage {
    $this: &;
    max-width: $default-container-width;
    width: 100%;
    margin: 0 auto;

    &--is-loading {
        #{$this}__form {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__header {
        margin-top: 1.6rem;
        margin-bottom: 2.4rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .card {
        padding: 1.6rem;
        @include res-desk {
            padding: 2.4rem;
        }
    }
}
