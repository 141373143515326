@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.MandatoryFlowsOverview {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    $this: &;
    font-size: 1.2rem;
    color: $text-grey-dark;

    margin-left: -2.4rem;
    margin-right: -2.4rem;
    margin-bottom: -2.4rem;

    --border-color: #{lighten($blue, 55%)};
    --current-month-border-radius: 6px;
    
    &__container {
        position: relative;
        overflow-y: auto;

        width: 100%;
        padding-left: 2.4rem;
        padding-bottom: 2.4rem;
    }

    table {
        width: 100%;
    }

    th, td {
        text-align: left;
        padding: 4px;
        background: white;
    }

    th, thead td {
        font-size: 1rem;
        font-weight: 400;
        padding-top: 0;
        padding-bottom: 2px;
        &::first-letter {
            text-transform: capitalize;
        }
    }

    thead td {
        padding-left: 4px;
    }

    tbody th {
        color: $text-grey-light;
    }

    th {
        position: sticky;
        z-index: 2;
        padding-left: 8px;
        white-space: nowrap;
        
        &:nth-of-type(1) {
            min-width: 5rem;
            max-width: 5rem;
            width: 5rem;
            right: 25rem;
            border-left: 1px solid var(--border-color);
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: calc(-1.6rem - 1px);
                width: 1.6rem;
                height: 100%;
                background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
                z-index: -1;
            }
        }
        &:nth-of-type(2) {
            min-width: 5rem;
            max-width: 5rem;
            width: 5rem;
            right: 20rem;
        }
        &:nth-of-type(3) {
            min-width: 5rem;
            max-width: 5rem;
            width: 5rem;
            right: 15rem;
        }
        &:nth-of-type(4) {
            min-width: 15rem;
            max-width: 15rem;
            width: 15rem;
            right: 0rem;
        }
    }

    tbody tr:first-child td {
        padding-top: 6px;
    }

    tbody tr:last-child td {
        padding-bottom: 6px;
    }

    // td:last-of-type {
    //     margin-right: 1.6rem;
    // }

    tr #{$this}__cell--marker {
        border-left: 1px solid var(--border-color);
        border-right: 1px solid var(--border-color);
    }

    tr:first-child #{$this}__cell--marker {
        border-top: 1px solid var(--border-color);
        border-top-left-radius: var(--current-month-border-radius);
        border-top-right-radius: var(--current-month-border-radius);
    }

    tr:last-child #{$this}__cell--marker {
        border-bottom: 1px solid var(--border-color);
        border-bottom-left-radius: var(--current-month-border-radius);
        border-bottom-right-radius: var(--current-month-border-radius);
    }
    

    &__entry {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 9px;
        font-weight: 700;
        background: lighten($blue, 66%);
        border-radius: 4px;
        text-align: center;
        height: 2.2rem;
        min-width: 4rem;
        padding: 2px 4px;
        cursor: pointer;

        &--filled {
            background: $blue;
            color: white;
        }

        &--critical {
            color: darken($red-status, 15%);
        }

        &--critical.MandatoryFlowsOverview__entry--filled {
            color: lighten($red-status, 25%);
        }

        &--warning {
            color: darken($orange-status, 15%);
        }

        &--warning.MandatoryFlowsOverview__entry--filled {
            color: lighten($orange-status, 25%);
        }

        &--success {
            color: darken($green-status, 15%);
        }

        &--success.#{this}__entry--filled {
            color: lighten($green-status, 0%);
        }
    }

    &__cell {
        position: relative;
        & > .Tooltip {
            width: 100%;
        }
    }

    &__hint {
        position: absolute;
        z-index: 1; 
        top: -50%;
        left: 0;
        transform: translate(0%, -25%);
        font-size: 1rem;
        background: white;
        width: 100%;
        text-align: center;
        color: $text-grey-light;
        width: calc(100% + 6px);
        left: -3px;
    }

    &__entry-value {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__entry-name {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        font-size: 1.1rem;
        font-weight: 500;

        &--danger {
            color: darken($red-status, 15%);
        }
    
        &--success {
            color: darken($green-status, 15%);
        }
    
        &--warning {
            color: darken($orange-status, 15%);
        }

        & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
            padding-right: 1.6rem;
        }
    }

    &__cell:not(#{$this}__cell--current) #{$this}__entry:not(:hover) #{$this}__entry-value {
        opacity: 0.35;
    }

    &__flag {
        position: absolute;
        z-index: 1;
        left: -2px;
        top: -2px;
        background: white;
        border-radius: 50%;
    }

    &__header {
        & > th:nth-of-type(1) {
            right: 0;
            width: 30rem;
            max-width: 30rem;
            min-width: 30rem;

            font-size: 1.1rem;
            font-weight: 500;
            color: $text-grey-dark;
            padding-top: 1.6rem;
        }
    }

    &__legend {
        margin-top: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.6rem;
        padding: 1.6rem 2.4rem 2.4rem;
        font-size: 1rem;
        font-weight: 400;
        color: $text-grey-light;

    }

    &__legend-item {
        position: relative;
        padding: 4px;
        color: $text-grey-dark;

        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    &__months {
        position: relative;
        overflow: visible;
        & > * {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            transform: translateX(calc(-50% + 2px));

            white-space: nowrap;
            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    &__showOther {
        padding: 0rem 2.4rem 1.6rem;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__showOtherButton {
        margin: 0 auto;
        color: $purple;
        text-align: center;
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 0.4rem;

        &.--show {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    @media (max-width: 1024px) {
        thead td {
            display: none;
        }
        tbody td {
            display: none;
        }

        th:nth-of-type(1) {
            border-left: none;
        }

        th:last-child {
            width: 30%;
        }
    }

    &--empty {
        margin-bottom: 2.4rem;
        font-size: 1.6rem;
    }
}
