@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingPreselector {
    &__info {
        font-size: 1.3rem;
        margin-top: 0.8rem;
        margin-bottom: 1.6rem;

        .titulo {
            margin-bottom: 0;
        }
    }
}
