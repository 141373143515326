@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.siteDocument {
    display: flex;
    flex-direction: row;
    padding: 1.3rem;
    align-items: flex-start;
    justify-content: space-between;

    &__info {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include res-desk {
            flex-wrap: nowrap;
        }
    }

    &__description {
        font-weight: 650;
        line-height: 1.4;
    }

    &__descriptionFile {
        word-wrap: anywhere;
    }

    .smallText {
        line-height: 1.8rem;
        font-size: 1.3rem;
    }

    &__noDocument {
        font-style: italic;
        font-size: 1.4rem;
        color: $dark-grey;
        font-weight: 400;
    }

    .icon__circle {
        padding: 1rem;
        margin-right: 1.25rem;
    }
}
