@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ValorizationDetail {
    &__tabs {
        margin-bottom: 2.4rem;
        border-bottom: 0;
    }

    &__tab {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        cursor: pointer;
        border-bottom: 1px solid lighten($grey-dark, 65%);
        
        .KPIStatusIcon {
            transform: scale(0.75);
        }
    }
}
