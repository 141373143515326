@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

.ppgcl__form--seeker {
    max-width: 1600px; // avoid container ocupying all width (rip to wide monitor users)
}

.seekerPpgcl {
    &__form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-end;
        gap: 1.5rem;

        margin-bottom: 2.5rem;
        padding-left: 1rem;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        max-width: 1800px;

        @include res-tablet {
            flex-wrap: nowrap;
        }
    }

    &__scrollResults {
        overflow-y: scroll;
        height: 60rem;
        border-bottom: 1px solid #eff3f7;
        max-width: fit-content;
    }

    &__documentsContainer {
        flex: 1 1 40%;
        min-width: 0rem; // This is mandatory for flex-grow to work correctly (idk why)
        border-left: 1px solid #eff3f7;
        margin: -2.4rem;
        margin-left: 0rem;
        padding: 2.4rem;
    }

    &__contractName {
        font-weight: 550;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-top: 5rem;
        margin-bottom: 0.5rem;
        padding-left: 1rem;

        &:first-of-type {
            margin-top: 0rem;
        }

        &-unlinked {
            color: #cf3a3a;
        }

        .Tooltip {
            height: 24px;
        }
    }

    &__message {
        font-size: 1.4rem;
        margin-top: 1.5rem;
    }

    &__editPpgcl {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ffff;
        border: none;
        border-radius: 1.25rem;
        overflow: unset;
        padding: 3rem;
        max-height: 85vh;
        width: 85vw;

        @include res-desk {
            height: auto;
            width: auto;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            transform: translate(-50%, -55%);
        }

        form {
            .input__container {
                margin: 1.5rem 0;
                input {
                    width: 100%;
                }
            }
        }

        p {
            font-size: 1.4rem;
        }
    }

    &__displayedDocument {
        min-height: 90vh;
        height: 90vh;
        width: 100%;
        max-width: 1400px;
        margin: 0rem 2rem;

        h2 {
            white-space: wrap;
        }

        iframe {
            margin-top: 1.5rem;
            width: 100%;
            height: 85%;
            border: none;
        }
    }
}
.editPpgcl__p {
    margin-top: 1rem;
}
.editPpgcl__container {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin: 0;
}
