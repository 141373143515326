@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterDIFallback {
    width: 100%;
    min-height: 100%;
    background: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;


    &__picture {
        width: 100%;
        max-width: 30rem;
        margin: 0 auto;
        display: block;
        margin-bottom: 3.2rem;
    }

    &__header {
        margin-bottom: 2.4rem;
        text-align: center;
        max-width: 42rem;

        &--align-left {
            text-align: left;
        }

        p, li {
            margin-bottom: 0.8rem;
        }
    }

    &__title {
        font-size: 1.8rem;
        color: darken($blue, 5%);
        margin-bottom: 0.4rem;
    }

    &__text {
        font-size: 1.4rem;
        color: lighten($blue, 15%);
        font-weight: 300;
    }

}
