@import '../../../../styles/abstracts/variables';
@import '../../../../styles/abstracts/mixins';

.promotionInformation {
    .input__container,
    .input__autocomplete {
        margin: 0rem;
        width: 100%;
        @include res-tablet {
            width: unset;
        }
    }

    .input__input {
        width: 100%;
        @include res-tablet {
            width: 15rem; // default
        }
    }

    &__row {
        margin-bottom: 3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 3rem;
    }

    &__input {
        @include res-tablet {
            &-small {
                width: 125px !important;
                max-width: 125px;
            }

            &-medium {
                min-width: 225px;
                width: 225px !important;
                max-width: 225px;
            }

            &-large {
                width: 400px !important;
                max-width: 400px;
            }

            &-extraLarge {
                width: 600px !important;
                max-width: 600px;
            }
        }
    }
}
