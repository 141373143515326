.label-environment {
    font-size: 0.8em;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    background-color: rgb(122, 23, 158);
    padding: 0.5em;
    border-radius: 0.75em;
    margin: 0.5em 0;
}

.label__dev {
    background-color: #4f00bd;
}
