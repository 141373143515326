@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPITile {
    $this: &;
    position: relative;

    & > * {
        position: relative;
    }

    &__label {
        color: $text-grey-light;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 100%;
        padding-top: 3px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 1px;
    }

    &__content {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__value {
        color: $text-grey-dark;
        font-size: 2.2rem;
        font-weight: 800;
        line-height: 100%;
    }

    &__total {
        font-weight: 300;
        color: lighten($text-grey-light, 25%);
    }

    &__slot {
        margin-top: 1.25rem;
    }

    &--clickable {
        cursor: pointer;
    }

    &--loading {
        #{$this}__label {
            @include skeleton-animation();
            @include skeleton($letters: 12);
        }
        #{$this}__value {
            @include skeleton-animation();
            @include skeleton($letters: 3);
        }
    }

    &--not-available {
        #{$this}__value {
            color: #e6e9ec;
        }
    }

    &--danger.KPITile--not-available {
        #{$this}__value {
            color: transparentize($red-status, 0.7);
        }
    }

    &--warning.KPITile--not-available {
        #{$this}__value {
            color: transparentize($orange-status, 0.7);
        }
    }

    &--has-error {
        #{$this}__label {
            @include skeleton-animation($error: true);
        }
        #{$this}__value {
            @include skeleton-animation($error: true);
        }
    }
}
