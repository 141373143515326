@import 'styles/abstracts/variables';

.ProgressBar {
    width: 100%;

    &__wrapper {
        background-color: $light-purple;
        padding: 0.4rem;
        border-radius: 99px;
    }

    &__progress {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.1rem;
        font-weight: 650;
        border-radius: 99px;

        color: white;
        background-color: $purple;
        min-width: 30px;
    }
}
