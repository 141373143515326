@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.navbar {
    $this: &;

    position: initial;
    z-index: 2;
    background-color: $white;
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-shadow: $default-box-shadow;
    padding: 1rem;
    border-bottom: 1.5px solid $grey;

    @mixin collapsed() {
        --navbar--collapsed-with: 76px;
        min-width: var(--navbar--collapsed-with);
        width: var(--navbar--collapsed-with);

        #{$this}__link {
            margin: 1rem 0rem;
            position: relative;

            a span {
                display: none;
            }
        }

        #{$this}__top {
            padding: 0rem 1.11rem;

            a {
                overflow: hidden;
            }
            img {
                width: 12rem;
            }
        }

        #{$this}__toggle-collapse {
            span {
                display: none;
            }
            svg {
                transform: rotate(180deg);
            }
        }

        #{$this}__section-header {
            border-bottom: 1px solid $grey;
            padding: 0.4rem 0 0;
            margin-bottom: 0.4rem;

            & > span {
                display: none;
            }
        }

        #{$this}__notification-badge {
            position: absolute;
            top: -4px;
            right: 1px;
        }

        .label__dev {
            display: none;
        }
    }

    @include res-bigdesk {
        padding: 3rem 1rem;
        position: initial !important;
        height: 100%;
        min-width: 300px;
        width: 300px;
        border-bottom: none;
    }

    &.active {
        position: fixed;
        height: 100%;
        border-bottom: none;

        ul {
            overflow-y: scroll;
        }
    }

    &__top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1;
        padding: 0rem 1.75rem;

        .label-environment {
            margin: 0rem;
        }

        img {
            width: 11.5rem; /* or any custom size */
            height: 100%;
            object-fit: contain;

            @include res-bigdesk {
                width: 20rem; /* or any custom size */
            }
        }
    }

    &__logo {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.8rem;

        @include res-bigdesk {
            display: block;
        }
    }

    &__items {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        overflow-x: hidden;
        overflow-y: auto;
        // hides scrollbar
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    &__item {
        margin-top: 2rem;

        &:not(.active) {
            display: none;

            @include res-bigdesk {
                display: block;
            }
        }
    }

    &__link {
        margin: 0.25rem 0rem;
        list-style: none;

        a {
            display: flex;
            align-items: center;

            span {
                margin-left: 1rem;
            }
        }

        a,
        > span {
            text-decoration: none;
            font-weight: 400;
            font-size: 1.6rem;
            padding: 0.7rem 1.65rem;
            align-items: center;
            transition: 0.15s all ease;
            border-radius: 0.75rem;
            cursor: pointer;

            &:hover,
            &:focus-visible {
                background-color: $light-purple;
            }
        }

        &-active {
            background-color: $light-purple;
            color: $purple;
        }
    }

    &__selectedPromotion {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 2.5rem 1.75rem;

        @include res-bigdesk {
            display: none;
        }
    }

    &__enterprise {
        color: $purple;
        font-weight: 600;
        margin-right: 1rem;
    }

    &__promotion {
        background-color: $light-purple;
        border: none;
        border-radius: 100px;
        padding: 0.5rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $purple;
        font-weight: 600;
        transition: 0.2s all ease-out;

        svg {
            margin-left: 1rem;
        }

        &:hover {
            background-color: $light-purple-hover;
        }
    }

    &__logout {
        @include res-bigdesk {
            display: none;
        }
    }

    &__bottom {
        border-top: 1px solid $grey;
        margin-top: 1.6rem;
        padding: 0.8rem 0;

        display: none;
        @include res-bigdesk {
            display: block;
        }
    }

    &__toggle-collapse {
        border: none;
        margin: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;

        height: 42px;
        width: 100%;

        display: flex;
        align-items: center;
        // justify-content: flex-end;
        gap: 4px;
        border-radius: 10px;
        padding: 0.4rem 1.65rem;
        font-size: 14px;

        cursor: pointer;
        &:hover {
            background: $grey;
        }
    }

    &__section-header {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 1.6rem 1.6rem 0.4rem;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__notification-badge {
        background: $purple;
        color: white;
        font-size: 0.9rem;
        line-height: 100%;
        padding: 4px 4px 4px;
        border-radius: 99px;
        min-width: 17px;
        text-align: center;
        margin-left: 0.4rem;
    }

    @media (min-width: 1200px) and (max-width: 1439px) {
        @include collapsed();

        #{$this}__bottom {
            display: none;
        }
    }

    &--collapsed {
        @include res-bigdesk {
            @include collapsed();
        }
    }
}

.nav-hamburguer {
    padding: 0rem 0.5rem;
    display: flex;
    align-items: center;
    // me molaria meter una animacion
    cursor: pointer;
    @include res-bigdesk {
        display: none;
    }

    &:hover,
    &:focus-visible {
        border-radius: 0.75rem;
        background-color: $light-purple;
    }
}

@keyframes spin {
    20% {
        transform: rotate(180deg);
    }
    40% {
        transform: rotate(180deg);
    }
    60% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
