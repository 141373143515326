@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionPreview {
    $this: &;
    color: $blue-cocircular;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;

    &__title {
        font-weight: 650;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        width: fit-content;

        p {
            // ellipsis effect without setting fixed widths: https://stackoverflow.com/a/44452722
            position: relative;
            display: block;
            max-height: 24.5px; // 23.8 is the height of the line (added some margin just in case)
            overflow: hidden;
            color: transparent;
            word-break: break-all;

            &::after {
                content: attr(title);
                color: $blue-cocircular;
                position: absolute;
                top: 0;
                left: 0;
                right: 0; // this one is also neccesary!!
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        svg {
            cursor: pointer;
            width: 22px;
            min-width: 22px;
            height: 22px;
            stroke-width: 1.75;
            stroke: lighten($light-blue-cocircular, 40%);
            visibility: hidden;
            fill: none;
            animation: fade 0.2s;
        }

        &:hover {
            svg {
                visibility: visible;
            }
        }

        &--pinned {
            svg {
                visibility: visible;
                stroke: $blue-cocircular;
                fill: $blue-cocircular;
                animation: none;
            }
        }
    }

    &__additionalData {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1.75rem;
        row-gap: 0.5rem;
    }

    &__footer {
        margin-top: auto;
        padding-top: 0.8rem;
    }

    &--is-skeleton {
        @include skeleton-animation();

        svg {
            display: none;
        }

        #{$this}__title {
            @include skeleton($letters: 25);

            p {
                display: none;
            }
        }

        #{$this}__additionalData {
            & > * {
                @include skeleton($letters: 9);
            }

            span {
                display: none;
            }
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
