@import 'styles/abstracts/variables.scss';
@import 'styles/abstracts/mixins.scss';

.PromotionInfoModal {
    $BAR_HEIGHT: 28.7px;

    color: $blue-cocircular;
    font-size: 1.4rem;

    width: 100%;
    max-width: 700px;

    .popover__title {
        align-items: flex-start;

        h2 {
            line-height: 1.5;
            white-space: unset;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 1.5rem;
        margin-bottom: 1rem;
    }

    &__extraData {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;

        @include res-tablet {
            flex-direction: row;
            margin-top: 0rem;

            p:not(:last-of-type) {
                &::after {
                    white-space: pre;
                    content: '  -  ';
                }
            }
        }
    }

    &__projectProgress {
        position: relative;
        width: 100%;
        margin: 1.5rem 0rem;
        min-height: $BAR_HEIGHT;

        @include res-tablet {
            margin: 10rem 0rem 8rem 0rem;
        }
    }

    &__backgroundBar {
        width: 100%;
        height: $BAR_HEIGHT;

        border-radius: 99px;
        border: 1px solid $purple;
    }

    &__date {
        display: flex;
        flex-direction: column;
        width: 200px;
        font-size: 1.2rem;

        position: unset;
        margin-top: 1rem;

        @include res-tablet {
            position: absolute;
            margin-top: 0rem;

            &--top {
                top: -60px;

                &::after {
                    content: '';
                    background-color: $blue-cocircular;
                    height: 20px;
                    width: 1px;
                }
            }
            &--bottom {
                top: $BAR_HEIGHT;

                &::before {
                    content: '';
                    background-color: $blue-cocircular;
                    height: 20px;
                    width: 1px;
                }
            }
            &--left {
                align-items: flex-start;
            }

            &--right {
                align-items: end;
                transform: translateX(-100%);
            }
        }

        span:first-of-type {
            font-weight: 600;
        }
    }
}
