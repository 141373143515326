@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.FilterBar {
    $this: &;
    position: relative;

    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.4rem;
    min-height: 4rem;

    &__buttonContainer {
        flex: 1 1 100%;
        margin-top: 0.8rem;
        display: flex;
        justify-content: flex-end;

        @include res-tablet {
            margin-top: 0;
            flex: auto;
        }
    }

    &__button {
        padding: 4px 8px;
        height: auto;
        appearance: none;
        transition: all 0.3 ease-in-out;
    }

    &__modalFilters {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        min-height: 100%;
    }

    &__modalFiltersFooter {
        margin-top: auto;
        width: 100%;

        display: flex;
        justify-content: space-between;
        gap: 1.6rem;
    }

    &__filter {
        // display: none
        max-width: 100%;

        &--hidden {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            pointer-events: none;
        }

        &--visible {
            transition: opacity 0.3s linear;
            pointer-events: unset;
            opacity: 1;
            visibility: visible;
            position: static;
            z-index: 2;
        }
    }

    &--is-empty {
        #{$this}__button {
            margin-left: auto;
        }
    }
}
