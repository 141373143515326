@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

@keyframes TraceabilitiesTabBadge__loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.TraceabilitiesTabBadge {
    $this: &;
    display: flex;
    gap: 1px;
    align-items: center;
    position: relative;

    &__badge {
        background: $tooltip-grey;
        color: white;
        position: relative;

        border-radius: 999px;
        text-align: center;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        font-size: 1rem;
        font-weight: 600;
        padding: 1px 6px 0;
        min-width: 1.8rem;
        margin-bottom: 2px;
        transition: all 0.2s linear
    }


    &--empty {
        #{$this}__badge {
            opacity: 0;
        }
    }

    &--warning {
        $color: darken($orange-status, 15%);
        
        #{$this}__badge {
            background: $color; 
        }
    }

    &--loading {
        #{$this}__badge {
            @include skeleton-animation($speed: 0.5s);
            background: $purple;
        }

        & > img {
            color: $purple;
            animation: TraceabilitiesTabBadge__loading 1.5s linear infinite;

            position: absolute;
            right: -20px;
            top: 1px;
        }
    }
}
