@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilityUpload {
    background-color: white;
    padding: 4rem;
    height: fit-content;
    border-radius: $default-border-radius;
    color: $blue-cocircular;

    max-width: 800px;
    width: 85vw;

    @include res-tablet {
        width: 70vw;
    }

    @include res-bigdesk {
        width: 52vw;
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__close {
        cursor: pointer;
        padding: 0.25rem;
        border-radius: 999px;

        &:hover {
            background-color: #fafcff;
        }

        svg {
            width: 28px;
            min-width: 28px;
            height: 28px;
            vertical-align: middle;
        }
    }

    &__title {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 1.5rem;

        h2 {
            font-size: 2.2rem;
        }
    }

    &__moreOptions {
        width: 100%;
        margin: 5rem 0rem 2rem 0rem;

        text-align: center;
        color: $blue-cocircular;
        font-weight: 450;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;

        & > * {
            border-color: lighten(#b4d3ff, 10%);
            font-weight: 450;
        }
    }
}
