@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesPage {
    margin-bottom: 14rem;
    &__header {
        max-width: $default-container-width;
        width: 100%;
        margin: 2rem auto;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2.4rem;

        h1 {
            margin: 0;
        }

        a[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        gap: 1.6rem;
    }

    &__tabs {
        width: 100%;
        position: relative;
    }

    &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        min-width: 16rem;
    }

    &__error {
        flex: 1 1 100%;
    }
}
