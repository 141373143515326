@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.PromotionInfoModalPrintable {
    .PromotionInfoModal__extraData {
        #ID, #NUM {
            display: none;
        }
    }
    .PromotionInfoModal__tags {
        display: none;
    }
    .WalleMailAddress {
        display: none;
    }
    .PromotionInfoModal__projectProgress {
        transform: scale(0.9);
    }
}
