@import '../../styles/abstracts/variables';
@import '../../styles/abstracts/mixins';

.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    margin: 0;
    overflow: hidden;
    background-color: white;

    @include res-bigdesk {
        flex-direction: row;
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: inherit;
        width: inherit;
        flex: 1; // fill remain parent space
        min-width: 0rem; // above rule doesn't work without this one
        overflow: hidden;
    }

    &__main {
        height: 100%;
        width: inherit;
        display: flex;
        flex-direction: column;
        height: inherit;
        overflow: auto;
        flex-grow: 1;
        background-color: $light-grey;
        padding: 1.5rem 1.5rem;
        padding-bottom: 8rem;
        
        @include res-desk {
            padding: 3rem;
            padding-bottom: 8rem;
        }

        &-white {
            background-color: #fff;
        }
    }

    &__costAlert {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;

        & > p {
            display: flex;
            flex-direction: column;
        }
    }
}
