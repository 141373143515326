@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.Onboarding {
    color: $blue-cocircular;

    &__header {
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .SegmentedControl {
            justify-content: center;
        }
    }

    &__topics {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
    }

    &__topicHeader {
        display: flex;
        align-items: stretch;

        margin-bottom: 0.8rem;
        border-radius: $border-radius-buttons;
        background: #f8f8ff;
    }

    &__topicText {
        font-size: 1.4rem;
        font-style: italic;
        font-weight: 400;
        padding: 1.2rem;
        line-height: 1.3;
    }

    &__topicResources {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-evenly;
        gap: 1rem 1.5rem;
        margin: 0rem;
    }

    &__topicResource {
        grid-column: span 3;

        &.--big {
            @include res-bigdesk {
                grid-column: span 1;
            }
        }
    }
}
