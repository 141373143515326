.newReuse {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 3rem;
    }

    &__textarea {
        & > div {
            width: 100%;
        }

        textarea {
            width: 100%;
            max-width: 56rem;
            height: 7rem;
        }
    }

    .input__container {
        margin: 0rem;
    }

    .input__label {
        margin-bottom: 0.5rem;
    }
}
