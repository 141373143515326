@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ModifyWasteHandling {
    
    &__modal {
        overflow: unset !important;
        display: flex;
        flex-direction: column;
        max-width: 480px;

        .popover__title {
            margin-bottom: 0.8rem;
        }
    }

    &__modal-footer {
        margin-top: 4.8rem;
        display: flex;
        justify-content: flex-end;
        gap: 1.6rem;
    }

    &__modal-message {
        color: $text-grey-light;
        font-size: 1.3rem;
        line-height: 1.4;
    }

    &__modal-select {
        margin: 3.2rem 0 2.4rem;
    }

    &__modal-alerts {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }
}
