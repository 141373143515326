@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingPendingState {
    margin: 0 auto 3.2rem auto;

    & > h2 {
        color: $text-grey-dark;
        display: flex;
        justify-content: center;
        gap: 1rem;
    }
    & > p {
        color: $text-grey-light;
    }
    & > svg {
        color: $purple;
    }

    &__aiBadge {
        font-size: 1rem !important;
    }
}
