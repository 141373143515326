@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.SelectFilter {
    $this: &;
    min-height: 4rem;

    &__select {
        max-width: 100%;

        display: flex;
        align-items: center;

        & > div:first-of-type {
            width: 100%;
        }
    }

    label {
        font-size: 1.3rem;
        padding-top: 0.85rem;
        white-space: nowrap;
    }

    &__clear {
        width: 1.6rem;
        height: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        color: $text-grey;
        cursor: pointer;
        margin-left: 0.8rem;
    }

    &__chevron {
        position: relative;
        top: 1px;
    }

    &--is-chip {
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
        @include res-tablet {
            flex-wrap: nowrap;
        }
    }

    &--disabled {
        opacity: 0.5;
    }
}
