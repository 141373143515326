@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.EgrAndPgr {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    // To prevent select from being cut off
    padding-bottom: 18rem;
}
