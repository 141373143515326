@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIHistoricalChart {
    &__chart {
        height: 300px;
    }

    &__legend {
        display: flex;
        gap: 3.2rem;
    }

    &__legend-goal {
        display: flex;
        align-items: center;
    }

    &__legend-goal-icon {
        height: 18px;
        & > g {
            transform: translate(50%, 50%);
        }
    }

    &__legend-goal-line {
        width: 12px;
        height: 2px;
        background-color: $blue;
        &.--short {
            width: 4px;
        }
    }

    &__legend-goal-text {
        font-size: 1.3rem;
        color: $text-grey-light;
        margin-left: 0.8rem;
        line-height: 100%;
    }

    &__tooltipGoal {
        margin-top: 0.8rem;

        display: flex;
        align-items: center;
        gap: 0.4rem;

        font-size: 1.4rem;
    }
}
