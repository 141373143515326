@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2.4rem;
    background-color: white;
    border: 1.5px solid $grey;
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;
    -webkit-box-shadow: $default-box-shadow;
    overflow: unset;

    &-hover {
        transition: transform 0.3s ease-out;

        &:hover {
            box-shadow: $hover-default-box-shadow;
            transform: translate(0, -5px);
        }
    }

    &-col {
        flex-direction: column;
        align-items: initial;
        flex-wrap: nowrap;
    }

    &-scroll {
        overflow-y: auto;
    }

    &__info {
        margin-top: 1rem;

        @include res-tablet {
            margin-top: 0rem;
            margin-left: 2rem;
        }
    }

    &__header {
        margin-bottom: 1.5rem;
    }

    &__header-title-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__button {
        width: max-content;
        background-color: $blue;
        color: $white;
        margin-top: 1px;
    }

    &__title {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        line-height: 2.4rem;
    }

    &__subtitle {
        margin-top: 0.4rem;
        font-size: 1.4rem;
        color: $text-grey-light;
        line-height: 1.5;
    }

    &-border {
        border: 2px solid $grey;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    &__alert {
        background-color: $light-purple;
        border: 1px solid hsl(240, 100%, 93%);
        box-shadow: none;
        padding: 1.5rem;
        font-size: 1.4rem;
        align-items: flex-start;
        flex-direction: row;
        gap: 1rem;

        & > svg {
            color: $purple;
            min-width: 24px;
            position: relative;
        }

        a {
            color: $purple;
            font-weight: 500;
            text-decoration: underline;
        }

        &__content {
            .titulo {
                margin-bottom: 0.75rem;
                color: $purple-medium;
                font-weight: 600;
                font-size: 1.4rem;
            }

            li {
                line-height: 1.4;
                margin-left: 1.25rem;
            }

            ol ul {
                margin-left: 1.5rem;
            }

            &>* + &>* {
                margin-top: 0.25rem;
            }
        }

        &-warning {
            background-color: lighten($orange-status, 33%);
            border: 1px solid $orange-status;

            & > svg {
                color: darken($orange-status, 20%);
            }

            .titulo {
                color: darken($orange-status, 20%);
            }
        }

        &-suggestion {
            background-color: hsl(120, 100%, 98%);
            border: 1px solid hsl(120, 42%, 95%);

            & > svg {
                color: hsl(46, 100%, 50%);
            }

            .titulo {
                color: $medium-green2;
            }
        }

        &-error {
            background-color: lighten($red-status, $amount: 45%);
            border: 1px solid transparent;

            & > svg {
                color: $red-status;
            }

            .titulo {
                color: $red-status;
            }

            code {
                font-size: 1rem;
            }
        }

        &--borderless {
            border-radius: 0;
            border: none;
            padding: 0.8rem 1.65rem;
        }

        &--center {
            justify-content: center;
            align-items: center;
        }
    }
}
