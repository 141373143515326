@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.ActivityDetail {
    max-width: $default-container-width;
    width: 100%;
    margin: 0 auto;

    &__contentWrapper {
        color: $blue;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
    }

    &__header {
        margin-top: 1.6rem;
        margin-bottom: 2.4rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }

    &__simpleData {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        row-gap: 2rem;
        column-gap: 5rem;

        font-size: 1.4rem;

        dt {
            font-weight: 300;
            color: lighten($blue, 15%);
        }

        dd {
            font-weight: 500;
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__type {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.25rem;
        background-color: $light-purple;
        border-radius: 99%;

        svg {
            width: 26px;
            min-width: 26px;
            height: 26px;
            stroke-width: 2;
            stroke: $purple-medium;
        }
    }

    &__richText {
        font-size: 1.4rem;
        ul,
        ol {
            margin-left: 2rem;
        }
    }

    &__cardTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 2rem;
        gap: 1rem;
    }

    &__files {
        display: flex;
        flex-wrap: wrap;
        row-gap: 1rem;
        column-gap: 2rem;
    }

    &__attachedImage {
        cursor: pointer;
        max-width: 18rem;
        height: fit-content;
        border-radius: 0.75rem;
    }

    .card__alert {
        margin-bottom: 1.5rem;
        padding: 1rem 1.5rem;
        a {
            color: $purple;
            text-decoration: underline;
        }
    }

    &__projectName {
        display: none;
    }

    @media print {
        max-width: 100%;
        h1 {
            display: inline-block !important; // remove this when other legacy global css is deleted
        }

        button,
        a {
            display: none;
        }

        &__filesSection {
            page-break-inside: avoid;
        }

        .card__alert-suggestion {
            page-break-inside: avoid;
            margin-bottom: 1rem;
        }

        &__contentWrapper {
            & > .card-col {
                border: none;
                box-shadow: none;
                -webkit-box-shadow: none;
                padding: 0rem;
                background-color: transparent;
            }
        }

        &__visibleToClient {
            display: none;
        }

        &__projectName {
            display: inline-block;
            dd {
                max-width: 400px;
            }
        }

        // CSS for Chrome bc it prints a desktop version, so we force mobile version (Firefox worked as expected without this)
        .ActivitySiteCheckingSection {
            img {
                display: inline-block;
            }

            &__row {
                width: 100%;
                display: flex;
                flex-direction: column;
                page-break-inside: avoid;

                & > td,
                & > th {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    page-break-inside: avoid;
                }
            }

            &__lerType {
                font-weight: 600;
                margin-top: 2rem;
            }

            &__desktopHeaders {
                display: none;
            }

            &__mobileHeaders {
                display: block;
                min-width: 20rem;
            }
        }
        // End of the code necessary for Chrome
    }
}
