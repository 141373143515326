.PromotionBilleables {
    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 1.6rem;

        h3 {
            margin-left: 1rem;
        }
    }

    &__section {
        margin-bottom: 4.2rem;
    }

    &__sectionTitle {
        font-size: 1.4rem;
        margin-bottom: 0.8rem;
    }
}
