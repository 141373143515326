@import '../../../styles/abstracts/mixins';

.Impact {
    margin-top: 1.5rem;
    & > * + * {
        margin-top: 1.5rem;
    }

    &__metrics {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 3rem;
        margin-left: 1.5rem;

        @include res-tablet {
            gap: 4rem;
            flex-direction: row;
        }
    }

    &__containerGraphs {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-bottom: 2.4rem;

        @include res-desk {
            flex-direction: row;
        }

        & > div {
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include res-desk {
                width: 50%;
                max-width: 50%;
            }
        }
    }
    &__containerLegends {
        display: flex;
        gap: 1.5rem;
        font-size: 1.2rem;

        p {
            display: flex;
            align-items: center;
        }
    }
    &__graph {
        display: flex;
        flex-direction: column;
        height: 350px;
    }

    &__graphTitle {
        display: flex;
        gap: 0.4rem;
        align-items: center;
        flex-wrap: wrap;
    }

    &__graphTitleSuffix {
        font-size: 1.2rem;
    }

    &__lineGraph {
        height: 300px;
        min-width: 100%;
        max-width: 100%;
    }
}

.impact__legend {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 50%;

    &--real {
        background-color: #7f42f5;
    }
    &--potential {
        background-color: #d1f0da;
    }
    &--standard {
        background-color: #b3b3ff;
    }
    &--mix {
        background-color: #70987d;
    }
}
.impact__card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.impact__kpisContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
    margin: 2rem 0 3rem;
}

.impact_container {
    margin-bottom: 1.5rem;
}

.impact__equivalenciesContainer {
    margin-top: 1.5rem;
    .card__alert-suggestion h3 {
        font-size: 1.6rem;
    }
    span {
        font-weight: 800;
    }
}

.impact__equivalencia {
    font-size: 1.6rem;
    gap: 0.5rem;
}

.impact__small {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}
