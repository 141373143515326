@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.file {
    &__data {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    & > img {
        max-width: 15rem;
        margin-bottom: 0.5rem;
    }

    &__name {
        border-radius: 0.7rem;
        font-size: 1.4rem;
        padding: 0.6rem 1rem;
        background-color: $light-purple;
        cursor: pointer;
        max-width: 50rem;

        &:hover {
            background-color: $light-purple-hover;
        }
    }

    .button__icon {
        background-color: $light-purple;
        border: none;
        margin-left: 1rem;
        padding: 0.6rem 0.8rem;

        &:hover {
            background-color: $light-purple-hover;
        }
    }
}
