@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.associateTraceabilities {
    .card {
        margin-bottom: 1.5rem;
    }
    &__title {
        display: flex;
        align-items: baseline;
        margin-bottom: 1.5rem;

        h3 {
            margin: 0 1rem;
        }
    }

    .button__submit {
        margin-top: 2rem;
    }

    &__ler {
        margin-top: 0.5rem;
        font-weight: 700;
    }

    .resultTraceability__containerLeft {
        width: 100%;
    }

    .smallMetricCard {
        margin-right: 1rem;
    }
    .buscador__form {
        margin-bottom: 1.5rem;
        .input__autocomplete {
            margin-right: 1.5rem;
        }
    }

    &__selected {
        margin: 1rem 0rem;
        font-weight: 300;
        font-size: 1.6rem;
        span {
            font-weight: 600;
        }
    }

    &__containerNonAssociated {
        max-height: 500px;
        overflow-y: auto;
    }
}
