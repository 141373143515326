@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.3rem;
    padding: 1.5rem 0rem;
    font-weight: 600;

    @include res-desk {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__info {
        display: flex;
        align-items: center;

        span {
            font-weight: 400;
            font-size: 1.2rem;
        }

        .user__authority {
            margin-right: 0.75rem;
        }
    }

    .profile__iconUser {
        width: 3rem;
        height: 3rem;
        font-size: 1.2rem;
        margin-right: 1rem;
        margin-bottom: 0;
    }

    .profile__userName {
        display: flex;
        align-items: center;

        .Tooltip {
            padding: 0rem 0.5rem;
            display: flex;
            align-items: center;
        }

        svg {
            min-width: 14px;
            width: 14px;
            height: 14px;
        }
    }

    &__unlinkBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        color: $red;
        border-radius: 10px;
        width: 3rem;
        padding: 0;

        svg {
            margin: 0;
            padding: 0;
        }
    }

    &__containerButtons {
        display: flex;
        margin-top: 2rem;
        :not(:last-child) {
            margin-right: 1rem;
        }

        @include res-desk {
            margin-top: 0;
        }
    }
}

.user__stakeHolderContainer {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    p span {
        font-weight: 600;
    }
}
