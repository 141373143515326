@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.DynamicFormControl {
    flex: 1 0 auto;
    min-width: 28rem;
    width: 100%;

    
    @include res-desk() {
        width: auto;
        max-width: 48rem;
    }
}
