@import 'styles/abstracts/variables';

$drag-and-drop-bg: #f6faff;
$drag-and-drop-border-color: #b4d3ff;

.AutomaticReadingUpload {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &__dragAndDrop {
        // container
        & > div {
            background-color: $drag-and-drop-bg;
            border: 2px dashed $drag-and-drop-border-color;
        }

        & > svg {
            min-width: 40px;
            width: 40px;
            height: 40px;
            stroke-width: 1.5;
            color: $text-grey-light;
        }

        .icon-tabler-upload {
            color: $text-grey-light;
        }

        .dragndrop__file {
            margin: 0.8rem;
        }

        .dragndrop__container {
            min-height: 14rem;
        }
    }

    &__spinner {
        position: absolute;
        background: transparentize($drag-and-drop-bg, $amount: 0.5);
        border-radius: 4px;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        color: $text-grey-light;
        font-size: 1.8rem;
        font-weight: 450;
        margin: 0.5rem 0rem;
    }

    &__subtitle {
        color: $text-grey-light;
        font-size: 1.4rem;
    }

    &__progress {
        margin-top: 1.6rem;
        // transform: scale(0.5);
        width: 80px;
    }

    &__error {
        margin-bottom: 1.6rem;
    }

    .CircularProgress__percent {
        color: #3d5671;
    }

    .CircularProgress circle {
        fill: #f6faff;
    }
}
