@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.metric {
    &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__data {
        margin-left: 1.5rem;

        &-vertical {
            margin-top: -0.5rem;
            margin-top: 1rem;
            text-align: center;
            margin-left: 1.2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-small {
            .metric__subtitle {
                font-size: 1.2rem;
            }
            .titulo__number {
                font-size: 1.8rem;
            }
        }

        &-highlighted {
            background-color: $green-cocircular;
            padding: 1rem;
            border-radius: 1rem;
        }
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 650;
        line-height: 1.5;
    }

    &__subtitle {
        color: $dark-grey;
        font-size: 1.4rem;

        &-vertical {
            line-height: 1.4;
            margin: -0.25rem auto 0;
            max-width: 8rem;
        }
    }
}
