@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.docDownloadRequest {
    padding: 2rem;

    .smallMetricCard {
        min-width: fit-content;
        width: 13rem;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;

        div {
            padding: 0rem;
            flex-direction: row;
        }
        p {
            font-weight: 600;
            color: $purple-medium;
        }
        svg {
            stroke: $purple-medium;
            min-width: 26px;
        }

        &-center {
            justify-content: center;

            & > div {
                display: none;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 2rem;

        @include res-tablet {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include res-tablet {
            flex-direction: row;
        }

        & > div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            gap: 1rem;
            @include res-tablet {
                gap: 0rem;
            }
        }
    }

    &__data {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > * + * {
            display: flex;
            align-items: center;

            &::before {
                content: '·';
                display: flex;
                margin: 0rem 0.75rem;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 2rem;
    }
}
