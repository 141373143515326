@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.BilleableBox {
    $this: &;

    border: 2px solid $grey;
    border-radius: 1.25rem;
    padding: 1.6rem;
    position: relative;

    max-width: 64rem;

    &:not(:last-child) {
        margin-bottom: 1.2rem;
    }

    &__title {
        font-size: 1.8rem;
        font-weight: bolder;
    }

    &__text {
        font-size: 1.4rem;
        line-height: 1.5;
        margin-top: 0.4rem;
    }

    &__details {
        padding-top: 2.4rem;
        padding-bottom: 0.8rem;
    }

    &__actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 1.6rem;
        justify-content: flex-end;
    }

    &--purchased {
        border-color: $purple;
    }

    &--with-actions {
        &:not(.BilleableBox--with-details) {
            #{$this}__title,
            #{$this}__text {
                padding-right: 12rem;
            }
        }
        #{$this}__details {
            padding-bottom: 4.8rem;
        }
    }

    &--is-skeleton {
        @include skeleton-animation();

        #{$this}__title {
            @include skeleton($letters: 4);
        }

        #{$this}__text {
            @include skeleton($letters: 18);
        }

        #{$this}__actions {
            display: none;
        }
    }
}
