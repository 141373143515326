@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

$checkbox-color: $purple;

.WalleEmail {
    margin-bottom: 4rem;

    &__mainCheckboxWrapper {
        display: block;
        width: 100%;
        margin-bottom: 0.5rem;
        padding: 0rem 0rem 0rem 4.6rem;

        .WalleEmail__fakeCheckbox {
            margin-right: -1rem;
        }
    }

    &__mainInfo {
        position: relative; // TO AVOID WHITE SECTION BUG (caused by position: absolute checkboxes)
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        font-weight: 500;
    }

    &__secondaryInfo {
        font-size: 1.3rem;
        font-weight: 400;
        color: darken($tooltip-grey, 30%);
    }

    &__userAndSubject {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 1rem;
        overflow: hidden;

        & > span:nth-child(2) {
            color: darken($tooltip-grey, 30%);
            font-weight: 375;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &__metaData {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: nowrap;

        svg {
            min-width: 18px;
            width: 18px;
            height: 18px;
        }
    }

    &__documents {
        padding: 0rem;
        font-size: 1.4rem;

        ul {
            list-style: none;
        }

        li {
            border-bottom: 1px solid #f2f4f7;

            &:nth-last-child(1) {
                border-bottom: none;
            }
        }

        label {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            width: 100%;
            padding: 1rem 2rem;

            &:hover {
                background-color: #fbfbfb;
            }

            & > div {
                position: relative; // TO AVOID WHITE SECTION BUG (caused by position: absolute checkboxes)
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-left: 2.5rem; // align when there is no checkbox
                max-width: 50%;
            }

            & span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &__discardedDoc {
        color: $tooltip-grey;
        text-decoration: line-through;
    }

    &__promotionName {
        max-width: 40%;
        color: $purple;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        flex-wrap: nowrap;

        svg {
            min-width: 14px;
            width: 14px;
            height: 14px;
        }

        & span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    /* COOL CHECKBOXES CSS */
    input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__fakeCheckbox {
        cursor: pointer;
        position: relative;
        min-width: 16px;
        width: 16px;
        height: 16px;
        background: transparent;
        border-radius: 2px;
        box-shadow: 0 0 0 1px rgba(0, 20, 20, 0.5);
        transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    }

    label > div > .WalleEmail__fakeCheckbox {
        margin-left: -2.5rem; // reset margin to 0 when there is checkbox
    }

    input[type='checkbox']:focus + .WalleEmail__fakeCheckbox {
        box-shadow: 0 0 0 1px $purple-real;
    }

    /* CHECKBOX WHEN ITS CHECKED (TICK SYMBOL) */
    input[type='checkbox']:checked + .WalleEmail__fakeCheckbox {
        background: $checkbox-color;
        box-shadow: 0 0 0 1px $checkbox-color;
        animation: reveal 200ms cubic-bezier(0.4, 0, 0.5, 1);
    }

    input[type='checkbox']:checked + .WalleEmail__fakeCheckbox:before {
        content: '';
        margin: auto;
        position: absolute;
        top: -1.5px;
        left: 0;
        bottom: 0;
        right: 0;

        // check tick
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;

        transform: rotate(45deg);
        animation: checkboxCheck 125ms 50ms cubic-bezier(0.1, 0, 0.1, 1) forwards;
    }

    /* CHECKBOX WHEN ITS PARTIALLY CHECKED (SQUARE SYMBOL) */
    input[type='checkbox'] + .WalleEmail__fakeCheckbox-partial {
        background: transparent;
        box-shadow: 0 0 0 1px $checkbox-color;
    }

    input[type='checkbox'] + .WalleEmail__fakeCheckbox-partial:before {
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        // square
        width: 0px;
        height: 0px;
        background-color: $checkbox-color;

        animation: partialCheckbox 125ms 50ms cubic-bezier(0.1, 0, 0.1, 1) forwards;
    }

    @keyframes reveal {
        0% {
            transform: scale(1);
        }
        33% {
            transform: scale(0.5);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes checkboxCheck {
        0% {
            width: 0;
            height: 0;
            border-color: white;
            transform: rotate(45deg);
        }
        33% {
            width: 0.175em;
            height: 0.35em;
            transform: rotate(45deg);
        }
        100% {
            width: 0.35em;
            height: 0.7em;
            border-color: white;
            transform: rotate(45deg);
        }
    }

    @keyframes partialCheckbox {
        0% {
            width: 0;
            height: 0;
        }
        33% {
            width: 5px;
            height: 5px;
        }
        100% {
            width: 10px;
            height: 10px;
        }
    }
}
