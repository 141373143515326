@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.dragndrop {
    &__container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border: 2px dashed rgb(212, 212, 212);
        border-radius: 2px;
        background-color: $light-grey;
        outline: none;
        color: $blue-cocircular;
        font-size: 1.4rem;
        transition: 0.15s ease-in-out;
        border-radius: 4px;
        cursor: pointer;
        justify-content: center;
    }

    &__placeholder {
        margin: 4rem 0rem;
        color: $text-grey;

        @include res-desk {
            margin: 0rem;
        }
    }

    &__filesContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__file {
        max-width: 100%;
        margin: 0.8rem;

        .file__data {
            align-items: stretch;
        }

        .file__name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: none;
            flex: 1;
            border-radius: $default-border-radius 0 0 $default-border-radius;
            font-size: 1.3rem;
        }

        .button__icon {
            margin-left: 0;
            border-radius: 0 $default-border-radius $default-border-radius 0;
        }
    }

    &__rejectedAlert {
        margin: -2rem -2rem 2rem;
        width: calc(100% + 4rem);
        padding: 1.6rem;
    }
}

.activeStyle {
    border-color: #aeaecc;
}

.acceptStyle {
    filter: brightness(96%);
}

.rejectStyle {
    border-color: #ff1744;
}

.widthFull {
    width: auto;
}
