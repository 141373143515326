@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.sort {
    background-color: transparent;
    margin-bottom: 2rem;

    &__menu {
        display: flex;
    }
    &__container {
        display: flex;
        flex-direction: row;
        padding-top: 0.5rem;
    }

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include res-desk {
            flex-direction: row;
            align-items: baseline;
        }
    }
    .button__submit {
        background-color: transparent;
        border: 1.5px solid $blue-cocircular;
        color: $blue-cocircular;
        padding: 0.5rem 1rem;
        align-self: flex-end;
        justify-self: flex-end;
        margin-left: 1rem;
    }

    &__openButton {
        background-color: transparent;
        padding: 0;
        margin-right: 1.5rem;
    }

    &__button {
        background-color: #efefef;
        padding: 0.3rem 1rem;
        border-radius: 3rem;
        font-size: 1.2rem;
        margin-left: 1rem;
        display: flex;
        align-items: center;

        svg {
            cursor: pointer;
            margin-left: 1rem;
        }
    }

    &__inputContainer {
        display: flex;
        align-items: center;
        margin: 0.1rem 0;
        margin-left: 1rem;
        margin-right: 1.5rem;
    }
    &__label {
        font-size: 1.3rem;
        margin-left: 0.5rem;
    }
    .button.button__blue {
        margin-top: 1rem;
        @include res-desk {
            margin-top: 0;
        }
    }
}
