@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.AutomaticReadingStatus {
    padding: 4px 10px 3px;
    border-radius: 999px;
    width: auto;
    font-weight: 700;
    background: $grey;
    font-size: 1rem;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    gap: 2px;

    svg {
        display: none;
        @include res-tablet {
            display: block;
        }
    }

    &--PENDING {
        @include skeleton-animation($speed: 0.5s);
        color: $purple;
        background: transparentize($purple, 0.85);
    }

    &--VALIDATION_ERROR {
        $validation-error: #dc6a29;
        background: transparentize($validation-error, 0.85);
        color: $validation-error;
    }

    &--PENDING_REVIEW {
        $pending-review: #c89d02;
        background: transparentize($pending-review, 0.85);
        color: $pending-review;
    }

    &--APPROVED {
        background: transparentize($green-status, 0.85);
        color: $green-status;
    }

    &--REJECTED {
        opacity: 0.33;
    }

    &--DOCUMENT_UPLOAD_ERROR,
    &--NOT_VALID {
        background: transparentize($red-status, 0.85);
        color: $red-status;
    }
}
