@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.WasteRegisterTable {
    $green: hsl(140, 100%, 35%);
    $yellow: hsl(46, 100%, 42%);
    $red: hsl(12, 100%, 35%);

    font-family: 'Be Vietnam Pro', 'sans-serif';

    margin-bottom: -14rem;

    .ag-theme-custom,
    .ag-theme-quartz {
        --ag-wrapper-border-radius: 0;
        font-family: 'Be Vietnam Pro', 'sans-serif';
    }

    .ag-root-wrapper {
        border-left: none;
        border-right: none;
        border-bottom: none;
    }

    .ag-theme-quartz {
        --ag-active-color: #bfa2ff;
        --ag-foreground-color: #3d5671;
        --ag-border-color: color-mix(in srgb, transparent, var(--ag-foreground-color) 10%);
        --ag-header-background-color: #f8f7fa;
        --ag-row-hover-color: #f8f7fa;
        // --ag-odd-row-background-color: rgb(0, 0, 0, 0.015);
    }

    .ag-header-cell {
        height: 44px !important;
    }

    .ag-cell {
        --ag-line-height: 1.5;

        width: 100%;
        display: flex;
        align-items: center;
    }

    .ag-cell > * {
        button {
            background: transparent;
            color: $purple;
            text-decoration: underline;
            border: none;

            svg {
                width: 18px;
                min-width: 18px;
                height: 18px;
            }

            &:hover {
                background: $light-purple;
            }
        }
    }

    &__header {
        background-color: white;
        font-size: 1.4rem;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        padding: 1.2rem 16px;
    }

    &__actions {
        margin-left: auto; // align buttons to right

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.2rem;

        li {
            cursor: pointer;
        }

        .button__purple {
            // smaller button
            font-size: 1.3rem;
            padding: 0.5rem 1.75rem;
        }
    }

    &__evaluationHeader,
    &__viewMoreObservations {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__boolean {
        svg {
            width: 18px;
            min-width: 18px;
            height: 18px;
        }
    }

    &__kpi {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        padding: 0.6rem 0.8rem;
        border-radius: 5px;
        transition: all 0.15s linear;

        svg {
            width: 18px;
            min-width: 18px;
            height: 18px;
        }

        &.--Summary {
            font-weight: 500;
            justify-content: start;
        }
    }

    &__kpi-msg {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        margin-bottom: 0.4rem;

        .KPIStatusIcon--danger {
            filter: brightness(2.5) saturate(0.75);
        }
        .KPIStatusIcon--warning {
            filter: brightness(1.5) saturate(0.5);
        }
        .KPIStatusIcon--success {
            filter: brightness(1.5) saturate(0.5);
        }
    }

    &__finalScore {
        font-weight: 500;
    }

    &__shouldPersist {
        display: flex;
        align-items: center;
        gap: 0.4rem;

        .Tooltip {
            display: flex;
        }
    }

    &__users {
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__users-popover {
        min-width: 480px;
    }

    &__users-user {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    &__users-user-info {
        margin: 0.8rem 0;
        p {
            font-size: 1.4rem;
            line-height: 1.2;
            color: $text-grey-dark;
        }

        small {
            color: $text-grey-light;
            font-size: 1.3rem;
        }
    }

    &--clickable:hover {
        transition: all 0.15s linear;
        cursor: pointer;
        box-shadow: $default-box-shadow;
        background: white;
    }

    &--danger {
        color: darken($red-status, 5%);
    }

    &--warning {
        color: darken($orange-status, 15%);
    }

    &--success {
        color: darken($green-status, 15%);
    }
}
