@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.LerAlert {
    &__tooltip {
        color: $orange;
        cursor: pointer;
        max-height: 2.2rem;
    }

    &__tooltip-msg {
        max-width: 40rem;
    }
}
