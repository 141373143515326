@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';


.TagManagerItem {
    $this: &;

    border-bottom: 1px solid $border-grey;

    &__header {
        padding: 1.6rem 2.4rem;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        color: $text-grey-dark;
    }

    &__info {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }

    &__actions {
        min-width: 7.2rem;
        display: flex;
        justify-content: flex-end;
        gap: 0.8rem;
    }

    &__projectCounter {
        font-size: 1.3rem;
        font-weight: 300;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        margin-left: auto;
        opacity: 0.5;

        @include res-tablet {
            min-width: 14rem;
        }

        span {
            margin-bottom: 1px;
        }
    }

    &__detail {
        padding: 0 2.4rem 2.4rem;
    }

    &__projects {
        .input__autocomplete {
            width: 100%;
        }
    }

    &--create {
        border-bottom: none;
    }

    &--hasProjects {
        #{$this}__projectCounter {
            opacity: 1;
        }
    }

    .--label-hidden {
        margin-top: 0;
        label {
            /** Visually hide the label */
            overflow: hidden;
            position: absolute;
            clip: rect(0 0 0 0);
        }
    }
}
