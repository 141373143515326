@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilityDetailPage {
    max-width: $default-container-width;
    width: 100%;
    margin: 0 auto;

    &__header {
        margin-top: 1.6rem;
        margin-bottom: 2.4rem;

        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }
}
