@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

// @keyframes AuthLayout__picture {
//     from {
//         transform: translateX(0%);
//         opacity: 1;
//     }
//     to {
//         transform: translateX(100%);
//     }
// }

@keyframes AuthLayout__input-animation {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}

.AuthLayout {
    $this: &;

    height: 100%;
    width: 100%;
    display: flex;
    background-color: white;

    &__picture {
        flex: 1;
        min-width: 200px;
        max-width: 30vw;
        position: relative;
        
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        
        @media (max-width: 600px) {
            display: none;
        }
        
        // &::before {
        //     content: '';
        //     display: block;
        //     height: 100%;
        //     width: 100%;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     background: white;
        //     animation: AuthLayout__picture 1s cubic-bezier(0.87, 0, 0.13, 1) forwards;
        // }
    }

    &__panel {
        flex: 1;
        display: flex;
        height: 100%;
        overflow-y: auto;

        & > * {
            width: 100%;
            max-width: 34rem;
            padding: 2.4rem 1.6rem;
            margin: auto;
        }
    }

    &__header {
        margin-bottom: 2.4rem;
        text-align: center;

        &--align-left {
            text-align: left;
        }

        p, li {
            margin-bottom: 0.8rem;
        }
    }

    &__title {
        font-size: 1.8rem;
        color: darken($blue, 5%);
        margin-bottom: 0.4rem;
    }
    
    &__text {
        font-size: 1.4rem;
        color: lighten($blue, 15%);
        font-weight: 300;
    }

    li#{$this}__text {
        margin-left: 1.6rem;
    }

    &__logo {
        width: 100%;
        max-width: 24rem;
        margin: 0 auto;
        display: block;
    }

    &__intro-msg {
        font-size: 16px;
        line-height: 1.6;
        font-weight: 300;
        color: $blue;
        text-align: center;
        font-size: 1.8rem;
    }

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .8rem;
    }

    &__password {
        position: relative;
    }

    &__password-eye-btn {
        position: absolute;
        right: .8rem;
        bottom: .9rem;
        height: 2.4rem;
        width: 2.8rem;
        padding: 0;
        border: 0;
        background: none;
        appearance: none;
        cursor: pointer;
        color: transparentize($blue, $amount: 0.2);
        border-radius: 50%;
        transition: all 0.3s linear;

        &:hover {
            color: transparentize($blue, $amount: 0.5);
        }
    }

    &__input {
        width: 100% !important;
        transition: box-shadow 0.15s linear;
        border: 1px solid lighten($blue, 60%) !important;

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-background-clip: text;
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px $light-purple;
        }

        &::placeholder {
            color: transparentize($blue, $amount: 0.75);
        }
    }

    &__error-msg {
        font-size: 1.4rem;
        color: $red-dark;
        text-align: center;
        opacity: 0;
        transition: all 0.15s linear;
    }

    &__error-alert {
        margin-top: 2.4rem;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        hr {
            width: 8rem;
            height: 0px;
            margin: 3rem;
            border: 1px solid $grey;
        }
    }

    &__button {
        width: 100%;
        margin-top: 1.6rem;

        height: 52px !important;
        font-size: 1.8rem;
        font-weight: 400;
        text-align: center;
        
        .button__label {
            line-height: 1;
            width: 100%;
        }
    }

    &__link {
        font-size: 1.4rem;
        color: lighten($purple, 15%);
        text-align: center;
        transition: all .3s linear;
        margin-bottom: 0.8rem;
        &:hover {
            color: darken($purple, 15%);
        }
    }

    &--has-error {
        #{$this}__input {
            animation: AuthLayout__input-animation 0.25s linear;
            box-shadow: inset 0px 0px 0px 2px $red;
            color: $red;
        }

        #{$this}__error-msg {
            opacity: 1;
        }
    }
}
