@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.TraceabilitiesSummary {
    $this: &;
    margin-bottom: 1.6rem;

    & > div {
        transition: all 0.3s linear;
        padding: 2.4rem;
    }

    &__header {
        right: 0;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1.6rem;
        justify-content: space-between;
        padding-bottom: 2.4rem;
    }

    &__unitSelector {
        svg {
            width: 22px;
            min-width: 22px;
            height: 22px;
            stroke-width: 1.75;
            margin-right: 0.5rem;
        }

        label {
            border-radius: 10px !important;
        }
    }

    &__dropdownItem  {
        font-size: 1.4rem;
        cursor: pointer;
    }

    &__switches {
        &--measure {
            order: 3;
            flex: 1 1;
            max-width: 100%;
            @include res-desk {
                order: unset;
                flex: unset;
            }
        }
    }

    &__summary {
        position: relative;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        margin-top: 0;
        opacity: 0;
        transform: scaleY(0.9);
        transform-origin: top center;
    }

    &__button {
        background: transparent;
        border: 0;
        line-height: 1;
        appearance: none;
        cursor: pointer;
        transition: all 0.15s linear;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        font-weight: inherit;
        padding: 0.6rem 0.8rem;
        border-radius: 5px;
        color: $purple;

        font-size: 1.4rem;
        margin-left: auto;

        &:hover {
            background: $background-grey;
        }

        display: flex;
        align-items: center;
        gap: 0.5rem;

        svg {
            transition: all 0.3s ease-in-out;
        }
    }

    &--hide {
        & > div {
            background: transparent;
            box-shadow: none;
            border: none;
            padding: 0;
            border-color: transparent;
        }

        #{$this}__summary {
            opacity: 0;
            min-height: 0rem;
        }
    }

    &--show {
        #{$this}__button {
            svg {
                transform: rotate(180deg);
            }
        }

        #{$this}__summary {
            opacity: 1;
            transform: scaleY(1);
            overflow: unset;
            min-height: 30rem;
        }
    }
}
