@import 'styles/abstracts/variables';
@import 'styles/abstracts/mixins';

.KPIAlarm {
    $this: &;
    display: flex;
    gap: 0.8rem;
    margin: 0.8rem 0;
    color: $text-grey-dark;

    &__container {
        flex: 1;
    }

    &__title {
        font-size: 1.4rem;
        font-weight: 600;
    }

    &__text {
        font-size: 1.3rem;
        font-weight: 400;
    }

    &__link {
        color: $purple;
        font-weight: 500;
        margin-left: 0.4rem;
    }

    &--danger {
        #{$this}__title {
            color: darken($red-status, 15%);
        }
    }

    &--success {
        #{$this}__title {
            color: darken($green-status, 15%);
        }
    }

    &--warning {
        #{$this}__title {
            color: darken($orange-status, 15%);
        }
    }
}
